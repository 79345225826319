import React, { useEffect } from 'react'

import Web3 from 'web3';
import NFTJSON from './build/NFT.json';
import NFTTokenJSON from './build/NFTToken.json';
import NFTExchangeJSON from './build/NFTExchange.json';

import { useDispatch } from 'react-redux';
import { loadBalanceData } from './redux/slices/balanceSlice';
import { loadCancleOrderData, loadAllOrderData, loadFillOrderData } from './redux/slices/orderSlice';
import { loadMintData } from './redux/slices/mintSlice';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './view/home/Home';

import Pledge from './view/pledge/Pledge';
import PledgeNew from './view/pledge/PledgeNew';
import Search from './view/search/Search';
import User from './view/user/User';
import CreateNFT from './view/createNFT/CreateNFT';
import NFT from './view/NFT/NFT';
import UserNFTList from './view/user/UserNFTList';
import UserInfo from './view/user/UserInfo';
import Project from './view/project/Project';
import Farms from './view/farms/Farms';

import { startInitWeb } from './util/startInitWeb';
import useSWR from 'swr';
import { fetchData, domainURL } from './util/util'; 
import { setNFTSymbol } from './redux/slices/homeSlice';
 



export default function App() {
  const dispatch = useDispatch();
  const { data, errors, isLoadings } = useSWR(domainURL+'/api/nft/symbol', fetchData);

  if (data) { 
    if(data.code == 1){  
        dispatch(setNFTSymbol(data.data.list[0])); 
    }
    
}  

  useEffect(() => {
    async function startWeb3() {
      var web = await startInitWeb();  
      dispatch(loadMintData(web));
      initWeb();
    }
     startWeb3();
  }, [])

  async function initWeb() {
    var web3 = new Web3(Web3.givenProvider); 
  }
 
  return (
    <div>
       
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="pledge" element={<Pledge />}></Route>
        <Route path="stake" element={<PledgeNew />}></Route>
        <Route path="search" element={<Search />}></Route>
        <Route path="user" element={<User />}></Route>
        <Route path="createNFT" element={<CreateNFT />}></Route>
        <Route path="NFT" element={<NFT />}></Route> 
        <Route path="userList" element={<UserNFTList />}></Route> 
        <Route path="userInfo" element={<UserInfo />}></Route> 
        <Route path="project" element={<Project />}></Route> 
        <Route path="farms" element={<Farms />}></Route>
      </Routes> 
    </div>
  )
}

import React, { useState } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { setNftGroup, setNftNetwork } from '../../redux/slices/configSlice';
import { domainURL, fetchData } from '../../util/util';
import useSWR from 'swr'; 
import { useTranslation } from 'react-i18next';

export default function GroupList(props) {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [curState,setCurState] = useState('1');
    const { data  } = useSWR([domainURL + `/api/nft/group`], fetchData);
    if (data) {
        dispatch(setNftGroup(data.data.nft_group));
        dispatch(setNftNetwork(data.data.nft_network));
    }

    const group = useSelector(state => state.config.nftGroup);
    
    const updateCategory = (event) => { 
        setCurState(event.target.dataset.value);
        if(props.updateCategory){
            if(event.target.dataset.value == '1'){
                props.updateCategory('');
            }else{
                props.updateCategory(event.target.dataset.value);
            }
            
        }
    }
    const jumpToSearch = () => {
        if(props.jumpToSearch){
            props.jumpToSearch(curState);
        }
    }

    const groupName = {
        background:'rgba(255,255,255,0.2)',
        cursor:'pointer'
    }
    const h2Container = {
        width:(100 * (group.length + 2))
    }
    const h2ContainerScroll = {
        overflowX: 'scroll',
        overflowY: 'hidden',
    }

     
    return (
        <Container className='d-flex flex-row justify-content-between   ' style={h2ContainerScroll}>
             
                <div className=' d-flex' style={h2Container}>
                    <h2 className='d-inline-block default-text-color fs-5 px-4 p-1  fw-bold rounded-1  h2Style' 
                        data-value='1'  
                        onClick={updateCategory} 
                        style={curState == '1' ? groupName : {}}>
                        {t('all')}
                    </h2> 
                    {group.length && group.map((item,key) => {
                        return ( <h2 key={key} className='d-inline-block default-text-color  fw-bold fs-5  p-1 rounded-1 cursor-pointer h2Style' 
                                     data-value={item.name}  
                                     onClick={updateCategory}  
                                     style={curState == item.name ? groupName : {cursor:'pointer'}}>{item.name}</h2>);
                    })} 
                </div>
                <div className="d-flex flex-row justify-content-end align-items-center">
                    <h2 className='d-inline-block default-text-color  fs-5 rounded-1 cursor-pointer h2Style fw-bold ' style={{textAlign:'right'}}   onClick={jumpToSearch}  >{t('more')}{'>'} </h2>
                </div>
              
        </Container>
    )
}

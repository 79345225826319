import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const orderSlice = createSlice({
    name: "order",
    initialState: {
        CancleOrder: 0,
        FillOrder: [],
        AllOrder: [],
    },
    reducers: {
        setCancleOrder(state, action) {
            console.log(action);
            state.CancleOrder = action.payload;
        },
        setFillOrder(state, action) {
            console.log(action.payload);
            state.FillOrder = action.payload;
        },
        setAllOrder(state, action) {
            console.log(action);
            state.AllOrder = action.payload;
        },

    }
});

export const { setCancleOrder, setFillOrder, setAllOrder} = orderSlice.actions;
export default orderSlice.reducer;
//balanceSlice.action

export const loadCancleOrderData = createAsyncThunk("order/fetchCancleOrderData",
    async (data, { dispatch }) => { 
        const { exchangeContract } = data; 
        await exchangeContract.methods.cancleOrederLists(1).call(); 
        const result = await exchangeContract.getPastEvents("CancleOrder",{
            fromBlock:0,
            toBlock:"latest"
        });
      
        const cancleOrders = result.map(item => item.returnValues);
        
        dispatch(setCancleOrder(cancleOrders));
    }
);

export const loadAllOrderData = createAsyncThunk("order/fetchAllOrderData",
    async (data, { dispatch }) => { 
        const { exchangeContract} = data; 
       
        var  result = await exchangeContract.getPastEvents("Trade",{
            fromBlock:0,
            toBlock:"latest"
        });
      
        const allOrders = result.map(item => item.returnValues);
       //过滤掉已经取消的订单
       var myAllOrder = allOrders.map(function(itemkey){
        return itemkey;
       });
        dispatch(setAllOrder(myAllOrder));
    }
);

export const loadFillOrderData = createAsyncThunk("order/fetchFillOrderData",
    async (data, { dispatch }) => { 
        const { exchangeContract } = data; 
       
        const result = await exchangeContract.getPastEvents("FillOrder",{
            fromBlock:0,
            toBlock:"latest"
        });
        
        const allOrders = result.map(item => item.returnValues);
     
        dispatch(setFillOrder(allOrders));
    }
);
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const mintSlice = createSlice({
    name: "mint",
    initialState: {
        mintFee: 0, 
    },
    reducers: {
        setMintFee(state, action) { 
            state.mintFee = action.payload;
        }, 

    }
});

export const { setMintFee} = mintSlice.actions;
export default mintSlice.reducer;
 

export const loadMintData = createAsyncThunk("mint/fetchMintData",
    async (data, { dispatch }) => {  
        const { NFTContract } = data; 
        var mintFee = await NFTContract.methods.mintFee().call();   
        dispatch(setMintFee(mintFee));
    }
);
 
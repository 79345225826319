import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Web3 from "web3";
const bnoSlice = createSlice({
    name: "bno",
    initialState: {
        allstake: 0,
        nftAddition: 0,
        nftAmount: 0,
        rewardDebt: 0,
        pendingFit: 0,
        stakeSupply:0,
        nftPoolAddition:0,
        pledgeId: [],
        selectUnpledgeId:[],
        withdrawalFee:0,
        nftCount:0,
        bnoNumber:0,
        totalMiningPool:'4500000'
    },
    reducers: {
        setAllstake(state, action) { 
            state.allstake = action.payload;
        },
        setNftAddition(state, action) {
            state.nftAddition = action.payload;
        },
        setNftAmount(state, action) {
            state.nftAmount = action.payload;
        },
        setRewardDebt(state, action) {
            state.rewardDebt = action.payload;
        },
        setPendingFit(state, action) {
            state.pendingFit = action.payload;
        },  
        setPledgeId(state, action) {
            state.pledgeId = action.payload;
        },  
        setSelectUnpledgeId(state, action) {
            state.selectUnpledgeId = action.payload;
        },  
        setStakeSupply(state, action) {
            state.stakeSupply = action.payload;
        },  
        setNftPoolAddition(state, action) {
            state.nftPoolAddition = action.payload;
        },
        setWithdrawalFee(state, action) {
            state.withdrawalFee = action.payload;
        },
        setNftCount(state, action) {
            state.nftCount = action.payload;
        },
        setBnoNumber(state, action) { 
            state.bnoNumber = action.payload;
        },
        setTotalMiningPool(state, action) { 
            state.totalMiningPool = action.payload;
        }   
        
        
    }
});

export const { setWithdrawalFee,setStakeSupply,setTotalMiningPool,setBnoNumber,setNftCount, setNftPoolAddition, setAllstake, setNftAddition, setNftAmount, setRewardDebt, setPendingFit, setPledgeId, setSelectUnpledgeId } = bnoSlice.actions;
export default bnoSlice.reducer;
 

export const loadBnoData = createAsyncThunk("bno/fetchBno",
async (data,{dispatch}) => { 
    let startTime = '2023/6/6 12:00:00';
     
    //console.log(data); 
    const {account, PledgeContract, PledgeBNONFTContract} = data; 
    var res = await PledgeContract.methods.userInfo(account).call(); 
    dispatch(setAllstake(res.allstake)); 
    dispatch(setNftAddition(res.nftAddition));
    dispatch(setNftAmount(res.nftAmount));
    dispatch(setRewardDebt(res.rewardDebt));
 
    res = await PledgeContract.methods.pendingFit(account).call();  
    dispatch(setPendingFit(res));
   
    var res2 = await PledgeContract.methods.poolInfo().call();  
  
    dispatch(setStakeSupply(res2.stakeSupply));
    dispatch(setNftPoolAddition(res2.nftAddition));

   var withraFee =  await PledgeContract.methods.withdrawalFee().call(); 
   dispatch(setWithdrawalFee(withraFee));

   var nftCount = await PledgeContract.methods.getUserNft(account).call();
   
   let stakeCount = 0;
   for (let index = 0; index < nftCount.length; index++) {
        if (nftCount[index] > 0) { 
            ++stakeCount;
        } 
    }
   dispatch(setNftCount(stakeCount));
   
   let bnoResult = await PledgeBNONFTContract.methods.balanceOf(account).call();
 
   dispatch(setBnoNumber(bnoResult));
   
   if(Date.parse(new Date()) > Date.parse(new Date(startTime))){
        setInterval(async function (){
                var seconds = parseInt(Date.parse(new Date())/1000 - Date.parse(new Date(startTime))/1000 );  
                let lastCount = parseFloat(4500000 - (0.0475 * seconds)); 
                lastCount = lastCount >= 0 ? lastCount : 0;
                dispatch(setTotalMiningPool(lastCount)); 
        },1000);
   }
   

   setInterval(async function (){
    var res = await PledgeContract.methods.pendingFit(account).call();  
    dispatch(setPendingFit(res)); 
   },1000 * 21);
}
);
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const balanceSlice = createSlice({
    name: "balance",
    initialState: {
        TokenWallet: 0,
        TokenExchange: 0,
        EtherWallet: 0,
        EtherExchange: 0
    },
    reducers: {
        setTokenWallet(state, action) {
            state.TokenWallet = action.payload;
        },
        setTokenExchange(state, action) {
            state.TokenExchange = action.payload;
        },
        setEtherWallet(state, action) {
            state.EtherWallet = action.payload;
        },
        setEtherExchange(state, action) {
            state.EtherExchange = action.payload;
        },

    }
});

export const { setTokenWallet, setTokenExchange, setEtherWallet, setEtherExchange } = balanceSlice.actions;
export default balanceSlice.reducer;
//balanceSlice.action

export const loadBalanceData = createAsyncThunk("balance/fetchBalance",
async (data,{dispatch}) => {
    const ETHERAddress = '0x0000000000000000000000000000000000000000'; 
    const {web3, ybTokenContract, exchangeContract, account} = data;
 
    const TokenWallet = await ybTokenContract.methods.balanceOf(account).call(); 
    dispatch(setTokenWallet(TokenWallet));


    const TokenExchange = await exchangeContract.methods.balanceOf(ybTokenContract.options.address,account).call(); 
    dispatch(setTokenExchange(TokenExchange));

    const EtherWallet = await web3.eth.getBalance(account);
    dispatch(setEtherWallet(EtherWallet));
    
 
    const EtherExchange = await exchangeContract.methods.balanceOf(ETHERAddress,account).call();
    dispatch(setEtherExchange(EtherExchange));
    
}
);
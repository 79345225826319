import React, { useState, useEffect, useRef } from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { Button, Container, Form } from 'react-bootstrap'
import LinearBorderBox from '../common/LinearBorderBox'
import CategoryTitle from '../common/CategoryTitle'
import ContentTitle from '../common/ContentTitle'
import Input from 'antd/es/input/Input'
import NFTList from '../home/NFTList'
import NFTNode from '../common/NFTNode'
import VerticallyModalNFTNode from '../common/VerticallyModalNFTNode'
import { startInitPledge } from '../../util/startInitPledge'
import { useDispatch, useSelector } from 'react-redux'
import { loadBnoData } from '../../redux/slices/bnoSlice'
import { message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../common/Loading'
import Web3 from 'web3'
import { pledgeAddress } from '../../util/util';
import { useCountdown } from '../../util/useCountdown';

const className = 'fs-4 default-text-color  py-1 mt-2 mb-1';

export default function Pledge() {

    const dispatch = useDispatch();

    const [days, hours, minutes, seconds] = useCountdown('2023/6/6 12:00:00');
    // console.log(days, hours, minutes, seconds);

    const { allstake, pendingFit, bnoNumber,totalMiningPool, nftCount, nftAmount, pledgeId, selectUnpledgeId,stakeSupply, nftPoolAddition, withdrawalFee, nftAddition } = useSelector(state => state.bno);
    const { t, i18n } = useTranslation();
	
    const cRef = useRef(null);

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [loadingType, setLoadingType] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [spinStatus, setSpinStatus] = useState(false);
    const [quentity, setQuentity] = useState(0);
    const [number, setNumber] = useState(0);

    const [tips, setTips] = useState('');
	
    const changeQuantity = (event) => {
        setQuentity(event.target.value);
    }
 
    const changeNumber = (event) => {
        setNumber(event.target.value);
    }
 
    const startRedeemNFT = async () => {
        if (spinStatus) {
            return;
        }
        if (selectUnpledgeId.length == 0) {
            messageApi.info(t('tips1'));
            return;
        }

        setSpinStatus(true);
        setTips(t('tips2'));
        setLoading(true);
        setLoadingType('redeemNFT');
        const { PledgeContract, account } = window.pledge;

        PledgeContract.methods.unstakeNft(selectUnpledgeId)
        .estimateGas({ from: account, value: withdrawalFee })
        .then((res) => {
            estimateGasUnstakeNftSuccess();
        }).catch((error) => {
            setSpinStatus(false);
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
        });

    }
    const estimateGasUnstakeNftSuccess = async () => {
        const { PledgeContract, account } = window.pledge;
        PledgeContract.methods.unstakeNft(selectUnpledgeId).send({ from: account, value: withdrawalFee }).then((res) => {
            messageApi.info(t('tips3'));
            setModalShow(false);
            setLoading(false);
            //回调刷新一下我的已经质押列表
            cRef.current && cRef.current.updatePledgeData();

        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
        });
    }


    const startRedeem = async () => {
        if (spinStatus) {
            return;
        }
        if (number == 0) {
            messageApi.error(t('tips4'));
            return;
        }
        
        // let  allstake1 = 10000;
        // let  allstake2 = 9500;

        // if (allstake2 > allstake1) {
        //     messageApi.error(t('tips15'));
        //     return;
        // }
        // console.log('赎回数量：',number , '质押数量',allstake1,'allstake2',allstake2);
        // return;


        const { PledgeContract, account } = window.pledge;
        setSpinStatus(true);
        setTips(t('tips5'));
        setLoading(true);
        setLoadingType('redeembon');

        let repedgeNumber =  Web3.utils.toWei(number);
        PledgeContract.methods.rePledge(repedgeNumber).estimateGas({ from: account,value:withdrawalFee }).then((res) => {
            estimateGasRedeemSuccess();
        }).catch((error) => {
            messageApi.error(t('insufficientGas'));
            setSpinStatus(false);
            setLoading(false);
        });
    }
    const estimateGasRedeemSuccess = () => {
        const { PledgeContract, account } = window.pledge;
        let repedgeNumber =  Web3.utils.toWei(number);
        PledgeContract.methods.rePledge(repedgeNumber).send({ from: account,value:withdrawalFee }).then((res) => {
          
            setSpinStatus(false);
            setLoading(false);
            messageApi.info(t('tips6'));
            dispatch(loadBnoData(window.pledge));
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
        });
    }

    const startDraw = async () => {
        if (pendingFit == 0) {
            messageApi.error(t('tips7'));
            return;
        }
        if (spinStatus) {
            return;
        }
        // if (spinStatus) {
        //     messageApi.error(t('tips16'));
        //     return;
        // }
        const { PledgeContract, account } = window.pledge;
        setSpinStatus(true);
        setLoading(true);
        setLoadingType('draw');
        setTips(t('tips14'));
        PledgeContract.methods.withdraw().estimateGas({ from: account,value:withdrawalFee }).then((res) => {
            estimateGasDrawSuccess();
        }).catch((error) => {
            messageApi.error(t('insufficientGas'));
            setLoading(false);
            setSpinStatus(false);
        });
    }

    const estimateGasDrawSuccess = () => {
        const { PledgeContract, account } = window.pledge;
        PledgeContract.methods.withdraw().send({ from: account,value:withdrawalFee }).then((res) => {
            console.log(res);
            setSpinStatus(false);
            messageApi.info(t('tips8'));
            setLoading(false);
            dispatch(loadBnoData(window.pledge));
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
            setSpinStatus(false);
        });
    }


    const startPledge = async () => {
        
        if (spinStatus) {
            return;
        }
        if (quentity == 0) {
            messageApi.error(t('tips9'));
            return;
        }
        const { PledgeContract, account, PledgeBNONFTContract } = window.pledge;

        setSpinStatus(true);
        setLoadingType('pledgebno');
        setLoading(true);
        setTips(t('tips10'));

        let allowanceAccount = await PledgeBNONFTContract.methods.allowance(account, pledgeAddress).call();
        //console.log('授权数量：',allowanceAccount);
        let inputBno = Web3.utils.toWei(quentity);
        //console.log('授权数量：',allowanceAccount , '质押数量：',inputBno);
		if (parseInt(allowanceAccount) >= parseInt(inputBno)) {
            //console.log('授权数量：',allowanceAccount , '质押数量：',inputBno);
            PledgeContract.methods.pledge(inputBno).estimateGas({ from: account,value:withdrawalFee }).then((res) => {
                estimateGasSuccess();
            }).catch((error) => {
                messageApi.error(t('insufficientGas'));
                setLoading(false);
                setSpinStatus(false);
            });
        }else{ 
            PledgeBNONFTContract.methods.approve(pledgeAddress, Web3.utils.toWei('10000')).send({ from: account }).then((res) => {

                PledgeContract.methods.pledge(inputBno).estimateGas({ from: account,value:withdrawalFee }).then((res) => {
                    estimateGasSuccess();
                }).catch((error) => {
                    messageApi.error(t('insufficientGas'));
                    setLoading(false);
                    setSpinStatus(false);
                });  
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                setLoading(false);
            }); 
        }  
    }

    const estimateGasSuccess = async () => {
        let inputBno = Web3.utils.toWei(quentity);
        const { PledgeBNONFTContract, PledgeContract, account } = window.pledge;
        var res = await PledgeBNONFTContract.methods.allowance(account, pledgeAddress).call();

        PledgeContract.methods.pledge(inputBno).send({ from: account,value:withdrawalFee }).then((res) => {
            console.log(res);
            setSpinStatus(false);
            setLoading(false);
            messageApi.info(t('tips11'));
            dispatch(loadBnoData(window.pledge));
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
        });

    }

    const getIncome = (pendingFit) => { 
        if(pendingFit){
            var res = Web3.utils.fromWei(pendingFit);
            return parseFloat(res).toFixed(2);
        }else{
            return 0;
        }
        
    }
    const getPledgebnoRatio= () => {
        let allStakeSupply = getIncome(stakeSupply); 

        let result = allStakeSupply != 0  ? ((600000 / allStakeSupply) * 100) : 0; 
        result = isNaN(result) ? 0 : result;
       return (result).toFixed(2);

    }
	
	const [account, setAccount] = useState('');	
	  useEffect(() => {
	     const loadWeb3 = async () => {
	       if (window.ethereum) {
	         window.web3 = new Web3(window.ethereum);
	         try {
	           // 请求用户授权连接到以太坊
	           await window.ethereum.request({ method: 'eth_requestAccounts' });
	           // 获取用户的钱包地址
	           const accounts = await window.web3.eth.getAccounts();
	           // 将地址设置到状态中
	           setAccount(accounts[0]);
	         } catch (error) {
	           console.error('用户拒绝连接到以太坊：', error);
	         }
	       } else {
	         console.error('请安装 MetaMask 以使用以太坊功能！');
	       }
	     };
	 
	     loadWeb3();
	   }, []);
	  
    const createCreateForm = () => {
       return (<div className='text-white  m-2  py-4'>
               
               
       
             <div>{t('pledgeEndExplain')}<span style={{fontSize: '0.8rem',borderRadius:0,border:'none'}}>{account}</span></div>
              
               
           </div>);
       }
    //这里是添加NFT质押的函数
    const addNFT = async () => {
        if (pledgeId.length == 0) {
            messageApi.info(t('tips12'));
            return;
        }
        const { PledgeNFTContract, PledgeContract, account } = window.pledge;
        setLoadingType('stakeNft');
        setLoading(true);
        var isApprovedForAll = await PledgeNFTContract.methods.isApprovedForAll(account, pledgeAddress).call();

        if (isApprovedForAll) {
            estimateGasStakeNft();

        } else {
            await PledgeNFTContract.methods.setApprovalForAll(pledgeAddress, true).send({ from: account }).then((res) => {
                estimateGasStakeNft();
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                setLoading(false);
            });
        }
    }
    const estimateGasStakeNft = async () => {
        const { PledgeContract, account } = window.pledge;
        //console.log(pledgeId);
        PledgeContract.methods.stakeNft(pledgeId)
            .estimateGas({ from: account, value: withdrawalFee })
            .then((res) => {
                estimateGasStakeNftSuccess();
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                setModalShow(false);
                setLoading(false);
            });

    }
    const estimateGasStakeNftSuccess = async () => {
        const { PledgeContract, account } = window.pledge;
        console.log(pledgeId);
        PledgeContract.methods.stakeNft(pledgeId)
            .send({ from: account, value: withdrawalFee })
            .then((res) => {
                //console.log('质押成功了,你在提示什么',t('tips12'));
                messageApi.info(t('tips13'));
                setModalShow(false);
                setLoading(false);
                //回调主要成功后，需要刷新质押列表
                cRef.current && cRef.current.updatePledgeData();

            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                setModalShow(false);
                setLoading(false);
            });

    }
    


    useEffect(() => {
        async function startWebPledge() {
            var web = await startInitPledge(); 
            dispatch(loadBnoData(web)); 
            var res = await web.PledgeContract.methods.getUserNft(web.account).call();
            
        } 
        startWebPledge();
    }, [])

    return (
        <div className='bgLinearColor'>
            <Header></Header>
            {contextHolder}
            {spinStatus ? (<Spin tip={tips} size="large">
                <div className="content" />
            </Spin>) : null} 
            <Container>
                <LinearBorderBox item={createCreateForm()}></LinearBorderBox>
            </Container>
            <VerticallyModalNFTNode
                show={modalShow}
                loadingtype={loadingType}
                loading={loading}
                addnft={addNFT}
                onHide={() => setModalShow(false)}>
            </VerticallyModalNFTNode>
            <Footer></Footer>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { domainURL } from '../../util/util';
import NFTItem from './NFTItem'
import { Empty, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

export default function NFTGroupList(props) {
 
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
   
  const fetchData = async (url,nft) => {  
    let formData = new FormData();
    formData.append('id',nft.id);
    formData.append('address',window.web.account);
    formData.append('type','favorite');
    return fetch(url, {
            method: 'post', 
            body:formData, 
        }).then((res) => res.json())
  }

  const favorNFT = (nft,callback) => {
    console.log(nft);
    let url = domainURL + '/api/nft/vote';
    fetchData(url,nft).then((res) => {
      if(res.code == 1){
        callback();
      } 
      messageApi.info(res.msg); 
    }); 
  }

    
  return (
    <Container>
      <div className='row g-3 item-wrap  my-4'>
        {contextHolder}
        {props.list.length ? props.list.map(function (item, key) {
          return (<div className='px-2 col-sm-6 col-md-6 col-lg-3 ' key={key}>
            <NFTItem nft={item} index={key} is_favor={item.is_favor} callback={favorNFT}></NFTItem></div>);
        }) : <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }} 
              description={
                  <span className='fs-3 default-text-blur'>
                      {t('usertList5')}
                  </span>
              }>
          </Empty>}
      </div>
    </Container>
  )
}

import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import { NavDropdown, Nav, Navbar,  Offcanvas, Alert, Fade } from 'react-bootstrap';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../../index.css';
import { useNavigate } from 'react-router-dom';
import { checkWallectEnv, checkWallectConnect } from '../../util/util';
 
import { useTranslation } from 'react-i18next';
import { message } from 'antd'; 
import { useSelector } from 'react-redux';
import i18next from 'i18next';

export default function Header(props) {
    const [offcanvas, setOffcanvas] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [connectStatus, setConnectStatus] = useState(false);
    const [search, setSearch] = useState('');
    const { t  } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const userInfo = useSelector(state => state.user.info);

    const navigate = useNavigate();
    const submitSearch = () => { 
        if(props.callback){
            props.callback(search);
        }else{
            navigate(`/search?search=${search}`);
        }
       
    }
    const changeSearch = (event) => {
        setSearch(event.target.value);
    }
    const checkConnectStatus = () => {
        if (checkWallectEnv() && checkWallectConnect()) {
            connectWallet();
        } else {
            setOffcanvas(true);
        }
    }
    const connectMetaMask = async () => {
        if (checkWallectEnv('isMetaMask')) {
            connectWallet();
        } else {
           // setShowAlert(true);
            alert(t('installple')+'MetaMask');
           // timeoutCloseAlert();
        }
    }
    const connectTokenPocket = () => {

        if (checkWallectEnv('isTokenPocket')) {
            connectWallet();
        } else {
            //setShowAlert(true);
            alert(t('installple')+'Token Pocket');
           // timeoutCloseAlert();
        }
    }

    const connectWallet = async () => {
        window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
            let accounts = res[0];
            navigate('/user');
        }).catch((err) => {
            if (err.code == 4001) {
                //setShowAlert(true);
                messageApi.error(t('userDenied'));
                //timeoutCloseAlert();
            } else {

            }
        });
    }

    const timeoutCloseAlert = () => {
        setTimeout(function () {
            setShowAlert(false);
        }.bind(this), 3000);
    }
    const updateLanguage = (event) => {
        var lang = event.target.dataset.value == '1' ? 'en' : 'zh';
       
        localStorage.setItem('lang',lang);
        i18next.changeLanguage(lang)
        .then((t) => {
          t('key'); // -> same as i18next.t
        });
    }
    

    useEffect(() => { 
        async function startWeb3() {
            if (checkWallectEnv()) {
                var status = await checkWallectConnect();
                 
                setConnectStatus(status);
            } else {
                console.log(window.ethereum);
            }
        }
        setTimeout(function () {
            startWeb3();
        }, 500);
    }, [])


    const createOffcanva = () => {
        return (<Offcanvas show={offcanvas} onHide={() => { setOffcanvas(false) }} placement={'end'}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='fs-4 default-text-blur'>{t('connectBtnWallet')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body  >
                <p className='fs-6'>{t('installMetamask')}</p>
                <div className='wallet-row d-flex justify-content-start  align-items-center rounded-2 p-2' onClick={connectMetaMask}>
                    <img src='/assets/fox.png' width={45}></img>
                    <span className='ms-2'>{t('connect')} MetaMask</span>
                </div>
                <div className='wallet-row d-flex justify-content-start  align-items-center  rounded-2 p-2 mt-2' onClick={connectTokenPocket}>
                    <img src='/assets/tokenpocket.png' width={45}></img>
                    <span className='ms-2'>{t('connect')} TokenPocket</span>
                </div>
            </Offcanvas.Body>
        </Offcanvas>);
    }

    const createAlert = () => {
        return (<Fade in={true} timeout={3000} >
            <div id="example-fade-text" className='position-fixed ' xs={8} lg={6} md={6} sm={8}>
                <Alert key={'danger'} variant={'danger'} onClose={() => { setShowAlert(false) }} dismissible className="container text-center text-balnk">
                    {alertMessage}
                </Alert>
            </div>
        </Fade>);
    }
    const createInfo = () => {
        return (<div className=' rounded-circle bg-white ' style={{  width: 30, height: 30 }}><img src={userInfo.avatar || '/assets/no-picture.png'} className='rounded-circle' width={30} height={30}></img></div>)
    }

    return (<div className='pt-2'>
        <Navbar collapseOnSelect expand="lg" variant="dark" >
            <Container>
                {contextHolder}
                <Navbar.Brand href="/">
                    <img
                        alt="LOGO"
                        src="/assets/logo.png"
                        width="42"
                        height="46"
                        className="d-inline-block align-center"
                    />{' '}
                    <span className="h5">OCEAN</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="text-white" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Input placeholder={t('searchHolder')} className='search-input' onChange={changeSearch} onPressEnter={submitSearch} allowClear size="large" prefix={<SearchOutlined style={{ fontSize: 22, color: '#fff' }} />} />
                    </Nav>
                    <Nav className="text-white align-middle">
                    <Nav.Link className="text-white hvr-underline-from-center" href="/"> {t('home')}</Nav.Link>
                        <Nav.Link className="text-white hvr-underline-from-center" href="/farms"> {t('farms')}</Nav.Link>
                        <Nav.Link className="text-white hvr-underline-from-center" href="/createNFT"> {t('createNFT')}</Nav.Link>
                        <Nav.Link className="text-white hvr-underline-from-center" href="/pledge">{t('pledge')}</Nav.Link>
                        <Nav.Link className="text-white hvr-underline-from-center" href="/search">{t('search')}</Nav.Link>
                        <Nav.Link className="text-white hvr-underline-from-center" href="/stake">{t('stake')}</Nav.Link> 
						<Nav.Link className="text-white hvr-underline-from-center" href="https://elephantswaps.com/" target="_blank"  >ElephantSwaps</Nav.Link> 
						
						<NavDropdown title={t('more')} id="collasible-nav-dropdown" className="text-white hvr-underline-from-center dropdown-btn " >
						
							
							<NavDropdown.Item href="https://unitedgaming.vip/" target="_blank" className="text-white align-middle">
								<span className='ms-2 fs-6 fw-light' data-value='1'>{t('unitedgaming')}</span>
							</NavDropdown.Item>
							
							<NavDropdown.Item href="https://dragongirl.pro/" target="_blank" className="text-white align-middle">
							    <span className='ms-2 fs-6 fw-light' data-value='1'>{t('legTH')}</span>
							</NavDropdown.Item>
							
							<NavDropdown.Item href=" https://okc.mom/" target="_blank" className="text-white align-middle">
								<span className='ms-2 fs-6 fw-light' data-value='1'>{t('okc')}</span>
							</NavDropdown.Item>
							
							
						</NavDropdown>
						
                     
						
						<NavDropdown title={t('language')} id="collasible-nav-dropdown" className="text-white hvr-underline-from-center dropdown-btn " >
                            <NavDropdown.Item href="#" data-value='1'  onClick={updateLanguage.bind(1)} className="text-white align-middle"> 
                                <span className='ms-2 fs-6 fw-light' data-value='1'>{t('english')}</span>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#" data-value='2'  onClick={updateLanguage.bind(2)} className="text-white align-middle"> 
                                <span className='ms-2 fs-6 fw-light' data-value='2'>{t('chinese')}</span>
                            </NavDropdown.Item> 
                        </NavDropdown>

                        {/* <Nav.Link className="text-white" onClick={checkConnectStatus} > {connectStatus ? createInfo() : t('connectBtn')}</Nav.Link> */}
                        <NavDropdown title={connectStatus ? t('user') : t('connectBtn')} id="collasible-nav-dropdown" className="text-white    dropdown-btn me-4" >
                             <NavDropdown.Item href="/user" className="text-white align-middle">
                                {/* <img src='/assets/icon_1.png' width={16} height={16}></img> */}
                                <span className='ms-2 fs-6 fw-light'>{t('user')}</span>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/userList" className="text-white align-middle">
                                {/* <img src='/assets/icon_1.png' width={16} height={16}></img> */}
                                <span className='ms-2 fs-6 fw-light'>{t('sub1')}</span>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/user" className="text-white align-middle">
                                {/* <img src='/assets/icon_2.png' width={16} height={16}></img> */}
                                <span className='ms-2 fs-6 fw-light'>{t('sub2')}</span>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/userList" className="text-white align-middle">
                                {/* <img src='/assets/icon_3.png' width={16} height={16}></img> */}
                                <span className='ms-2 fs-6 fw-light'>{t('sub3')}</span>
                            </NavDropdown.Item> 
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        {createOffcanva()}
        {/* {createAlert()} */}
    </div>)
}

import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import Loading from './Loading';
import dayjs from 'dayjs'
import { message, Checkbox, Col, Row, Pagination, Spin } from 'antd'; 

export default function ModalStakeNFT(props) {

    const { t  } = useTranslation();
    const [imgs, setImgs] = useState([]);
    const [page, setPage] = useState(1);
    const [listloading, setListLoading] = useState(false);
    const [checkedValues, setCheckedValues] = useState([]);
  
    const handleClose = (event) => { 
        props.handleclose();
   }
   const handleExit = () => {
    setPage(1)
    setImgs([])
   }

       const makeOffer = (event) => {
    console.log(checkedValues);
    if(checkedValues.length > 0){ 
		
        const offerType = event.target.getAttribute('type');
        props.oncallback(checkedValues,offerType);
		

    }else{ 
        message.info(t('tips12'));
    } 
   }
   const getStartEndIndexes = (pageNumber, pageSize = 4) => {
  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize - 1;
  return { startIndex, endIndex };
}
   const initData = () => {
    console.log(props.obj)
    setListLoading(true)
    setPage(1)
    setImgs([])
    let {nft} = props.obj
    getData(nft)
   }
   const getNFTList = async (url) => {
        return fetch(url).then((res) => res.json())
    }
   const getData = async (item) => {
        let balanceUrl = imgs
        let { startIndex, endIndex } = getStartEndIndexes(page);
        if(endIndex > (item.balance - 1)) {
            endIndex = item.balance - 1
        }
        console.log(page, startIndex, endIndex)
        for(let i = startIndex; i <= endIndex; i++) {
                let tokenOfOwnerByIndex = await item.nftContract.methods.tokenOfOwnerByIndex(item.account, i).call()
                console.log(tokenOfOwnerByIndex)
                let tokenURI = await item.nftContract.methods.tokenURI(tokenOfOwnerByIndex).call()
                console.log(tokenURI)
                let res = await getNFTList(tokenURI)
                console.log(res)
                res.tokenId = tokenOfOwnerByIndex
                balanceUrl.push(res)
                
        }
        const uniqueArray = Array.from(new Set(balanceUrl.map(obj => obj.tokenId)))
  .map(tokenId => {
    return balanceUrl.find(obj => obj.tokenId === tokenId);
  });
        setImgs(uniqueArray)
        setListLoading(false)
   }
    const onChange = (checkedValues) => {
console.log('checked = ', checkedValues);
setCheckedValues(checkedValues)
    }

    const onPageLoad = () => {
        setPage(page+1)
    }
    const onPageChange = (val) => {
        console.log('checked = ', val);
        setPage(val)
        
        
        
    }
    useEffect(() => {
    // 在这里执行操作
    console.log("page", page)
    let {nft} = props.obj
    console.log("page", nft)
    if(!nft || !nft.balance) return
    setListLoading(true)
    getData(nft)
    }, [page]);
    const imgList = () => {
        return imgs.length > 0 && imgs.map((item,key) => {
            return (
                <Col span={12} key={key}>
                    <Checkbox value={item.tokenId}>
                            <img className='nft_img' src={item.image}></img>
                    </Checkbox>
                </Col>
            )
        })
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='lp_modal'
            onShow={initData}
            onHide={handleClose}
            onExit={handleExit}
        > <div className='pup-outer'>
                <Modal.Header>
                    <Modal.Title className='stake_title'>{t('pledge')} NFT</Modal.Title>
                    <svg onClick={handleClose} viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg" className="close_icon"><path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z"></path></svg>
                </Modal.Header>
                <Modal.Body>
                    <div className='nft_scroll'>
                    <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                            <Row gutter={[16, 16]}>
                            {
                            imgList()
                            }
                        </Row>
                </Checkbox.Group>
                </div>
      {/* <Pagination size="small" pageSize={4} total={props.obj.nft?.balance} onChange={onPageChange} /> */}
      <div className='nft_page'>
        <Button className="nft_load" size="small" disabled={listloading || (imgs.length == props.obj.nft?.balance)} loading={listloading} onClick={onPageLoad} >
            {listloading?<Spin></Spin>:<DownOutlined></DownOutlined>}
            </Button>
      </div>
                    <div className='base_btnbox'>
                        <button className='base_btn base_item' onClick={handleClose}>{t('stakelp3')}</button>
                        <button type ="1" onClick={makeOffer} disabled={props.loading} className='base_btn2 base_item' style={{marginLeft:'24px'}}>{props.loading?t('stakelp5'):t('stakelp4')}</button>
                    </div>
                   
                </Modal.Body>
            </div>
        </Modal>

    )
}

import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'; 
import { domainURL } from '../../util/util';
import NFTItem from '../home/NFTItem'
import { message  } from 'antd';

export default function NFTList(props) {

  const latestList = useSelector(state => state.home.searchList); 
  const [messageApi, contextHolder] = message.useMessage();
  


  const fetchData = async (url,nft) => {  
    let formData = new FormData();
    formData.append('id',nft.assetId);
    formData.append('address',window.web.account);
    formData.append('type','favorite');
    return fetch(url, {
            method: 'post', 
            body:formData, 
        }).then((res) => res.json())
  }

  const favorNFT = (nft,callback) => {
    let url = domainURL + '/api/nft/vote';
    fetchData(url,nft).then((res) => {
      if(res.code == 1){
        callback();
      }
      messageApi.info(res.msg); 
    });
  }
 
  return (
    <Container>
      <div className='row g-3 item-wrap'>
        {contextHolder}
        {latestList.map(function (item, key) {
          return (<div className='px-2 col-sm-6 col-md-6 col-lg-3' key={item.id}>
            <NFTItem nft={item} index={key} is_favor={item.is_favor}  callback={favorNFT}></NFTItem></div>);
        })}
      </div>
    </Container>
  )
}

import React from 'react'
import { Dropdown } from 'react-bootstrap'

export default function DropdownButton(props) {
    const { icon, list, title } = props;
    const changeItem = (value) => {
        props.changeValue(value.target.innerHTML);
    }
    return (
        <Dropdown className='me-4'>
            <Dropdown.Toggle id="dropdown-basic">
                {icon ? (<img src={icon} width={20} height={20} className="me-2"></img>) : null}
                <span className='fs-6'>{title}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu >
                {list.length > 0  && list.map((item, key) => {
                    return (<div key={key} onClick={changeItem}><Dropdown.Item >{item.name}</Dropdown.Item></div>);
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

import React, { useEffect, useState } from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { Container } from 'react-bootstrap'
import { Pagination } from 'antd'

import LinearBorderBox from '../common/LinearBorderBox' 
import { Empty, Tabs } from 'antd' 
import { domainURL, ERCAddress, NFTAddress, NFTExchangeAddress, BNBAddress } from '../../util/util'
import { setInfo } from '../../redux/slices/userSlice'
import NFTItem from './NFTItem'
import NFTItemSell from './NFTItemSell'


import { message, Spin } from 'antd'; 
import VerticallyCenteredModal from '../common/VerticallyCenteredModal'
import { useTranslation } from 'react-i18next';
import { startInitWeb } from '../../util/startInitWeb'
import { useDispatch, useSelector } from 'react-redux'
import Web3 from 'web3'
const limit = 8;


//const className = 'fs-4 default-text-color  px-3 pt-2 pb-2';
//const className = 'fs-4 default-text-color  px-3 py-1 mt-4 mb-1';

export default function UserNFTList() {

    const [myCreatedNFT, setMyCreatedNFT] = useState([]); 
    const [myFavouriteNFT, setMyFavouriteNFT] = useState([]);
    const [myOwnerNFT, setMyOwnerNFT] = useState([]);
    const [mySellNFT, setMySellNFT] = useState([]);
    const [staticResult, setStaticResult] = useState({ curTab: 1, 
                                                       total:0,
                                                       create: 0, 
                                                       own: 0, 
                                                       sell: 0, 
                                                       favour: 0, 
                                                       curPage: 0, 
                                                       activePage: 1 });
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingType, setLoadingType] = useState('');

    const [tips, setTips] = useState('');
   
    const [modalShow, setModalShow] = useState(false);
    const [spinStatus, setSpinStatus] = useState(false);
    const [curNft, setCurNft] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const userInfo = useSelector(state => state.user.info);
    const dispatch = useDispatch();
    

    const onChange = (res) => {
        updateList(res, 1);
    }

    const updateList = (res, page) => {
        
        var newData = staticResult;
        newData.curTab = res;
        newData.activePage = page;
        if (res == 1) {
            const url = domainURL + `/api/nft/mylist?address=${window.web.account}&type=created&pageNumber=${page}&pageSize=8`;
            getNFTList(url).then((res) => {
                if (res.code == 1) {
                    newData.curPage = Math.ceil(res.data.total / limit);
                    newData.create = res.data.total;
                    newData.total = res.data.total; 
                    setStaticResult(newData);
                    setMyCreatedNFT(res.data.list);
                }
            });
        } else if (res == 2) {
            const url = domainURL + `/api/nft/mylist?address=${window.web.account}&type=owner&pageNumber=${page}&pageSize=8`;
            getNFTList(url).then((res) => {
                if (res.code == 1) {
                    newData.curPage = Math.ceil(res.data.total / limit);
                    newData.own = res.data.total;
                    newData.total = res.data.total; 
                    setStaticResult(newData);
                    setMyOwnerNFT(res.data.list);
                }
            });
        } else if (res == 3) {
            const url = domainURL + `/api/nft/mylist?address=${window.web.account}&type=sell&pageNumber=${page}&pageSize=8`;
            getNFTList(url).then((res) => {
                if (res.code == 1) {
                    newData.curPage = Math.ceil(res.data.total / limit);
                    newData.sell = res.data.total;
                    newData.total = res.data.total; 
                    setStaticResult(newData);
                    setMySellNFT(res.data.list);
                }
            });
        } else if (res == 4) {
            const url = domainURL + `/api/nft/mylist?address=${window.web.account}&type=favorite&pageNumber=${page}&pageSize=8`;
            getNFTList(url).then((res) => {
                if (res.code == 1) {
                    newData.curPage = Math.ceil(res.data.total / limit);
                    newData.favour = res.data.total;
                    newData.total = res.data.total; 
                    setStaticResult(newData);
                    setMyFavouriteNFT(res.data.list);
                }
            });
        }
    }


    const startCheckParam = async (price, selectDate, type) => {
        
        if (price === 0) {
            message.info(t('usertList1'));
            return;
        }
        if (selectDate === 0) {
            message.info(t('usertList2'));
            return;
        }
        if (curNft === '') {
            message.info(t('usertList3'));
            return;
        }
        setLoading(true);
        setLoadingType('sellorder'); 
        startCreateOrder(price, selectDate, type); 
        
    }
    const startCreateOrder = async (price, selectDate, type) => {
        const { NFTContract,
            account } = window.web;
        let result = await NFTContract.methods.isApprovedForAll(account, NFTExchangeAddress).call();
        if (result) { 
            createOrderEstimate(price, selectDate, type);
        } else { 
             NFTContract.methods.setApprovalForAll(NFTExchangeAddress, true).send({ from: account }).then((res) => {
                createOrderEstimate(price, selectDate, type);
            }).catch((error) => {
                setLoading(false);
                setLoadingType('');
                messageApi.error(t('insufficientGas'));
                setSpinStatus(false);
            });
        }
    }

    const createOrder = async (nft) => {
        setModalShow(true);
        setCurNft(nft);
        return;
    }
    const createOrderEstimate = async (price, selectDate, type) => {
        //console.log('创建订单参数：',NFTAddress, curNft.id, BNBAddress, Web3.utils.toWei(price), selectDate, type);
        const { web3,
            NFTExchangeContract,
            account } = window.web;
        NFTExchangeContract.methods.createOrder(NFTAddress, curNft.assetId, BNBAddress, Web3.utils.toWei(price), selectDate, type).estimateGas({ from: account }).then((res) => {
            createOrderEstimateGasResult(price, selectDate, type);
        }).catch((error) => {
            console.log(1111);
            setLoading(false);
            setLoadingType('');
            setSpinStatus(false); 
            messageApi.error(error.data);
        });
    }

    const handleclose = async () => {
        setModalShow(false);
    }
 
    const createOrderEstimateGasResult = (price, selectDate, type) => {
        const { web3,
            NFTExchangeContract,
            account } = window.web;

        NFTExchangeContract.methods.createOrder(NFTAddress, curNft.assetId, BNBAddress, Web3.utils.toWei(price), selectDate, type).send({ from: account }).then((res) => {
            messageApi.info(t('usertList4'));
            setModalShow(false);
            setLoading(false);
            setLoadingType('');
        }).catch((error) => {
            if (error.code === 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
            setLoadingType('');
        });
    }


    const callbackCancleOrder = (nft) => {

        const {  
            NFTExchangeContract,
            account } = window.web;
        // console.log(NFTAddress, nft.assetId);
        // console.log(account);
        // return;
        NFTExchangeContract.methods.cancelOrder(NFTAddress, nft.assetId).estimateGas({ from: account }).then((res) => {
            NFTExchangeContract.methods.cancelOrder(NFTAddress, nft.assetId).send({ from: account }).then((res) => {
                console.log(res);
            }).catch((error) => {
                setSpinStatus(false);
                messageApi.error(error.data.message);
            });
        }).catch((error) => {
            setSpinStatus(false);
            messageApi.error('稍后再试');
        });
    }


    const createNFT = () => {
        return (<div className='row g-3 item-wrap'>
            {myCreatedNFT.length ? myCreatedNFT.map((item, key) => {
                return (<div className='px-2 col-sm-6 col-md-6 col-lg-3 ' key={key}>
                    <NFTItem nft={item} 
                             index={key} is_favor={item.is_favor} 
                             type='create'
                             callback={createOrder} ></NFTItem>
                </div>);
            }) : (<div className='my-4 py-4' style={{minHeight:300}}>
                <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60 }} 
                description={
                    <span className='fs-3 default-text-blur'>
                        {t('usertList5')}
                    </span>
                }>
            </Empty>
            </div>)}

        </div>);
    }


    const ownerNFT = () => {
        return (<div className='row g-3 item-wrap'>
            {myOwnerNFT.length ? myOwnerNFT.map((item, key) => {
                return (<div className='px-2 col-sm-6 col-md-6 col-lg-3' key={key}>
                    <NFTItem nft={item} index={key} is_favor={item.is_favor} type='owner' callback={createOrder} ></NFTItem>
                </div>);
            }) : (<Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60 }}
                description={
                    <span className='fs-3 default-text-blur'>
                        {t('usertList5')}
                    </span>
                }
            >
            </Empty>)}

        </div>);
    }

    const sellNFT = () => {
        return (<div className='row g-3 item-wrap'>
            {mySellNFT.length ? mySellNFT.map((item, key) => {
                return (<div className='px-2 col-sm-6 col-md-6 col-lg-3' key={key}>
                    <NFTItemSell nft={item} index={key} is_favor={item.is_favor} type='sell' callback={createOrder} callbackCancleOrder={callbackCancleOrder}></NFTItemSell>
                </div>);
            }) : (<Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60 }}
                description={
                    <span className='fs-3 default-text-blur'>
                        {t('usertList5')}
                    </span>
                }
            >
            </Empty>)}

        </div>);
    }



    const favouriteNFT = () => {
        return (<div className='row g-3 item-wrap'>
            {myFavouriteNFT.length ? myFavouriteNFT.map((item, key) => {
                return (<div className='px-2 col-sm-6 col-md-6 col-lg-3' key={key}>
                    <NFTItem nft={item} index={key} is_favor={item.is_favor} type='favourite' callback={createOrder} ></NFTItem>
                </div>);
            }) : (<Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60 }}
                description={
                    <span className='fs-3 default-text-blur'>
                        {t('usertList5')}
                    </span>
                }
            >
            </Empty>)}

        </div>);
    }

    const items = [

        // {
        //     key: '1',
        //     label: `${t('creatednft')} ${staticResult.create}`,
        //     children: createNFT()
        // }, 
        {
            key: '2',
            label: `${t('ownnft')} ${staticResult.own}`,
            children: ownerNFT()
        },
        {
            key: '3',
            label: `${t('sellnft')} ${staticResult.sell}`,
            children: sellNFT()
        },
        {
            key: '4',
            label: `${t('favournft')} ${staticResult.favour}`,
            children: favouriteNFT()
        },


    ];


    const clickItem = (number) => {
        updateList(staticResult.curTab, number);
    }
    const createPageNode = () => {
        let items = [];
        
        for (let number = 1; number <= staticResult.curPage; number++) {
            items.push(
                <Pagination.Item key={number} onClick={clickItem.bind('', number)} active={number === staticResult.activePage}>
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    }

    const createCreateForm = () => {
       
        return (<div className='text-white  m-2  py-4'>
                    <div className="m-4 p-1 avatar text-center  ">
                        
                        <div> <img alt=""  src={ (userInfo && userInfo.avatar ) ||  '/assets/no-picture.png'} className="rounded-circle" width={100} height={100} ></img></div>
                        <div className='pt-4'> <img  alt=""  src={'/assets/right.png'} className="rounded-circle" width={25} height={25} ></img></div>
                    </div>
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                    {staticResult.total ? (<div className='my-4'>
                        {/* <Pagination>{createPageNode()}</Pagination> */}
                        <Pagination defaultCurrent={staticResult.activePage} 
                                    total={staticResult.total}  
                                    pageSize={8}
                                    showSizeChanger={false} 
                                    onChange={clickItem} />
                    </div>) : null}
                </div>);
    }

    const getNFTList = async (url) => {
        return fetch(url).then((res) => res.json())
    }

    useEffect(() => {
        async function startWeb3() {
            var web = null;
            if (typeof window.web == 'undefined') {
                web = await startInitWeb();
            } else {
                web = window.web;
            }
            //获取我的铸造
            const url = domainURL + `/api/nft/mylist?address=${web.account}&type=owner`;
            getNFTList(url).then((res) => {
                if (res.code === 1) {
                    var newData = staticResult;
                    newData.curPage = Math.ceil(res.data.total / limit); 
                    newData.own = res.data.total;
                    setStaticResult(newData);
                    setMyOwnerNFT(res.data.list);
                }
            });

            //获取我的出售的
            const url3 = domainURL + `/api/nft/mylist?address=${window.web.account}&type=sell&pageNumber=1&pageSize=8`;
            getNFTList(url3).then((res) => {
                if (res.code == 1) {
                    var newData2 = staticResult; 
                    newData2.sell = res.data.total;
                    setStaticResult(newData2);
                    // setMySellNFT(res.data.list);
                }
            });



            //获取我收藏的数量

            const url4 = domainURL + `/api/nft/mylist?address=${window.web.account}&type=favorite&pageNumber=1&pageSize=8`;
            getNFTList(url4).then((res) => {
                if (res.code == 1) { 
                    var newData3 = staticResult; 
                    newData3.favour = res.data.total;
                    setStaticResult(newData3); 
                }
            });


            //获取我收藏的数量
            const url2 = domainURL + `/api/nft/userinfo?address=${window.web.account}`;
            getNFTList(url2).then((res) => {
                if (res.code === 1) { 
                    dispatch(setInfo(res.data));
                }
            });

        }
        startWeb3();

    }, [])

    return (
        <div className='bgLinearColor'>
            <Header></Header>
            {spinStatus ? (<Spin tip={tips} size="large">
                <div className="content" />
            </Spin>) : null}
            {contextHolder}
            <Container>
                <LinearBorderBox item={createCreateForm()}></LinearBorderBox>
            </Container>
            <VerticallyCenteredModal
                show={modalShow}
                loadingType={loadingType}
                loading={loading}
                handleclose={handleclose}
                oncallback={startCheckParam}>
            </VerticallyCenteredModal>
            <Footer></Footer>
        </div>
    )
}

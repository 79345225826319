import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
const homeSlice = createSlice({
    name: "home",
    initialState: {
        banner: [],
        collections: [],
        latest: [],
        sellers: [],
        searchList: [],
        NFTInfo: [],
        NFTOrder: [],
        NFTSymbol: {},
        searchListTotal:0
    },
    reducers: {
        setBannerData(state, action) {
            state.banner = action.payload;
        },
        setCollections(state, action) {
            state.collections = action.payload;
        },
        setLatest(state, action) {
            state.latest = action.payload;
        },
        setSellers(state, action) {
            state.sellers = action.payload;
        },
        setSearchList(state, action) { 
            state.searchList = action.payload;
        },
        setNFTInfo(state, action) {
            state.NFTInfo = action.payload;
        },
        setNFTOrder(state, action) {
            state.NFTOrder = action.payload;
        },
        setNFTSymbol(state, action) {
            state.NFTSymbol = action.payload;
        },
        setSearchListTotal(state, action) {
            state.searchListTotal = action.payload;
        },

    }
});

export const { setSearchListTotal,setBannerData, setCollections, setLatest, setSellers, setSearchList, setNFTInfo, setNFTOrder, setNFTSymbol } = homeSlice.actions;
export default homeSlice.reducer;


// export const loadMintData = createAsyncThunk("home/fetchHomeData",
//     async (data, { dispatch }) => {
//         const { NFTContract } = data;
//         var mintFee = await NFTContract.methods.mintFee().call();
//         dispatch(setMintFee(mintFee));
//     }
// );


import React from 'react'
 
import Web3 from 'web3';
export default function PledgeNFTItem(props) {
  const { nft, isSelect } = props;
  

  const boxStyle = {
    //background:"url('/assets/bg-round.png')",
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    boxShadow: 'rgb(136, 136, 136) 0px 0px 50px 10px inset',
  }
  const imgBox = {
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1
  }
  const selectPledge= ()=> {
    if(props.from == 'unPledge'){
      props.callback(nft.assetId);
    }else{
      props.callback(nft.assetId);
    } 
  }
  return (
    <div className='col-sm-12 col-md-12 col-lg-12' onClick={selectPledge}>
      <div className={isSelect == 1 ? 'top-box-shadow-select rounded-2' : 'top-box-shadow rounded-2'}>
        <div style={boxStyle} className="rounded-2">
          <div className="nft-items default-text-color overflow-hidden p-3"   >
            <a  className='nft-image overflow-hidden rounded-2  d-flex  '>
              <img src={nft.url} className='img-rounded img-fluid margin w-100' style={imgBox}></img>
            </a>
          </div>
          <div className='card-body content position-relative  p-3 pt-0'>
            <div className='d-flex justify-content-between mb-2 d-flex flex-row align-items-center'>
              <div className="img-group">
                <a className='user-avatar '>
                  <img src={(nft.selluser && nft.selluser.avatar) || "/assets/avatar.png"} className='avatar avatar-md-sm shadow-md rounded-pill'></img>
                </a>
              </div> 
              <div className="d-block fw-semi-bold h6  title default-text-color">#{nft && nft.assetId} </div>
            </div> 
            <div className="d-block fw-semi-bold h6  title default-text-color">{nft && nft.name} </div>
             
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function Loading() {
    return (
        <div><Spinner
            as="span"
            animation="border"
            role="status"
            size="sm"
            aria-hidden="true"
        />
        Loading...</div>
    )
}

import React from 'react'
import { HeartOutlined } from '@ant-design/icons';


export default function TopCollectionsItem(props) {
  const {nft} = props; 




  const boxStyle = {
    boxShadow: 'rgb(136, 136, 136) 0px 0px 50px 10px inset',
   
  }
  return (
    <div className='px-2  col-sm-12 col-md-6 col-lg-4'>
      <div className="top-box-shadow rounded-2">
        <div style={boxStyle} className="rounded-2">
          <div className=" default-text-color overflow-hidden p-3 d-flex  justify-content-between"   >

            <div className='overflow-hidden rounded-2 col-sm-7 pr-1'>
              <a href="/search" className='d-inline-block w-100 h-100'>
                <img src={nft.images.split(',')[0]} className='img-rounded img-fluid margin w-100 h-100 ' style={{objectFit:'cover'}}>
              </img></a>
            </div>

            <div className='rounded-2 d-flex flex-column justify-content-between  align-items-between col-sm-5' style={{ paddingLeft: 15 }} >
              <div className="mb-1"><a href="/NFT"><img src={nft.images.split(',')[1]} className='img-rounded img-fluid margin w-100'></img></a></div>
              <div className="mt-1"><a href="/NFT"><img src={nft.images.split(',')[2]} className='img-rounded img-fluid margin w-100'></img></a></div>
            </div>

          </div>

          <div className='card-body content position-relative p-3 pt-0'>
            <div className="d-block fw-semi-bold h6    default-text-color title">{nft.name}</div>
            <div className="d-flex justify-content-between mt-2">
            {/* in collectionC */}
              <small className="rate fw-semi-bold  default-text-bababa">{nft.tags}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Container} from 'react-bootstrap'
import TopCollectionsItem from './TopCollectionsItem'
import { useSelector } from 'react-redux';

export default function TopCollections() {
  const collections = useSelector(state => state.home.collections);

  return (
    <Container>
      <div className='row g-3 item-wrap my-4'>
        {collections.map((item,key) => {
          return (<TopCollectionsItem nft={item} key={key}></TopCollectionsItem>);
        })}
          
      </div>
    </Container>
  )
}

import React, { useEffect, useState } from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { Container } from 'react-bootstrap'
import { Pagination, Card } from 'antd'
import { beautySub, domainURL, ERCAddress, NFTAddress, NFTExchangeAddress, BNBAddress } from '../../util/util'
import { HeartOutlined, HeartFilled, TableOutlined, AppstoreOutlined, InsertRowLeftOutlined, SketchOutlined, TwitterOutlined,InstagramOutlined,ShareAltOutlined,GlobalOutlined  } from '@ant-design/icons';
import { Col, Divider, Row, Select, Avatar } from 'antd';

import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { startInitWeb } from '../../util/startInitWeb' 
import Web3 from 'web3'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo } from '../../redux/slices/userSlice'

const limit = 8;
const { Meta } = Card;

//const className = 'fs-4 default-text-color  px-3 pt-2 pb-2';
//const className = 'fs-4 default-text-color  px-3 py-1 mt-4 mb-1';

export default function UserNFTList() {
    const search = window.location.search;
    const params = new URLSearchParams(search); // 使用 URLSearchParams 解析查询字符串
    const address = params.get('assetId');

    const { t } = useTranslation();
    const [tips, setTips] = useState('');
    const userInfo = useSelector(state => state.user.info);
    const [spinStatus, setSpinStatus] = useState(false);
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    
    
    const [staticRes, setStaticRes] = useState([]);
    const [tableSpan, setTableSpan] = useState(8);
    const [showDesc, setShowDesc] = useState(true);
    const [type, setType] = useState(1);
    const dispatch = useDispatch();

    useEffect(() => {
        async function startWeb3() {
            var web = null;
            if (typeof window.web == 'undefined') {
                web = await startInitWeb();
            } else {
                web = window.web;
            }

            var url = domainURL + '/api/nft/mylist?type=sell&address=' + `${address}&pageNumber=1&pageSize=8`;// + JSON.stringify(json)
            getNFTList(url).then((res) => {
                setTotal(res.data.total);
                setList(res.data.list);
            }); 

            var url2 = domainURL + '/api/index/info?address='+address;// + JSON.stringify(json)
            getNFTList(url2).then((res) => {
                setStaticRes(res.data);
            }); 
            const url3 = domainURL + `/api/nft/userinfo?address=${address}`;
            getNFTList(url3).then((res) => {
                if (res.code === 1) { 
                    dispatch(setInfo(res.data));
                }
            });
        }
        startWeb3();

    }, [])
    const createStatic = () => {
        return (<Card hoverable
            style={{ width: '100%' }}
            className="default-bg-blur border-0 my-4">
            <div className="d-flex flex-row flex-row justify-content-around default-text-color  rounded-2 ">
                <div className='flex justify-content-center'>
                    <div className='my-1 fw-bold h3'>{staticRes?.total}BNB</div>
                    <div className='my-1 text-center'> {t('st1')}</div>
                </div>
                <div className='flex  justify-content-center'>
                    <div className='my-1 fw-bold h2'>{staticRes?.max}BNB</div>
                    <div className='my-1  text-center'>{t('st2')}</div>
                </div>
                <div className='flex justify-content-center'>
                    <div className='my-1 fw-bold h2'>{staticRes?.min}BNB</div>
                    <div className='my-1  text-center'>{t('st3')}</div>
                </div>
                <div className='flex justify-content-center'>
                    <div className='my-1 fw-bold h2'>{staticRes?.avg}BNB</div>
                    <div className='my-1  text-center'>{t('st4')}</div>
                </div>
            </div>
        </Card>);
    }




    const getNFTList = async (url) => {
        return fetch(url).then((res) => res.json())
    }

    const clickTable = (type) => {
        setType(type);
        if (type == 1) {
            setTableSpan(8);
            setShowDesc(true);
        } else if (type == 2) {
            setTableSpan(6);
            setShowDesc(true);
        } else if (type == 3) {
            setTableSpan(8);
            setShowDesc(false);
        }
    }
    const handleChange = (data) => {
        console.log(data);
    }
    const imgBox = {
        resizeMode: 'contain',
        flex: 1,
        aspectRatio: 1,
        height: 240
    }

    const getShortAddress = (value) => {
        return value.substring(0, 5)+"****"+value.substr(value.length-4);
    }

    const getJoinTime = (timeStamp) => {
        let curDate = new Date(timeStamp);
        let year = curDate.getFullYear();
        let month = curDate.getMonth();
        return (month+1)+' - '+year;
    }
    const createHeader = () => {
        return (<Row className='my-5' gutter={{  xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}>
            <Col className="gutter-row mb-4 ps-5" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 15 }} md={{ span: 15 }} span={8}>
                 
                <div className='default-text-color fs-2 fw-bold mb-3'>
                    <Avatar size={100} src={userInfo.avatar} />
                </div>
                <div className='default-text-color fs-2 fw-bold mb-3'>
                {userInfo.nickname && getShortAddress(userInfo.nickname)}<SketchOutlined style={{color:'#339DCF',paddingLeft:10}} />
                </div>
                <div className='default-text-color  mb-3'>
                    {t('info1')} {userInfo.nickname && getShortAddress(userInfo.nickname)} <SketchOutlined style={{color:'#339DCF',paddingLeft:10}} />
                </div>
                <div className='default-text-color fw-bold mb-3'>
                 {t('info4')}2%  ·  BNB Smart Chain
                </div>
                <div className='default-text-color'>
                    {userInfo.bio}
                </div>
            </Col>
            <Col className="gutter-row mb-4 text-center" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 9 }} md={{ span: 9 }} span={8}>
                <div className="banner-nft mb-4" xs={12} lg={12} md={12} sm={12}>
                    <img src={getUserBanner() } 
                         className="rounded img-fluid" 
                         style={imgBox} alt="NFT" />
                </div>
                <div>
                <a href={userInfo.twitter}  target="_blank">
                    <TwitterOutlined className='cursor-pointer px-2'  style={{color:'#E5E5E5',fontSize:20}}/>
                    </a>
                    <a href={userInfo.ins}  target="_blank">
                    <InstagramOutlined className='cursor-pointer  px-2' style={{color:'#E5E5E5',fontSize:20}}/> 
                    </a>
                    <a href={window.GeolocationCoordinates.href}  target="_blank"> 
                    <span style={{color:'#E5E5E5',padding:'0px 20px'}}>|</span> 
                    <GlobalOutlined className='cursor-pointer  px-2' style={{color:'#E5E5E5',fontSize:20}}/>
                    </a>
                    <a href={userInfo.websitelink}  target="_blank"> 
                    <ShareAltOutlined className='cursor-pointer  px-2' style={{color:'#E5E5E5',fontSize:20}}/>
                    </a>  
                </div>
            </Col>
        </Row>);
    }


    const createNFTSelect = () => {
        return (<div>
            <div className='d-flex justify-content-end my-4'>
                <Select defaultValue="选择筛选条件"
                    bordered={true}
                    style={{ width: 240, height: 45, color: '#E5E5E5', borderRadius: 0, background: 'rgba(0,0,0,0)' }}
                    onChange={handleChange}
                    options={[{
                        value: '1', label: '价格从低到高',
                    },
                    {
                        value: '2',
                        label: '价格从高到低',
                    },
                    {
                        value: '3',
                        label: '一口价',
                    },
                    {
                        value: '4',
                        label: '拍卖',
                    },
                    ]}
                />
                <div onClick={clickTable.bind(this, 1)} className='p-2  cursor-pointer' style={{ border: '1px solid #E5E5E5', borderLeft: 0 }} ><TableOutlined style={{ fontSize: '25px', color: type == 1 ? '#339DCF' : '#E5E5E5' }} /></div>
                <div onClick={clickTable.bind(this, 2)} className='p-2  cursor-pointer' style={{ borderTop: '1px solid #E5E5E5', borderBottom: '1px solid #E5E5E5' }}><AppstoreOutlined style={{ fontSize: '25px', color: type == 2 ? '#339DCF' : '#E5E5E5' }} /></div>
                <div onClick={clickTable.bind(this, 3)} className='p-2  cursor-pointer  ' style={{ border: '1px solid #E5E5E5' }}><InsertRowLeftOutlined style={{ fontSize: '25px', color: type == 3 ? '#339DCF' : '#E5E5E5' }} /></div>
            </div>
        </div>);
    }

    const getUserBanner = () => {
        if(userInfo.banner){
            return userInfo.banner;
        }else if(list.length > 0){
            return list[0]['nft']['url']
        }else{
            return 'https://oceannft.oss-cn-hongkong.aliyuncs.com/uploads/20230510/6276403ca34360ba7723273acbef39c5.png';
        }
    }

    const createNFTList2 = () => {
        return (<Row gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
        }}>
            {list.map((item) => {
                return (<Col className="gutter-row mb-4" xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: tableSpan }} md={{ span: 12 }} span={8}>
                    <Card hoverable
                        bodyStyle={showDesc ? {} : { showDescheight: 0, padding: 0 }}
                        cover={<img alt="NFT" src={item.nft?.url} style={{ height: 300, objectFit: 'cover' }} className='img-fluid' />}>

                        {showDesc ? (<div>
                            <Meta title={item.nft?.name} description={item.nft?.attributes} />
                            <div className='d-flex justify-content-between mb-2 d-flex flex-row align-items-center '>
                                <div className="img-group">
                                    {Web3.utils.fromWei(item.price)}BNB
                                </div>
                                <div className="btn-group px-3  d-flex flex-row justify-content-between align-items-center cursor-pointer" >
                                    {true ? (<HeartFilled style={{ fontSize: '16px', color: 'red' }} />) : (<HeartOutlined style={{ fontSize: '16px', color: '#E5E5E5' }} />)}
                                    <div className='default-text-color'>{item.nft?.favorite}</div>
                                </div>
                            </div></div>) : null}
                    </Card>
                </Col>);
            })}
        </Row>);
    }

    const style = {
        background: '#0092ff',
        padding: '8px 0',
    };

    const clickItem = (number) => {
        getNFTLists(number);
        setPageNumber(number);
    }


    const getNFTLists = async(number) => {
        var url = domainURL + '/api/nft/mylist?type=sell&address=' + `${address}&pageNumber=${number}&pageSize=8`;// + JSON.stringify(json)
        getNFTList(url).then((res) => {
            setTotal(res.data.total);
            setList(res.data.list);
        }); 
    }


    return (
        <div className='bgLinearColor'>
            <Header></Header>
            <Container>
                {createHeader()}
                {createStatic()}
                {createNFTSelect()}
                {createNFTList2()}
                <div className='my-4'>
                    <Pagination defaultCurrent={setPageNumber} 
                                total={total}  
                                pageSize={8}
                                showSizeChanger={false} 
                                onChange={clickItem} />
                </div>
                {/*                         
                 {createNFTList()} */}
            </Container>
            <Footer></Footer>
        </div>
    )
}

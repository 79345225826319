import React, { useEffect, useState } from 'react' 
import NFTList from './NFTList'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { Container } from 'react-bootstrap'
import { Pagination } from 'antd' 
import DropdowBox from './DropdowBox'
import { fetchData, domainURL } from '../../util/util'
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux'
import { setSearchList, setSearchListTotal } from '../../redux/slices/homeSlice'
import { Empty } from 'antd'
const limit = 8;

export default function Search() {
    const dispatch = useDispatch();
    const search = window.location.search; // 获取 URL 中的查询字符串，如 "?foo=bar"
    const params = new URLSearchParams(search); // 使用 URLSearchParams 解析查询字符串 
    const keyword = params.get('search') ? params.get('search') : '';
    let attributes = params.get('attributes') ? params.get('attributes') : '';
    attributes = attributes  == 1 ? '' : attributes;

    const latestList = useSelector(state => state.home.searchList);
    const searchListTotal = useSelector(state => state.home.searchListTotal);
    

    const [staticResult, setStaticResult] = useState({ curPage:0,activePage:1 });
     

   

    const clickItem = (number) => { 
        changeNFTList(number,attributes);
      }
      
       
    const createNFTList = () => {
        return (<div className='m-auto d-flex  justify-content-center align-items-center w-100' style={{ minHeight: '550px' }}>
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60 }}
                description={
                    <span className='fs-3 default-text-blur'>
                        Data Not Found
                    </span>
                }
            >
            </Empty>
        </div>);
    }

    const getNFTList = async (url) => {
        return fetch(url).then((res) => res.json())
    }
    
    

    const changeNFTDropBoxList = (value1,value2,value3) => { 
        // var newData = staticResult; 
        // newData.activePage = 1;   
        // setStaticResult(newData);  
        changeNFTList(0,value1,value2,value3);
    }   


    const changeNFTList = async (page,value1='',value2='',value3='') => {
        // var json = { 'nft.nft_group_id': group, 'nft.nft_network_id': network };
        let newPage = page > 0 ? (page-1) : 0;
        var url = domainURL + '/api/search/index?search=' + keyword + `&offset=${newPage * limit}&limit=${limit}&attributes=${value1}&collection=${value2}&network=${value3}`;// + JSON.stringify(json)
        getNFTList(url).then((res) => { 
                var newData = staticResult;
                newData.curPage = Math.ceil(res.total / limit);  
                newData.activePage = page;   
                setStaticResult(newData);  
                dispatch(setSearchList(res.rows));
                dispatch(setSearchListTotal(res.total)); 
        });
    }

    const changeSearch = (keyword) => {
        var url = domainURL + '/api/search/index?search=' + keyword + `&offset=0&limit=${limit}&attributes=&collection=&network=`;// + JSON.stringify(json)
        getNFTList(url).then((res) => { 
                var newData = staticResult;
                newData.curPage = Math.ceil(res.total / limit);  
                newData.activePage = 1;   
                setStaticResult(newData);  
                dispatch(setSearchList(res.rows));
                dispatch(setSearchListTotal(res.total)); 
        });
    }

    useEffect(() => { 
            var url = domainURL + '/api/search/index?search=' + keyword + `&offset=0&limit=${limit}&attributes=${attributes}&collection=&network=`;// + JSON.stringify(json)
            getNFTList(url).then((res) => { 
                    var newData = staticResult;
                    newData.curPage = Math.ceil(res.total / limit);  
                    newData.activePage = 1;   
                    setStaticResult(newData);  
                    dispatch(setSearchList(res.rows));
                    dispatch(setSearchListTotal(res.total)); 
            }); 
    }, [])



    
    const createPageNode = () => {
        let items = [];
        var page = Math.ceil(searchListTotal / limit);
 
        for (let number = 1; number <= page; number++) {
            items.push(
                <Pagination.Item key={number} onClick={clickItem.bind('', number)} active={number === staticResult.activePage}>
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    }

    const onChange = (value) => {
        console.log(value);
    }

    


    return (
        <div className='bgLinearColor'>
            <Header callback={changeSearch}></Header>
            <Container >
                <div className='py-4 my-4'>
                    <div className='mb-4'>
                        <DropdowBox callback={changeNFTDropBoxList}></DropdowBox>
                    </div>
                    
                    {latestList.length > 0 ? (<NFTList type="search"></NFTList>) : createNFTList()}
                    {latestList.length > 0 ? (<div className='my-4 '>
                                                    {/* <Pagination>{createPageNode()}</Pagination> */}
                                                    <Pagination defaultCurrent={staticResult.activePage} 
                                                                total={searchListTotal}  
                                                                pageSize={8}
                                                                showSizeChanger={false} 
                                                                onChange={clickItem} />
                                                </div>
                                                ) : null}
                </div>

            </Container>
            <Footer></Footer>
        </div>
    )
}

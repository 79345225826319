import Web3 from "web3";
import pledgeAbi from '../build/listAbi/pledgeAbiV2.json';
import nftAbi from '../build/listAbi/nftAbi.json';
var web3 = new Web3(Web3.givenProvider);

const startNftPledge = async (param) => {
    console.log(param);
    let account = await web3.eth.requestAccounts();
    var nftContract = await new web3.eth.Contract(nftAbi, param.nft_address);
    var Pledge2Contract = await new web3.eth.Contract(pledgeAbi, param.address); 
    var initPledge = {
        web3,
        nftContract,
        Pledge2Contract,
        account: account[0]
    };  
    return initPledge;
      
};
export { startNftPledge };


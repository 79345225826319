import React, { useState } from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import NFTInfo from './NFTInfo'
import NFTActivity from './NFTActivity'
import { Container } from 'react-bootstrap'
import { fetchData, domainURL, NFTAddress } from '../../util/util'
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom'
import { setNFTInfo, setNFTOrder } from '../../redux/slices/homeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { message } from 'antd'


export default function NFT() { 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const search = window.location.search; // 获取 URL 中的查询字符串，如 "?foo=bar"
    const params = new URLSearchParams(search); // 使用 URLSearchParams 解析查询字符串
    const nftAddress = NFTAddress;//params.get('nftAddress');//'0x2c11376fbb50c0704ad36918532b64cecc724645';//params.get('nftAddress');
    const assetId = params.get('assetId');
    const [messageApi, contextHolder] = message.useMessage();
    const { data, error, isLoading } = useSWR([domainURL+`/api/nft/orderinfo?nftAddress=${nftAddress}&assetId=${assetId}`], fetchData);
    const info = useSelector(state => state.home.NFTInfo);

    const { t } = useTranslation();
 
    if(data){ 
        if(data.data.order == null){
            // messageApi.info(t('nft20'));
            // setTimeout(function(){
            //     navigate('/');
            // },2000);
           
        }
        dispatch(setNFTInfo(data.data.info));
        dispatch(setNFTOrder(data.data.order));
    }

    
    const style ={
        color:'#fff'
    }
    return (
        <div className='bgLinearColor'> 
        {contextHolder}
            <Header></Header>
            <Container>
                <NFTInfo assetId={assetId}  ></NFTInfo>
                <NFTActivity></NFTActivity>
                <div className='text-center fs-2 my-4 py-4 default-text-color'>
                        <a style={style} href={`/search?attributes=${info.attributes}`}>{t('nft17')}</a> 
                </div>
            </Container>
            <Footer></Footer> 
        </div>
    )
}

import { DatePicker } from 'antd';
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Loading from './Loading';
import dayjs from 'dayjs'
import { message, Spin, Input } from 'antd'; 

export default function ModalToken(props) {

    const [priceValue, setPriceValue] = useState(0);
    const { t  } = useTranslation();
    
    const changeValue = (event) => {
            setPriceValue(event.target.value);
        // props.handlechange(event.target.value);
        
    }
    
    const handleClose = (event) => { 
        props.handleclose();
   }

    const clickMax = (event) => {
        setPriceValue(props.amount)
    }

   const makeOffer = (event) => {
    console.log(priceValue);
    if(priceValue > 0){ 
		
        const offerType = event.target.getAttribute('type');
        props.oncallback(String(priceValue),offerType);
		

    }else{ 
        message.info(t('请输入数量'));
    } 
   }


    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='lp_modal'
            onHide={handleClose}
        > <div className='pup-outer'>
                <Modal.Header>
                    <Modal.Title className='stake_title'>{t('stakelpp')}</Modal.Title>
                    <svg onClick={handleClose} viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg" className="close_icon"><path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z"></path></svg>
                </Modal.Header>
                <Modal.Body>
                <div className="input-group lp_form">
                    <div className='hdmGsl'>
                        <div className='cgvgt'>{t('stakelp11')}</div>
                        <div className='cgvgt'>{t('stakelp2')}{props.amount}</div>
                    </div>    
                    <div className='kldLoa'>
                        <input type='text' value={priceValue}  pattern="[0-9.]*" className='lp_input' onChange={changeValue} placeholder={t('farms12')}></input>
                        <button className='base_btn2 lp_maxbtn' onClick={clickMax}>{t('max')}</button>
                        <div className='iyQkTx'>{props.title}</div>
                    </div>    
                </div>
                    <div className='base_btnbox' style={{marginTop: '24px'}}>
                        <button className='base_btn base_item' onClick={handleClose}>{t('stakelp3')}</button>
                        <button type ="0" onClick={makeOffer} disabled={!priceValue || props.loading} className='base_btn2 base_item' style={{marginLeft:'24px'}}>{props.loading?t('stakelp5'):t('stakelp4')}</button>
                    </div>
                    
                    

                     
                    
                   
                </Modal.Body>
            </div>
        </Modal>

    )
}

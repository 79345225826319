import React from 'react'
import { Accordion, Container, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { beautySub, convertTimestamp } from '../../util/util';
import ContentTitle from '../common/ContentTitle'
import { useTranslation } from 'react-i18next';

export default function NFTActivity() {

    const info = useSelector(state => state.home.NFTInfo);
    const NFTSymbol = useSelector(state => state.home.NFTSymbol);
    const { t, i18n } = useTranslation();

    const getShortAddress = (value) => {
        return value.substring(0, 5)+"****"+value.substr(value.length-4);
    }
    const boxStyle = {
        boxShadow: 'rgb(136, 136, 136) 0px 0px 50px 10px inset',
        borderRadius: 15,
    }

    const renderActivity = () => {
        return info && info.transfer && info.transfer.map(function (item,key) {
            return (<tr key={key}>
                <td>{item.from == '0x0000000000000000000000000000000000000000' ? 'Mint' : 'Transfer'}</td>
                {/* <td>{item.price || 0} BNB</td>  */}
                <td>{item.from && getShortAddress(item.from)}</td>
                <td>{item.to && getShortAddress(item.to)}</td>
                <td>{convertTimestamp(item.createtime)}</td>
            </tr>);
        })

    }



    return (
        <Container style={boxStyle} >
            <div className='py-4 my-4 flex-row' id="table-nft">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <ContentTitle title={t('nft9')}></ContentTitle>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Table borderless responsive hover className='default-text-color table-striped'>
                                <thead>
                                    <tr>
                                        <th>{t('nft10')}</th>
                                        {/* <th>{t('nft11')}</th> */}
                                        <th>{t('nft12')}</th>
                                        <th>{t('nft13')}</th>
                                        <th>{t('nft14')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderActivity()}
 
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header><ContentTitle title="NFT Listings"></ContentTitle></Accordion.Header>
                        <Accordion.Body>
                        <Table borderless hover responsive className='default-text-color table-striped'>
                    <thead>
                        <tr>
                            <th>Events</th>
                            <th>Price</th>
                            <th>Form</th>
                            <th>To</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>

                    </tbody>
                </Table>
                        </Accordion.Body>
                    </Accordion.Item>*/}
                </Accordion>
            </div>


            {/* <div className='p-4 my-4 flex-row' id="table-nft">
                <ContentTitle title="NFT Activity"></ContentTitle>
                <Table borderless hover className='default-text-color table-striped'>
                    <thead>
                        <tr>
                            <th>Events</th>
                            <th>Price</th>
                            <th>Form</th>
                            <th>To</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>

                    </tbody>
                </Table>
                <div className="gap-border my-3"></div>
                <ContentTitle title="NFT Listings"></ContentTitle>
                <Table borderless hover className='default-text-color table-striped'>
                    <thead>
                        <tr>
                            <th>Events</th>
                            <th>Price</th>
                            <th>Form</th>
                            <th>To</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>
                        <tr>
                            <td>Offer</td>
                            <td>0.0900000 MATIC</td>
                            <td>0x3...A09bD6</td>
                            <td></td>
                            <td>23-03-01</td>
                        </tr>

                    </tbody>
                </Table>
            </div> */}

        </Container >
    )
}

 
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Loading from './Loading';

export default function VerticallyOffer(props) {

    const [priceValue, setPriceValue] = useState(0);
    const { t  } = useTranslation();
    
    const changeValue = (event) => {
        setPriceValue(event.target.value);
    }
   
    const handleClose = (event) => { 
         props.handleClose();
    }

    const startCallBack = () => {
        props.oncallback(priceValue);
    }
    
    


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
        > <div className='pup-outer'>
                <Modal.Header  >
                    <Modal.Title>{t('nft8')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="input-group mb-3">
                        <span className="input-group-text bg-blur text-white">BNB</span>
                        <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)" onChange={changeValue} />
                        {/* <span className="input-group-text  bg-blur  text-white">$</span> */}
                    </div>
                     
                     
                </Modal.Body>
                <Modal.Footer> 
                    <Button className='but ton-offer  button-common  py-2   me-3 my-4 fs-6 fw-bold  hvr-shutter-in-horizontal overflow-hidden  rounded-4' 
                            disabled={props.loading} 
                            onClick={startCallBack}> 
                        {props.loading && props.loadingType == 'nftoffer' ? <Loading></Loading> : t('nft8')}
                        </Button>
                </Modal.Footer>
            </div>
        </Modal>

    )
}

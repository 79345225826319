import { DatePicker } from 'antd';
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Loading from './Loading';
import dayjs from 'dayjs'
import { message, Spin } from 'antd'; 

export default function VerticallyCenteredModal(props) {

    const [priceValue, setPriceValue] = useState(0);
    const [dateValue, setDateValue] = useState(0);
    const [offerType, setOfferType] = useState(0);
    const { t  } = useTranslation();
    


    const changeDate = (date, dateString) => {
        setDateValue(Date.parse(dateString) / 1000);
    }
    const changeValue = (event) => {
        if(event.target.value > 0){
            setPriceValue(event.target.value);
        } 
        
    }
    const changeType = (event) => { 
        let curOfferType = event.target.value == '挂单' ? 0 : 1;
         
        setOfferType(curOfferType);
    }
    
    const handleClose = (event) => { 
        props.handleclose();
   }

   const makeOffer = (event) => {
    if(priceValue > 0){ 
        if(offerType == 0){
            props.oncallback(priceValue, 253372448750, offerType);
        }else{
            props.oncallback(priceValue, dateValue, offerType);
        }
    }else{
        message.info(t('usertList1'));
    }
    
    

   }
   const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
        > <div className='pup-outer'>
                <Modal.Header >
                    <Modal.Title>{t('nft8')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="input-group mb-3">
                        <span className="input-group-text bg-blur text-white">{t('salesType')}</span>
                        <Form.Group  >
                            <Form.Select className='border-0 text-blank rounded-0'  onChange={changeType}   >
                                <option >{t('listing')}</option>
                                <option >{t('auction')}</option>
                            </Form.Select>
                        </Form.Group>
                        {/* showTime={true} */}
                    </div>
                    {offerType == 1 ? (<div className="input-group mb-3">
                        <span className="input-group-text bg-blur text-white">{t('expirationTime')}</span>
                        <DatePicker className="form-control"
                                    disabledDate={disabledDate}
                                    placeholder={t('selectDate')} 
                                    showTime={{ format: 'HH:mm:ss' }}
                                    onChange={changeDate} />
                                    
                    </div>) : null}

                    <div className="input-group mb-3">
                        <span className="input-group-text bg-blur text-white">BNB</span>
                        <input type="number"  min='0'  className="form-control" aria-label="Amount (to the nearest dollar)" onChange={changeValue} />
                        {/* <span className="input-group-text  bg-blur  text-white">$</span> */}
                    </div>
                    
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button className='but ton-offer  button-common  py-2   me-3 my-4 fs-6 fw-bold  hvr-shutter-in-horizontal overflow-hidden  rounded-4' 
                            disabled={props.loading} 
                            onClick={makeOffer}> 
                        {props.loading && props.loadingType == 'sellorder' ? <Loading></Loading> : t('nft8')}
                        </Button>
 
                </Modal.Footer>
            </div>
        </Modal>

    )
}

import { DatePicker } from 'antd';
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Loading from './Loading';
import dayjs from 'dayjs'
import { message, Spin, Input } from 'antd'; 

export default function ModalToken(props) {

    const [priceValue, setPriceValue] = useState(0);
    const [dateValue, setDateValue] = useState(0);
    const [offerType, setOfferType] = useState(0);
    const { t  } = useTranslation();
      
    const changeDate = (date, dateString) => {
        setDateValue(Date.parse(dateString) / 1000);
    }
    const changeValue = (event) => {
        if(event.target.value > 0){
            setPriceValue(event.target.value);
        }
        props.handlechange(event.target.value);
        
    }
    const changeType = (event) => { 
        let curOfferType = event.target.value == '挂单' ? 0 : 1;
         
        setOfferType(curOfferType);
    }
    
    const handleClose = (event) => { 
        props.handleclose();
   }

  

   const makeOffer = (event) => {
    console.log(priceValue);
    if(priceValue > 0){ 
		
        const offerType = event.target.getAttribute('type');
        props.oncallback(priceValue,offerType);
		

    }else{ 
        message.info(t('请输入数量'));
    } 
   }
   const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };


    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='lp_modal'
            onHide={handleClose}
        > <div className='pup-outer'>
                <Modal.Header >
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="input-group lp_form">
                    {/* <Form.Item  >
                         
                    </Form.Item> */}
                    <Input type='number' onChange={changeValue} placeholder={t('farms12')}></Input>
                    {/* showTime={true} */}
                </div>
                <div className="input-group lp_form_amount">
                    <div>Lp</div>
                    <div>{props.amount}</div>
                </div>
                <div className="input-group lp_form_amount">
                    <div>{t('farms13')}</div>
                    <div>${props.ROI}</div>
                </div>
                    

                     
                    
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button  type ="1" className='but ton-offer  button-common  py-2   me-3 fs-6 fw-bold  hvr-shutter-in-horizontal overflow-hidden  rounded-4' 
                            disabled={props.loading} 
                            onClick={makeOffer}> 
                        {props.loading && props.loadingType == 'pledge' ? <Loading></Loading> : t('pledge')}
						
                        </Button>
						
						<Button type ="0" className='but ton-offer  button-common  py-2   me-3 fs-6 fw-bold  hvr-shutter-in-horizontal overflow-hidden  rounded-4'
						        disabled={props.loading} 
						        onClick={makeOffer}> 
						    {props.loading && props.loadingType == 'redeem' ? <Loading></Loading> : t('redeem')}
						    </Button>
                    <div style={{width:'100%'}}>
                    <p className='link' style={{justifyContent:'center'}}>
                        <a target="_blank" style={{ textDecoration: 'none' }} href={props.lpurl}>{t('farms7')} {props.title} LP</a>
                        <svg viewBox="0 0 24 24" color="primary" width="16px" xmlns="http://www.w3.org/2000/svg" class="sc-bdfBQB bYgUAi"><path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path></svg>
                    </p>
                    </div>
 
                </Modal.Footer>
            </div>
        </Modal>

    )
}

const Web3 = require("web3");
const moment = require("moment"); 

function checkWallectEnv(wallet = '') {
    if (typeof window.ethereum == 'undefined') {
        return false;
    } else {
        return wallet ? window.ethereum[wallet] : true;
    }
}
function checkWallectConnect() { 
    return window.ethereum.isConnected(); 
}

function beautySub(str, len) {
    var reg = /[\u4e00-\u9fa5]/g,slice = str.substring(0, len),
        cCharNum = (~~(slice.match(reg) && slice.match(reg).length)),
        realen = slice.length*2 - cCharNum-1;
    return str.substr(0, realen) + (realen < str.length ? "..." : "");
}

let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
const fetchData = (...args) => fetch(...args,{method:'post',headers:{'lang':lang}}).then((res) => res.json());

function fromWei(price){
     
    return Web3.utils.fromWei(price);
}
function toWei(price){
    return Web3.utils.toWei(price);
}
function convertTimestamp(n) { 
    if(n){
        return moment(n * 1000).format("YYYY-MM-DD HH:mm:ss")
    }else{
        return '';
    }
    
  }
  


const domainURL = 'https://api.oceannft.io';
//const domainURL = 'ttps://auction.cswtest.com';
 
const NFTAddress = '0x8EE0C2709a34E9FDa43f2bD5179FA4c112bEd89A';     //NFT合约地址
const NFTTokenAddress = '0x2c11376FbB50c0704aD36918532B64CEcc724645'; //测试USDT合约
const NFTExchangeAddress = '0x6D55003BD21351241306558CCd7B52e3E7135e19'; //NFT交易所


const pledgeAddress = '0x96421a6c107a747b6d98b87Be3EFBcc0D341d037';  //NFT质押挖矿合约xxx
const NFTBNOTokenAddress = '0xc10c96b80DE6C1C4f13F7A3084222b83A33384df';  //测试BNO合约
const NFTPledgeAddress = pledgeAddress; //NFT质押挖矿合约

const ERCAddress = '0x2c11376FbB50c0704aD36918532B64CEcc724645';

const BNBAddress = '0x0000000000000000000000000000000000000000';




// 测试网

// const BNO = '0xf644E8556f9ed4D2cc3B7D6668cD4e5E623b3bC4'
// const USDT = '0x05aa278a9cB6e14131Ed77d958216FEBc44120D6'
// const Router = '0xd99d1c33f9fc3444f8101754abc46c52416550d1'
// const stakeAddress = '0x3Ad7E6dE3723041A65dAeEf57791fE022F285721'// lp质押合约
// const lpAddress = '0x59A612516e90C48254a5C2e08f786903caE1145A'// lp地址
// 主网
const BNO = '0xc10c96b80de6c1c4f13f7a3084222b83a33384df'
const USDT = '0x55d398326f99059ff775485246999027b3197955'
const Router = '0x10ed43c718714eb63d5aa57b78b54704e256024e'
const stakeAddress = '0x1e83B7E6CF8b0e87C12696FEc7d12b113095aEfC'// lp质押合约
const lpAddress = '0x4ea2121ef2d4fdac3d3481f5c487e29cdd9baaf9'// lp地址

module.exports = {
    checkWallectEnv,
    checkWallectConnect,
    beautySub,
    fetchData,
    domainURL,
    fromWei,
    convertTimestamp,
    toWei,
    NFTAddress,
    NFTTokenAddress,
    NFTExchangeAddress,
    ERCAddress,
    pledgeAddress,
    NFTPledgeAddress,
    NFTBNOTokenAddress,
    BNBAddress,
    BNO,
    USDT,
    Router,
    stakeAddress,
    lpAddress
}
import React from 'react'
import { Container} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import TopSellerItem from './TopSellerItem'

export default function TopSeller() {
  const sellers = useSelector(state => state.home.sellers);
  return (
    <Container>
      <div className='row g-3 item-wrap my-5'>
        {sellers.map((item,key) => {
          return (<TopSellerItem key={key} index={key} seller={item}></TopSellerItem>);
        })}
            
      </div>
    </Container>
  )
}

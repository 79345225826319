import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useCountdown } from '../../util/useCountdown';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { convertTimestamp } from '../../util/util';
 
export default function Banner(props) {
    //const { info } = props;
    const banner = useSelector(state => state.home.banner); 
    const [days, hours, minutes, seconds] = useCountdown(convertTimestamp(banner ? banner.expiresAt : ''));
    const { t, i18n } = useTranslation();

    
     
    const navigate = useNavigate();
    const goSearch = () => {
        navigate('/search');
    }
    const goMint = () => {
        navigate('/createNFT');
    }
    const imgBox = {
        resizeMode: 'contain',
        flex: 1,
        aspectRatio: 1,
        minHeight:240
      }
   
    return (
        <Container className="container">
            <Row className='m-auto  flex-row justify-content-around pt-4'>
                <Col className="col-md-7 d-flex flex-row justify-content-center align-items-center" xs={12} lg={5} md={5} sm={12}>
                    <div className=''>
                        <Row className='py-5'>
                            <Col className="" xs={12} lg={12} md={12} sm={12}>
                                <div className='text-left'>
                                    <span className='bannerText1 h1'>Mint,Buy,</span>

                                    <span className='text-white h1'> and Sell Your NFT</span>
                                </div>
                            </Col>
                        </Row>
                        <Row className='py-5'>
                            <Col className="" xs={12} lg={12} md={12} sm={12}>
                                <div className='text-left'>
                                    <span className='text-white h5'>OCEAN is the biggest Blockchain  based NFT marketplace</span>
                                </div>
                            </Col>
                        </Row>

                        <Row className=''>
                            <Col className="text-left" xs={6} lg={6} md={6} sm={6}>
                                <Button className='hvr-shutter-in-horizontal overflow-hidden  btn button-ming rounded-2  w-85 ' onClick={goMint} size="md">{t('mint')}</Button>
                            </Col>
                            <Col className="text-center" xs={6} lg={6} md={6} sm={6}>
                                <Button className='overflow-hidden  btn button-search rounded-2  w-85' onClick={goSearch} size="md">{t('searchBtn')}</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={12} lg={5} md={5} sm={12} className="col-md-5 ">
                    <Row className='mt-5 mt-md-0 text-center margin justify-content-center banner-nft' >
                        <Col xs={12} lg={12} md={12} sm={12} className="row  justify-content-center">
                            <div className="nft-card-img mb-4" xs={12} lg={12} md={12} sm={12}>
                                <img src={(banner && banner.nft && banner.nft.url) || 'https://oceannft.oss-cn-hongkong.aliyuncs.com/uploads/20230510/6276403ca34360ba7723273acbef39c5.png' } className="rounded img-fluid" style={imgBox} alt="NFT" />
                            </div>
                            {/* <div className='bottom-desc g-4 justify-content-center row pb-3 '>
                                <div className='col-auto'>
                                    <h6 className='default-text-color mb-2' size="lg">{t('owner')}</h6>
                                    <div className='default-text-color creators creator-primary d-flex flex-column align-items-center'>
                                        <div className='position-relative'>
                                            <img src={(banner && banner.selluser && banner.selluser.avatar)} className="avatar avatar-md-sm shadow-md rounded-pill" width={34} height={34} alt="" />
                                        </div>
                                        <div className='position-relative'>
                                             
                                        </div>
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    <h6 className='default-text-color mb-2'>{t('oceanD')}</h6>
                                    <div className='default-text-color  fw-semi-bold text-dark'>
                                        <span className="fs-5 default-text-color">{(banner && banner.nft && banner.nft.name)}</span>
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    <h6 className='default-text-color mb-2'>{t('auctionEnds')}</h6>
                                    <div className='default-text-color  fw-semi-bold text-dark'>
                                   
                                        {(parseInt(minutes) > 0) ? (<span className="fs-5 default-text-color">{(days && days > 0 ? days : 0) || 0}:{(hours && hours > 0 ? hours : 0) || 0}:{(minutes && minutes > 0 ? minutes : 0) || 0}:{(seconds && seconds > 0 ? seconds : 0) || 0}</span>) : <span className="fs-5 default-text-color">已超时</span>} 
                                    </div>
                                </div>
                            </div> */}
                        </Col>

                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

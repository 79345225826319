import React, { useState, useEffect, useRef } from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { Button, Container, Form } from 'react-bootstrap'
import LinearBox from '../common/LinearBox'
import CategoryTitle from '../common/CategoryTitle'
import ContentTitle from '../common/ContentTitle'
import Input from 'antd/es/input/Input'
import { startInitPledge } from '../../util/startInitPledge'
import { useDispatch, useSelector } from 'react-redux'
import { message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../common/Loading'
import Web3 from 'web3'
import icon from '../../images/icon.png'
import logo from '../../images/logooc.png'

export default function Pledge() {

    const dispatch = useDispatch();

   

    const { t, i18n } = useTranslation();

    const cRef = useRef(null);

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [loadingType, setLoadingType] = useState('');
    const [spinStatus, setSpinStatus] = useState(false);
    
    

    const [tips, setTips] = useState('');

    // lp余额
    const [lpBalance, setLPBalance] = useState(0);
    // lp已质押
    const [lpAmount, setLPAmount] = useState(0);
    // 全网质押
    const [lpTotalAmount, setLPTotalAmount] = useState(0);
    // 可提取收益
    const [reward, setReward] = useState(0);
    // 累计收益
    const [rewardTotal, setRewardTotal] = useState(0);
    // 全网分红
    const [rewardAll, setRewardAll] = useState(0);

    // 领取收益输入框
    const [rewardInput, setRewardInput] = useState(0);
    const changeReward = (event) => {
        setRewardInput(event.target.value);
    }
    const changeRewardAll = (event) => {
        setRewardInput(getIncome2(reward));
    }

    // 质押数量输入框
    const [quentity, setQuentity] = useState(0);
    const changeQuantity = (event) => {
        setQuentity(event.target.value);
    }
    const changeQuantityAll = (event) => {
        setQuentity(getIncome(lpBalance));
    }

    // 解押数量输入框
    const [number, setNumber] = useState(0);
    const changeNumber = (event) => {
        setNumber(event.target.value);
    }
    const changeNumberAll = (event) => {
        setNumber(getIncome(lpAmount));
    }
    
    

    // 解押
    const startRedeem = async () => {
        if (spinStatus) {
            return;
        }
        if (!lpAmount) {
            messageApi.error(t('new20'));
            return;
        }
        if (!number) {
            messageApi.error(t('tips4'));
            return;
        }
        

        const { StakeContract, account } = window.pledge;
        setSpinStatus(true);
        setTips(t('tips5'));
        setLoading(true);
        setLoadingType('redeem');

        let repedgeNumber =  Web3.utils.toWei(String(number));
        StakeContract.methods.relieve(repedgeNumber).estimateGas({ from: account }).then((res) => {
            estimateGasRedeemSuccess();
        }).catch((error) => {
            console.log(error)
            messageApi.error(t('insufficientGas'));
            setSpinStatus(false);
            setLoading(false);
        });
    }
    const estimateGasRedeemSuccess = () => {
        const { StakeContract, account } = window.pledge;
        let repedgeNumber =  Web3.utils.toWei(String(number));
        StakeContract.methods.relieve(repedgeNumber).send({ from: account }).then((res) => {
            initialization();
            setSpinStatus(false);
            setLoading(false);
            messageApi.info(t('tips6'));
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
        });
    }

    // 领取收益
    const startDraw = async () => {
        if (!reward) {
            messageApi.error(t('new18'));
            return;
        }
        if(!rewardInput) {
            messageApi.error(t('new21'));
            return;
        }
        if (spinStatus) {
            return;
        }
        const { StakeContract, account } = window.pledge;
        setSpinStatus(true);
        setLoading(true);
        setLoadingType('draw');
        setTips(t('tips14'));
        let inputBno = Web3.utils.toWei(String(rewardInput));
        StakeContract.methods.claim(inputBno).estimateGas({ from: account }).then((res) => {
            estimateGasDrawSuccess();
        }).catch((error) => {
            messageApi.error(t('insufficientGas'));
            setLoading(false);
            setSpinStatus(false);
        });
    }

    const estimateGasDrawSuccess = () => {
        const { StakeContract, account } = window.pledge;
        let inputBno = Web3.utils.toWei(String(rewardInput));
        StakeContract.methods.claim(inputBno).send({ from: account }).then((res) => {
            console.log(res);
            initialization();
            setSpinStatus(false);
            messageApi.info(t('tips8'));
            setLoading(false);
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
            setSpinStatus(false);
        });
    }

    // 质押
    const startPledge = async () => {
        
        if (spinStatus) {
            return;
        }
        if(!quentity) {
            messageApi.error(t('tips9'));
            return;
        }
        if (!lpBalance) {
            messageApi.error(t('new19'));
            return;
        }
        const { StakeContract, lpContract, account, stakeAddress } = window.pledge;

        setSpinStatus(true);
        setLoadingType('pledge');
        setLoading(true);
        setTips(t('tips10'));

        let allowanceAccount = await lpContract.methods.allowance(account, stakeAddress).call();
        console.log('授权数量：',allowanceAccount);
        let inputBno = Web3.utils.toWei(String(quentity));
        //console.log('授权数量：',allowanceAccount , '质押数量：',inputBno);
		if (parseInt(allowanceAccount) >= parseInt(inputBno)) {
            //console.log('授权数量：',allowanceAccount , '质押数量：',inputBno);
            StakeContract.methods.deposit(inputBno).estimateGas({ from: account }).then((res) => {
                estimateGasSuccess();
            }).catch((error) => {
                messageApi.error(t('insufficientGas'));
                setLoading(false);
                setSpinStatus(false);
            });
        }else{ 
            lpContract.methods.approve(stakeAddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account }).then((res) => {

                StakeContract.methods.deposit(inputBno).estimateGas({ from: account }).then((res) => {
                    estimateGasSuccess();
                }).catch((error) => {
                    messageApi.error(t('insufficientGas'));
                    setLoading(false);
                    setSpinStatus(false);
                });  
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                setLoading(false);
            }); 
        }  
    }

    const estimateGasSuccess = async () => {
        let inputBno = Web3.utils.toWei(String(quentity));
        const { StakeContract, lpContract, account, stakeAddress } = window.pledge;


        StakeContract.methods.deposit(inputBno).send({ from: account }).then((res) => {
            console.log(res);
            initialization();
            setSpinStatus(false);
            setLoading(false);
            messageApi.info(t('tips11'));
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
        });

    }

    const getIncome = (pendingFit) => { 
        if(pendingFit){
            var res = Web3.utils.fromWei(pendingFit) * 1;
            // return parseFloat(res.toFixed(2));
            return parseInt(res * 100) / 100
        }else{
            return 0;
        }
        
    }
    const getIncome2 = (pendingFit) => { 
        if(pendingFit){
            var res = Web3.utils.fromWei(String(pendingFit)) * 1;
            return parseInt(res * 10000) / 10000
        }else{
            return 0;
        }
        
    }
    const createCreateForm = () => {
        return (<div className='text-white  m-2  py-4'>
            
            

            <CategoryTitle title={t('new1')} ></CategoryTitle>
            <Container>
            <div className='row g-3 item-wrap  my-4'>
                <div className='px-2 col-sm-6 col-md-6 col-lg-4'>
                    <div className='pl_card'>
                        <div className='card_label'>{t('new2')}</div>
                        <div className='card_num'>{getIncome(lpTotalAmount)} LP</div>
                    </div>
                </div>
                <div className='px-2 col-sm-6 col-md-6 col-lg-4'>
                    <div className='pl_card'>
                        <div className='card_label'>{t('new4')}</div>
                        <div className='card_num'>{getIncome2(rewardAll)}</div>
                    </div>
                </div>
                <div className='px-2 col-sm-6 col-md-6 col-lg-4'>
                    <div className='pl_card'>
                        <div className='card_label'>{t('new3')}</div>
                        <div className='card_num'>{getIncome(lpAmount)} LP</div>
                    </div>
                </div>
            </div>
            <div className='income_card'>
                <div className='card_title'>
                    {t('new5')}<img src={icon} className='card_icon'></img>
                </div>
                <div className='row g-3 item-wrap  my-2'>
                    <div className='px-2 col-sm-12 col-md-8 col-lg-8'>
                        <img src={logo} className='ocean_logo'></img>
                        <div style={{display:'inline-block'}}>
                            <div className='input_label'>{t('new10')}<span style={{color:'#00BBF9'}}>{getIncome2(reward)} BNO</span></div>
                            <div className='input_box'>
                                <Input placeholder='0.00' min='0' type="number" className='' value={rewardInput} onChange={changeReward}></Input>
                                <div className='all' onClick={changeRewardAll}>{t('all')}</div>
                            </div>
                        </div>
                    </div>
                    <div className='px-2 col-sm-12 col-md-4 col-lg-4'>
                        <Button className='but ton-offer  button-common  py-2   me-3 my-4 fs-6 fw-bold  hvr-shutter-in-horizontal overflow-hidden  rounded-4' disabled={loading} onClick={startDraw}  >
                        {loading && loadingType == 'draw' ? <Loading></Loading> : t('new9')}
                    </Button>
                    </div>
                </div>
                <div className='row g-3 item-wrap'>
                    <div className='px-2 col-sm-12 col-md-6 col-lg-6'>
                        <div className='income_card2'>
                            <div className='card_label'>{t('new7')}</div>
                            <div className='card_num'>{getIncome2(rewardTotal - reward)} BNO</div>
                            {/* <div className='card_num'>{(rewardTotal - reward)} BNO</div> */}
                        </div>
                    </div>
                    <div className='px-2 col-sm-12 col-md-6 col-lg-6'>
                        <div className='income_card2'>
                            <div className='card_label'>{t('new8')}</div>
                            <div className='card_num'>{getIncome2(rewardTotal)} BNO</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='income_card stake_card my-4'>
                <div className='card_title'>
                    {t('new6')}<img src={icon} className='card_icon'></img>
                </div>
                <div className='row g-3 item-wrap  my-2'>
                    <div className='px-2 col-sm-12 col-md-8 col-lg-8'>
                        <img src={logo} className='ocean_logo'></img>
                        <div style={{display:'inline-block'}}>
                            <div className='input_label'>{t('new13')}<span style={{color:'#00BBF9'}}>{getIncome(lpBalance)} LP</span></div>
                            <div className='input_box'>
                                <Input placeholder='0.00' min='0' type="number" value={quentity} onChange={changeQuantity}></Input>
                                <div className='all' onClick={changeQuantityAll}>{t('all')}</div>
                            </div>
                        </div>
                    </div>
                    <div className='px-2 col-sm-12 col-md-4 col-lg-4'>
                        <Button className='but ton-offer  button-common  py-2   me-3 my-4 fs-6 fw-bold  hvr-shutter-in-horizontal overflow-hidden  rounded-4' disabled={loading} onClick={startPledge}  >
                        {loading && loadingType == 'pledge' ? <Loading></Loading> : t('new16')}
                    </Button>
                        
                    <a target="_blank" href="https://pancakeswap.finance/v2/add/0x55d398326f99059fF775485246999027B3197955/0xc10c96b80DE6C1C4f13F7A3084222b83A33384df">
                        <Button className='but ton-offer  button-common  py-2   me-3 my-4 fs-6 fw-bold  hvr-shutter-in-horizontal overflow-hidden  rounded-4' disabled={loading}  >
                        {loading && loadingType == 'pledgebno' ? <Loading></Loading> : t('new15')}
                    </Button>
                    </a> 
                    </div>
                </div>
                <div className='row g-3 item-wrap  my-2'>
                    <div className='px-2 col-sm-12 col-md-8 col-lg-8'>
                        <img src={logo} className='ocean_logo'></img>
                        <div style={{display:'inline-block'}}>
                            <div className='input_label'>{t('new14')}<span style={{color:'#00BBF9'}}>{getIncome(lpAmount)} LP</span></div>
                            <div className='input_box'>
                                <Input placeholder='0.00' min='0' type="number" className='' value={number} onChange={changeNumber}></Input>
                                <div className='all' onClick={changeNumberAll}>{t('all')}</div>
                            </div>
                        </div>
                    </div>
                    <div className='px-2 col-sm-12 col-md-4 col-lg-4'>
                        <Button className='but ton-offer  button-common  py-2   me-3 my-4 fs-6 fw-bold  hvr-shutter-in-horizontal overflow-hidden  rounded-4' disabled={loading} onClick={startRedeem}  >
                        {loading && loadingType == 'redeem' ? <Loading></Loading> : t('new17')}
                    </Button>
                    </div>
                </div>
                <div className='tips1'>{t('new11')}</div>
                <div className='tips2'>&nbsp;&nbsp;&nbsp;&nbsp;{t('new12')}</div>
            </div>
            </Container>
            
        </div>);
    }

    
    async function initialization() {
        try {
            var web = await startInitPledge(); 
            let {web3, StakeContract, lpContract, account,stakeAddress,
            lpAddress} = web
            console.log(StakeContract, lpContract, account)
            let rewardAll = await StakeContract.methods.getRewardTotal().call()
            console.log(rewardAll)
            setRewardAll(rewardAll)

            let userInfo = await StakeContract.methods.getUserInfo(account).call()
            console.log(userInfo)
            setLPAmount(userInfo.amount)
            setReward(userInfo.balance)
            setRewardTotal(userInfo.rewardTotal)

            let balance = await lpContract.methods.balanceOf(account).call()
            console.log("lp 余额",balance)
            setLPBalance(balance)
            let totalBalance = await lpContract.methods.balanceOf(stakeAddress).call()
            console.log("质押合约余额",totalBalance)
            setLPTotalAmount(totalBalance)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        initialization();
        const timerId = setInterval(() => {
		initialization()
		    }, 5000);
    }, [])

    return (
        <div className='bgLinearColor'>
            <Header></Header>
            {contextHolder}
            {spinStatus ? (<Spin tip={tips} size="large">
                <div className="content" />
            </Spin>) : null} 
            <Container>
                <LinearBox item={createCreateForm()}></LinearBox>
            </Container>
            <Footer></Footer>
        </div>
    )
}

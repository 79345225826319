import React from 'react'
import { Col, Row } from 'react-bootstrap';

export default function LinearBorderBox(props) {
    const { item } = props;
    return (
        <Row className='d-flex justify-content-center py-4 px-3'>
            <Col className='box-border my-4 ' xs={12} lg={10} md={12} sm={12}> 
                {item}
            </Col>
        </Row> 
    )
}

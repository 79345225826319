import { configureStore } from "@reduxjs/toolkit"; 
import balanceSlice from "./slices/balanceSlice";
import bnoSlice from "./slices/bnoSlice";
import configSlice from "./slices/configSlice";
import homeSlice from "./slices/homeSlice";
import mintSlice from "./slices/mintSlice";
import orderSlice from "./slices/orderSlice";
import userSlice from "./slices/userSlice";
 
const store = configureStore({
    reducer:{
        balance:balanceSlice,
        order:orderSlice,
        mint:mintSlice,
        home:homeSlice,
        config:configSlice,
        user:userSlice,
        bno:bnoSlice
    },
    middleware:getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck:false
    })
}); 
export default store;
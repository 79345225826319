import { message, Tabs, Spin } from 'antd'
import React, { useState } from 'react'
import { Col, Container, Row, Button, Table } from 'react-bootstrap'
import VerticallyOffer from '../common/VerticallyOffer';
import { useCountdown } from '../../util/useCountdown';
import { useSelector } from 'react-redux';
import { beautySub, convertTimestamp, fromWei, NFTAddress, NFTExchangeAddress, toWei, BNBAddress } from '../../util/util';
import Web3 from 'web3';
 
import Loading from '../common/Loading';
import { useTranslation } from 'react-i18next';


export default function NFTInfo(props) {

    const info = useSelector(state => state.home.NFTInfo);
    const order = useSelector(state => state.home.NFTOrder);
    const NFTSymbol = useSelector(state => state.home.NFTSymbol);
    const { t } = useTranslation();

    const [days, hours, minutes, seconds] = useCountdown(convertTimestamp(order ? order.expiresAt : ''));

    const [modalShow, setModalShow] = useState(false);
    const [spinStatus, setSpinStatus] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingType, setLoadingType] = useState('');

    const [messageApi, contextHolder] = message.useMessage();
    const onChange = (res) => {
        console.log(res);
    }

    const DetailItem = () => {
        return (<div>
            <div className='fs-6  text-white' style={{ minHeight: 80 }}>
                {order && order.nft && order.nft.description}
            </div>
            {/* <div className='fs-6 text-white'>Characteristics</div>
            <div className='fs-6 font-weight-normal default-text-color my-3'>empty</div> */}
        </div>);
    }

    const getShortAddress = (value) => {
        return value.substring(0, 5)+"****"+value.substr(value.length-4);
    }
    const BlockChainItem = () => {
        return (<div className='d-flex  flex-column' style={{ minHeight: 80 }}>
            {order && order.bidcreated && order.bidcreated.map(function (item, key) {
                return blockItem(item);
            })}
        </div>);
    }

    const blockItem = (item) => {

        return (<div className='d-flex justify-content-start align-items-center mb-1'  key={item.bidder} >
                    <div>
                        <img src={item.avatar || '/assets/avatar.png'} className='rounded-circle' width="40" height={40}></img>
                    </div>
                    <div className='ms-1'>
                        <div className='default-text-color '>{item.price && fromWei(item.price)} {NFTSymbol.name} by {item.bidder && getShortAddress(item.bidder)}</div>
                        <div className='default-text-color'>{convertTimestamp(item.biddingAt)}</div>
                    </div>
                </div>);
    }

    const InfoItem = () => {
        return (<div id="table-nft">
            <Table borderless hover responsive className='default-text-color table-striped'>
                <thead>
                    <tr>
                        <th>Contract</th>
                        <th>{info && info.nftAddress && getShortAddress(info.nftAddress)}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Chain ID</td>
                        <td>{info && info.network && info.network.chainid}</td>

                    </tr>
                    <tr>
                        <td>Token Standard</td>
                        <td>{info && info.network && info.network.token}</td>
                    </tr>
                    <tr>
                        <td>Blockchain Network</td>
                        <td>{info && info.network && info.network.name}</td>
                    </tr>
                </tbody>
            </Table></div>);
    }

    const items = [
        {
            key: '1',
            label: `${t('nft1')}`,
            children: DetailItem()
        },
        {
            key: '2',
            label: `${t('nft2')}`,
            children: BlockChainItem()
        },
        {
            key: '3',
            label: `${t('nft3')}`,
            children: InfoItem()
        },
    ];
   

    const directBuy = async () => {
        const {
            NFTTokenContract,
            account
        } = window.web;
         
        if (order.seller.toUpperCase() === account.toUpperCase()) {
            messageApi.info(t('nft21'));
            return;
        }
       

        setLoading(true);
        setLoadingType('buy');
        //判断如果是BNB支付就走BNB流程
        if(order.acceptedToken == BNBAddress){
            startEstimateBNBBuy();
            return;
        }
         
        let approveNum = await NFTTokenContract.methods.allowance(account, NFTExchangeAddress).call(); 
         
        if (approveNum >= order.finalprice) {
            startEstimateBuy();
        } else {
            NFTTokenContract.methods.approve(NFTExchangeAddress, order.finalprice).estimateGas({ from: account })
                .then((res) => {
                    estimateApproveGas();

                }).catch((error) => {
                    setLoading(false);
                    console.log('gas不足');
                });
        }
        // console.log('查询授权数量',web3.utils.fromWei(nftres));
    }

    

    const estimateApproveGas = async () => {
        const {
            NFTTokenContract,
            account
        } = window.web;
        NFTTokenContract.methods.approve(NFTExchangeAddress, 100000000).send({ from: account })
            .then((res) => {
                startEstimateBuy();
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                setLoading(false);
            }); 
    }




    
    const startEstimateBNBBuy = async () => {
        const {
            NFTExchangeContract,
            account
        } = window.web;
        NFTExchangeContract.methods.safeExecuteOrder(NFTAddress, props.assetId).estimateGas({ from: account,value:order.finalprice }).then((res) => {
            startBNBBuy();
        }).catch((error) => {
            setLoading(false);
            console.log('gas不足');
        });
    }

    const startBNBBuy = async () => {
        const {
            NFTExchangeContract,
            account
        } = window.web;
        NFTExchangeContract.methods.safeExecuteOrder(NFTAddress, props.assetId).send({ from: account,value:order.finalprice}).then((res) => { 
            setLoading(false);
        }).catch((error) => { 
            setLoading(false);
        });
    }



    const startEstimateBuy = async () => {
        const {
            NFTExchangeContract,
            account
        } = window.web;
        NFTExchangeContract.methods.safeExecuteOrder(NFTAddress, props.assetId).estimateGas({ from: account }).then((res) => {
            startBuy();
        }).catch((error) => {
            setLoading(false);
            console.log('gas不足');
        });
    }
    const startBuy = async () => {
        const {
            NFTExchangeContract,
            account
        } = window.web;
        NFTExchangeContract.methods.safeExecuteOrder(NFTAddress, props.assetId).send({ from: account }).then((res) => {
        
            setLoading(false);
        }).catch((error) => {
        
            setLoading(false);
        });
    }

    //开始敲锤子
    const startKnock = async () => {
        const {
            NFTExchangeContract, 
            account
        } = window.web;
        setLoading(true);
        setLoadingType('knock');  

        NFTExchangeContract.methods.acceptBid(NFTAddress, props.assetId).estimateGas({ from: account,value:0 }).then((res) => {
            knockEstimateGas();
        }).catch((error) => {
            setLoading(false);
            setLoadingType('');
        }); 
    }
    const knockEstimateGas = async () => {
        const {
            NFTExchangeContract, 
            account 
        } = window.web;
        
        NFTExchangeContract.methods.acceptBid(NFTAddress, props.assetId).send({ from: account,value:0 }).then((res) => {
            setModalShow(false);
            messageApi.info(t('nft26'));
            setLoading(false);
            setLoadingType('');
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
            setLoadingType('');
        });
    }



    const offerPrice = async () => {
        const { 
            account
        } = window.web;
         
        if (order.seller.toUpperCase() == account.toUpperCase()) {
            messageApi.info(t('nft21'));
            return;
        }
        setModalShow(true);
    }


    const startOffer = async (price) => { 
        const {
            NFTExchangeContract,
            NFTTokenContract,
            account
        } = window.web;
        
        
        if(price == 0 ){
            messageApi.info(t('nft23'));
            return;
        } 
     
        if( Web3.utils.toWei(price) - order.finalprice < 0){
            messageApi.info(t('nft22'));
            return;
        }
     
        setLoading(true);
        setLoadingType('nftoffer'); 


        if(order.acceptedToken == BNBAddress){
            offerEstimateBNBGas(price);
            return;
        }


        let approveNum = await NFTTokenContract.methods.allowance(account, NFTExchangeAddress).call();
        console.log('授权： ',approveNum,Web3.utils.toWei(price));
        if(Web3.utils.toWei(price) > approveNum ){ 
            NFTTokenContract.methods.approve(NFTExchangeAddress, order.finalprice).estimateGas({ from: account })
                .then((res) => {
                    estimateApproveGasOffer(price); 
                }).catch((error) => {
                    setLoading(false);
                    console.log('gas不足');
                });
            return;
        }else{
            NFTExchangeContract.methods.safePlaceBid(NFTAddress, props.assetId, Web3.utils.toWei(price))
            .estimateGas({ from: account })
            .then((res) => {
                offerEstimateGas(price);
            }).catch((error) => {
                setLoading(false);
                setLoadingType('');
            });
        } 
       
    }
    const estimateApproveGasOffer = async (price) => {
        const {
            NFTTokenContract,
            account
        } = window.web;
        NFTTokenContract.methods.approve(NFTExchangeAddress, 1000000000000).send({ from: account })
            .then((res) => {
                startEstimateOffer(price);
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                setLoading(false);
            }); 
    }
    const startEstimateOffer = async (price) => {
        const {
            NFTExchangeContract,
            account
        } = window.web;
        NFTExchangeContract.methods.safePlaceBid(NFTAddress, props.assetId, Web3.utils.toWei(price))
            .estimateGas({ from: account })
            .then((res) => {
                offerEstimateGas(price);
            }).catch((error) => {
                setLoading(false);
                setLoadingType('');
            });
    }
    

    const offerEstimateBNBGas = async (price) => {
        const {
            NFTExchangeContract, 
            account 
        } = window.web; 
        NFTExchangeContract.methods.safePlaceBid(NFTAddress, props.assetId, Web3.utils.toWei(price)).send({ from: account,value:Web3.utils.toWei(price) }).then((res) => {
            setModalShow(false);
            messageApi.info(t('nft25'));
            setLoading(false);
            setLoadingType('');
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
            setLoadingType('');
        });
    }



    
    const offerEstimateGas = async (price) => {
        const {
            NFTExchangeContract, 
            account 
        } = window.web;
        
        NFTExchangeContract.methods.safePlaceBid(NFTAddress, props.assetId, Web3.utils.toWei(price)).send({ from: account }).then((res) => {
            setModalShow(false);
            messageApi.info(t('nft25'));
            setLoading(false);
            setLoadingType('');
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
            setLoadingType('');
        });
    }

    const handleClose = async () => {
        setModalShow(false);
    }

    const boxStyle = {
        boxShadow: 'rgb(136, 136, 136) 0px 0px 50px 10px inset',
        borderRadius: 15, 
    }

    const knockButton = () => {
        return (<Col xs={12} lg={6} md={6} sm={12} className="text-center mb-2">
                    <Button className='hvr-pulse-grow  button-offer min-button-offer  w-75 fs-6 mx-auto' disabled={loading}  onClick={startKnock}   size="md">
                    {loading && loadingType == 'knock' ? <Loading></Loading> : t('nft24')}   
                    </Button>
                </Col>);
    }

    const createButtonByStatus = () => {
        
        if(seconds > 0){
            return createOfferButton();
        }else{
            return knockButton();
        }
    }

    const createOfferButton = () => {
        return (<Col xs={12} lg={6} md={6} sm={12} className="text-center mb-2">
                    <Button className='hvr-pulse-grow  button-offer min-button-offer  w-75 fs-6 mx-auto' disabled={loading || (seconds < 0)}  onClick={offerPrice} size="md">
                    {loading && loadingType == 'offer' ? <Loading></Loading> : (seconds > 0 ? t('nft8') : t('nft19'))}  
                    </Button>
                </Col>);
    }

    return (
        <Container className='pb-4' >
            <Row className='flex-row my-4' >
                <Col xs={12} lg={5} md={5} sm={12} className="d-flex flex-row justify-content-center align-items-center">
                    <img src={info && info.url} className='img-fluid mx-auto rounded-2 w-100'></img>
                </Col>
                <Col xs={12} lg={6} md={6} sm={12} >
                    <div className="py-3">
                        <div className='default-text-blur fs-5 mt-2'> {info && info.group && info.group.name}</div>
                        <div className='default-text-color fs-4 my-2'> {(info && info.name) || ''}{' '}{'#' + (info && info.assetId)}</div>
                        <div className='default-text-blur fs-6 mt-2'>{t('nft4')}: 
                            <a className='default-text-blur fs-6 '   href={"/userInfo?assetId="+ order.seller}> 
                                {order && order.seller && getShortAddress(order && order.seller)}
                            </a>
                        </div>
                    </div>

                    {order ? (<div className='p-4' style={boxStyle}>
                        <Row>
                            <Col xs={12} lg={6} md={12} sm={12}>
                                <div className='text-white fs-5'>{order && order.method == '1' ? t('nft15') : t('nft18')}</div>
                            </Col>
                            <Col xs={12} lg={6} md={12} sm={12}>
                                <div className='text-white fs-5'>{order && order.finalprice && Web3.utils.fromWei(order.finalprice)} BNB</div>
                            </Col>
                        </Row>

                        {order && order.method == '1' ? (<Row className='my-2'>
                            <Col xs={12} lg={6} md={12} sm={12}>
                                <div className='text-white fs-5'>{order && order.method == '1' ? t('nft16') : t('expirationTime')}</div>
                            </Col>
                            <Col xs={12} lg={6} md={12} sm={12}>
                                {seconds < 0 ? (<div className='text-white  fs-5 d-flex align-items-center'>{t('nft19')}</div>) : (<div className='text-white  fs-2 d-flex align-items-center'>
                                    <h4 className='me-2'><span className="badge bg-white text-black fs-6 fw-normal">{(isNaN(days) ? 0 : days)+' '}D</span></h4>
                                    <h4 className='me-2'><span className="badge bg-white text-black fs-6 fw-normal">{(isNaN(hours) ? 0 : hours)}H</span></h4>
                                    <h4 className='me-2'><span className="badge bg-white text-black fs-6 fw-normal">{(isNaN(minutes) ? 0 : minutes)}M</span></h4>
                                    <h4 className='me-2'><span className="badge bg-white text-black fs-6 fw-normal">{(isNaN(seconds) ? 0 : seconds)}S</span></h4>
                                </div>)}
                                
                            </Col>
                        </Row>) : <div style={{height:'30px'}}></div>}
                        
                        <Row className='mb-2 text-center d-flex  justify-content-center pt-2'>
                            {order && order.method == '1' ? createButtonByStatus() : (<Col xs={12} lg={6} md={4} sm={12} className="text-center mb-2">
                                <Button className="buy-now hvr-pulse-grow min-button-offer   fs-6 mx-auto" disabled={loading} onClick={directBuy} size="md"> 
                                    {loading && loadingType == 'buy' ? <Loading></Loading> : t('nft7')} 
                                </Button>
                            </Col>)} 
                        </Row>
                    </div>) : <div className='p-4' style={boxStyle}>
                        <Row>
                            <Col xs={12} lg={6} md={12} sm={12}>
                                <div className='text-white fs-5'>{t('nft29')}</div>
                            </Col> 
                        </Row> 
                    </div>}
                    <Row >
                        <Col xs={12} lg={12} md={12} sm={12} style={{height:235,overflowY:'scroll'}} >
                            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12} lg={4} md={6} sm={12}>
                            <div className='fs-6  mb-2  text-white font-weight-bold'>{t('nft4')}</div>
                            <div className='d-flex align-items-center'>
                                <div ><img src={(info && info.owneruser && info.owneruser.avatar) || '/assets/avatar.png'} className="rounded-circle" width={40} height={40}></img></div>
                                <div className='px-2 text-white py-2  fs-6'>{info && info.owneruser && getShortAddress(info.owneruser.nickname)}</div>
                            </div>
                        </Col>
                        <Col xs={12} lg={4} md={6} sm={12}>
                            <div className='fs-6  mb-2 text-white font-weight-bold'>{t('nft5')}</div>
                            <div className=' category-style py-2 w-60'>
                                <div className='d-flex rounded-4 category-style-inline  px-2 py-1 justify-content-start align-items-center  border-white  '>
                                    {/* <div><img src="/assets/icon1.png" width={33} height={21} ></img></div> */}
                                    <div className='fs-6 text-white px-2'>{info && info.attributes}</div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={4} md={6} sm={12}>
                            <div className='fs-6 mb-2 text-white font-weight-bold'>{t('nft6')}</div>
                            <div className='fs-6 text-white py-2 '>{info && info.network && info.network.name}</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {contextHolder}
            <VerticallyOffer
                show={modalShow}
                loading={loading}
                loadingType={loadingType}
                oncallback={startOffer}
                handleClose={handleClose}
            >
            </VerticallyOffer>
        </Container>
    )
}

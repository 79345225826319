import i18n from "i18next";
import { initReactI18next } from "react-i18next";



// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: { 
      "max": 'Max',
      "stakelp": 'Stake LP tokens',
      "stakelpp": 'Unstake LP tokens',
      "stakelp1": 'Stake',
      "stakelp11": 'Unstake',
      "stakelp2": 'Balance: ',
      "stakelp3": 'Cancel',
      "stakelp4": 'Confirm',
      "stakelp5": 'Confirming',
      "createNFT": "Create Your NFT",
      "pledge": "Pledge",
      "search": "Search",
      "mainTitle": "Mint,Buy, and Sell Your NFT",
      "subtitle": "NFTBOX is the biggest Blockchan based NFT markeplace",
      "mint": "Mint",
      "searchBtn": "Search",
      "connectBtn": "Connect",
      "connectBtnWallet": "Connect wallet", 
      "latestnNft": "Latest NFTs",
      "topCollection": "Top Collections",
      "topSellers": "Top Sellers",
      "allNfts": "ALL NFTs",
      "art": "Art",
      "joinUs": "Join Communtiy",
      "searchHolder": "Search  Your NFT...",
      "owner": "Owner",
      "oceanD": "Search  Your NFT...",
      "auctionEnds": "Auction Ends",
      "expirationTime": "Expiration time",
      "sub1": "My NFTs",
      "sub2": "Profile",
      "sub3": "Favourites",
      "sub4": "Log Out",  
      "earnbno": "Earn BNO",
      "pledgebno": "Pledge BNO", 
      "pledgebnoBalance": "BNO balance", 
      "totalamount": "Total Mining Amount", 
      "pledgequantity": "Pledge quantity...",
      "toatlocked": "Total locked positions", 
      "viewtoken": "View token information", 
      "viewcontract": "View contract",
      "incomde": "Income", 
      "inputquantity": "Input  quantity...", 
	  "pledge": "Pledge", 
      "draw": "Draw",
      "redeem": "Redeem", 
      "redeemNFT": "Redeem NFT", 
      "NFTpledge": "NFT in pledge", 
      "earnings": "Earnings",
      "addnft": "ADD NFT",   
      "pledgebnoRatio": "Income ratio",
      "pledgenft": "Pledge NFT", 


      "socialLinks": "Social Links",  
      "socialLinksUseFul": "Help collectors verify your account by connecting to social accounts",  
      "socialLinkscontact": "connect",  

      "userInfoImg": "Profile Image",  
      "userInfoBanner": "Profile Banner",  
        

      "create1": "Create New NFT", 
      "create2": "NFT Title",   
      "create3": "NFT Title...", 
      "create4": "NFT Description", 
      "create5": "NFT Description...", 
      "create6": "NFT Series",   
      "create7": "NFT Tag",  
      "create8": "Blockchain Network", 
      "create9": "Add Image",   
      "create10": "Support: JPG,PNG,GIF,Max size:10 MB", 
      "create11": "Note:Gas fee required, it will deducted from your wallet", 
      "create12": "Create Your NFT", 


      "editprofile": "Edit Profile",  
      "blockchaininfo": "BlockChain Info", 
      "desc1": "Profile picture recommended(300*300px)", 
      "nickname": "Nickname", 
      "biography": "Biography",   
      "usecollection": "NFT Collection",  
      "weblink": "Personal website link", 
      "emailaddress": "Email Address",   
      "walletAddress": "Wallet Address", 
      "updateProfile": "Update Profile",  
      
      "creatednft": "Created NFT",  
      "ownnft": "Own NFT", 
      "sellnft": "Sell NFT",   
      "favournft": "My Favourite ",  


      "nft1": "Details",  
      "nft2": "Bids", 
      "nft3": "BlockChain Info",   
      "nft4": "Owner", 
      "nft5": "Category", 
      "nft6": "Network ", 
      "nft7": "Buy Now", 
      "nft8": "Make Offer", 
      "nft9": "NFT Activity", 
      "nft10": "Events", 
      "nft11": "Price", 
      "nft12": "From", 
      "nft13": "To", 
      "nft14": "Date", 
      "nft15": "Price", 
      "nft16": "Auction Ends in",
      "nft17": "More NFTs From This Collection",
      "nft18": "Price", 
      "nft19": "Ended", 
      "nft20": "The released NFT has been removed from the shelves", 
      "nft21": "Unable to purchase NFTs published by oneself", 
      "nft22": "The bid cannot be lower than the latest price", 
      "nft23": "Please enter the price", 
      "nft24": "Deal", 
      "nft25": "Successful bidding", 
      "nft26": "The order has been closed", 
      "nft27": "Current computing power", 
      "nft28": "NFT addition", 
      "nft29": "Order completed", 

      "userDenied": "User denied transaction signature",
      "insufficientGas": "Insufficient Gas",
      "totalminingpool": "Total mining pool", 


      "installMetamask": "Please connect your metamask. If you don't have a metamask yet.",
      "connect": "Connect",
      "installple": "Please install",


      "expirationTime": "Expiration time",
      "salesType": "Sales type",
      "listing": "Listing",
      "auction": "Auction",
      "principal": "Principal", 
      "pledgedAmount": "Total pledged amount", 
      "countdownToPledge":"Countdown to pledge activation",

      "tips1": "Please select the redeemed collection first",
      "tips2": "Redemption in progress",
      "tips3": "Successfully redeemed NFT",
      "tips4": "Please enter the quantity first",
      "tips5": "Redemption in progress",
      "tips6": "Redemption successful",
      "tips7": "Fully withdrawn", 
      "tips8": "Withdrawal successful",
      "tips9": "Please input the pledged quantity first",
      "tips10": "Pledging in progress",
      "tips11": "Pledge successful",
      "tips1111": "Unstake successful",
      "tips12": "Please select the pledged collection first",
      "tips13": "Successfully pledged NFT",
      "tips14": "Withdrawing", 
      "tips15": "BNO redemption quantity greater than pledged quantity", 
      "tips16": "The withdrawal quantity cannot be greater than the total income",


      "usert1": "Offer",
      "usert2": "Sold Out",
      "usert3": "Canceled",
      "usert4": "Cancle Offer",
      "usert5": "In transaction", 


       "st1": "Total transaction",  
      "st2": "Highest price", 
      "st3": "Lowest price",   
      "st4": "Average price",


      "info1": "Creator", 
      "info2": "Item", 
      "info3": "Creation date", 
      "info4": "Creator Benefits", 
      "info5": "Chain ", 
      "info6": "Category", 


      "usertList1": "Please enter the price first",
      "usertList2": "Please enter the date first",
      "usertList3": "Please select the collection to be sold first",
      "usertList4": "Successfully placed NFT order",
      "usertList5": "Data Not Found", 


      "createTip1": "Please fill in the NFT name",
      "createTip2": "Please fill in the NFT description",
      "createTip3": "Please upload NFT resources", 
      "createTip4": "Successfully cast", 

      "chinese": "Chinese", 
      "english": "English", 
      "home":'Home',
      "language":"Language",

      "all":"All",
      "more":"More",
      "user":"User",
      "selectDate":'Select Date',
      "group1":"Group",
      "group2":"Collection",
      "group3":"Blockchain",
      "day":"D",
      "hours":"H",
      "minutes":"M",
      "seconds":"S",
      
      "blance":"Blance",
	  "power":"Power",
	  "projectName":"Project Name",
	   "earned":"Earned",
	   "PledgeRedemption":"Pledge / Redemption",
     'farms': 'Farms',
     'farms2': 'Stake LP tokens to earn.',
     'farms3': 'Earned',
     'farms4': 'APR',
     'farms5': 'Liquidity',
     'farms6': 'Weight',
     'farms7': 'Get ',
     'farms8': 'View Contract',
     'farms9': 'See Pair Info',
     'farms10': 'Core',
     'farms11': 'START FARMING',
     'farms12': 'Please enter the quantity',
     'farms13': 'Annual ROI',
	 
	 
	 'legTH':'LDG treasure hunt',
    'new1': 'Pledge information',
    'new2': 'Total pledge amount',
     'new3': 'My pledge amount',
     'new4': 'Total dividends',
     'new5': 'Your earnings',
     'new6': 'Your pledge',
     'new7': 'Earned',
     'new8': 'Cumulative income',
     'new9': 'Receive',
     'new10': 'Available to receive: ',
     'new11': 'Note: ',
     'new12': 'You will participate in dividends immediately after staking LP, and dividends will stop when you decompress LP',
     'new13': 'LP that can be staked: ',
     'new14': 'Detachable LP: ',
     'new15': 'Get LP',
     'new16': 'Pledge',
     'new17': 'Unlock',
     'new18': 'Insufficient earnings available',
     'new19': 'Insufficient staking LP',
     'new20': 'Insufficient deductible LP',
     'new21': 'Please enter first',
     'stake': 'Community Dividends',
	 'unitedgaming':'UN Ecology',
	 'okc':'OKC Ecology',
	 'pledgeEndExplain':' We will distribute this pledge section to your wallet on a monthly basis and provide an additional 50% airdrop benefit to contributing communities based on the size of the coins held. If unsure, you can consult the community leader or customer service. Your wallet address:',
    }
  },
  zh: {
    translation: { 
      "max": '最大',
      "stakelp": '质押 LP 代币',
      "stakelpp": '取消质押 LP 代币',
      "stakelp1": '质押',
      "stakelp11": '取消质押',
      "stakelp2": '余额：',
      "stakelp3": '取消',
      "stakelp4": '确认',
      "stakelp5": '正在确认',
      "createNFT": "铸造NFT",
      "pledge": "质押",
      "search": "搜索",
      "mainTitle": "Mint,Buy, and Sell Your NFT",
      "subtitle": "NFTBOX is the biggest Blockchan based NFT markeplace",
      "mint": "铸造",
      "searchBtn": "搜索",
      "connectBtn": "连接",
      "connectBtnWallet": "连接钱包", 
      "latestnNft": "最新NFT",
      "topCollection": "最新藏品",
      "topSellers": "推荐卖家",
      "allNfts": "所有NFT",
      "art": "艺术",
      "joinUs": "加入我们的社区",
      "searchHolder": "搜索你的NFT...",
      "owner": "卖家",
      "oceanD": "ocean-D",
      "auctionEnds": "拍卖结束",
      "expirationTime": "过期时间",
      "sub1": "我的NFT",
      "sub2": "个人中心",
      "sub3": "我的收藏",
      "sub4": "退出", 
      "earnbno": "收益 BNO",
      "pledgebno": "质押 BNO", 
      "pledgebnoBalance": "BNO余额", 
      "totalminingpool": "总矿池",  
      "totalamount": "总开采量", 
      "pledgequantity": "质押数量...",
      "toatlocked": "总锁定位置", 
      "viewtoken": "查看Token信息", 
      "viewcontract": "查看合约",
      "incomde": "收入", 
      "inputquantity": "输入数量...", 
	  "pledge": "质押", 
      "draw": "提取",
      "redeem": "赎回", 
      "principal": "本金",  
      "redeemNFT": "赎回 NFT",   
      "NFTpledge": "质押中的NFT", 
      "earnings": "收益",
      "addnft": "添加NFT",  
      "pledgenft": "质押NFT",    
      "pledgebnoRatio": "收益率",  
      "totalminingpool": "总矿池", 
      "create1": "铸造NFT", 
      "create2": "标题",   
      "create3": "NFT 标题...", 
      "create4": "NFT 描述", 
      "create5": "NFT 描述...", 
      "create6": "NFT 系列",   
      "create7": "NFT 标签",  
      "create8": "区块链网络", 
      "create9": "添加图像",   
      "create10": "支持：JPG，PNG，GIF，最大大小：10 MB", 
      "create11": "注意：需要加油费，它将从您的钱包中扣除", 
      "create12": "铸造NFT", 


      "editprofile": "个人信息",  
      "blockchaininfo": "区块链信息", 
      "desc1": "推荐个人资料图片（300*300px）", 
      "nickname": "昵称", 
      "biography": "介绍",   
      "usecollection": "NFT 系列",  
      "weblink": "个人网站链接", 
      "emailaddress": "电子邮件地址",   
      "walletAddress": "钱包地址", 
      "updateProfile": "更新个人信息",

      "socialLinks": "社交链接",  
      "socialLinksUseFul": "帮助收藏家通过连接社交账户验证您的账户",  
      "socialLinkscontact": "连接",  
      "userInfoImg": "个人资料图片",  
      "userInfoBanner": "个人资料横幅",  
     

      "creatednft": "我创建的",  
      "ownnft": "我拥有的", 
      "sellnft": "我出售的",   
      "favournft": "我收藏的",  


      "st1": "总交易量",  
      "st2": "最高价格", 
      "st3": "最低价格",   
      "st4": "平均价格",  


      "info1": "创作者", 
      "info2": "项目数", 
      "info3": "创建日期", 
      "info4": "创作者收益", 
      "info5": "链", 
      "info6": "类别", 


       


      


      "nft1": "详情",  
      "nft2": "出价", 
      "nft3": "区块链信息",   
      "nft4": "所有权", 
      "nft5": "系列", 
      "nft6": "网络", 
      "nft7": "立即购买", 
      "nft8": "报价", 
      "nft9": "NFT记录", 
      "nft10": "事件", 
      "nft11": "价格", 
      "nft12": "From", 
      "nft13": "To", 
      "nft14": "日期", 
      "nft15": "价格", 
      "nft16": "拍卖结束", 
      "nft17": "此系列的更多NFT",
      "nft18": "一口价", 
      "nft19": "已结束", 
      "nft20": "发布的NFT已下架", 
      "nft21": "不能购买自己发布的NFT", 
      "nft22": "出价不能低于最新价格", 
      "nft23": "请输入价格", 
      "nft24": "成交", 
      "nft25": "出价成功", 
      "nft26": "订单已成交", 
      "nft27": "当前算力", 
      "nft28": "NFT加成", 
      "nft29": "寄售订单已完成", 
      
      
      

      

      "userDenied": "用户拒绝交易签名",
      "insufficientGas": "Gas 不足",
      
      


      "installMetamask": "请连接您的Metamask。如果你还没有Metamask ",
      "connect": "连接",
      "installple": "请安装",

      "expirationTime": "到期时间",
      "salesType": "出售类型",
      "listing": "挂单",
      "auction": "拍卖",
      "pledgedAmount": "总质押量", 
      "countdownToPledge":"质押开启倒计时", 

      "tips1": "请先选择赎回的藏品",
      "tips2": "正在赎回",
      "tips3": "赎回NFT成功",
      "tips4": "请先输入数量",
      "tips5": "正在赎回",
      "tips6": "赎回成功",
      "tips7": "已全部提现",
      "tips8": "提现成功",
      "tips9": "请先输入质押数量",
      "tips10": "正在质押",
      "tips11": "质押成功",
      "tips1111": "取消质押成功",
      "tips12": "请先选择质押的藏品",
      "tips13": "质押NFT成功",
      "tips14": "正在提现", 
      "tips15": "BNO赎回数量大于质押数量", 
      "tips16": "提现数量不能大于总收益",
      "selectDate":'选择时间',

      "usert1": "挂售",
      "usert2": "已售出",
      "usert3": "已取消",
      "usert4": "取消订单",
      "usert5": "交易中", 

      "usertList1": "请先输入价格",
      "usertList2": "请先输入日期",
      "usertList3": "请先选择要出售的藏品",
      "usertList4": "挂售NFT订单成功",
      "usertList5": "暂无数据", 
      

      "createTip1": "请填写NFT名称",
      "createTip2": "请填写NFT描述",
      "createTip3": "请上传NFT资源", 
      "createTip4": "铸造成功", 


      "chinese": "中文", 
      "english": "英文", 
      "home":'首页',
      "language":"语言",

      "all":"全部",
      "more":"更多",
      "user":"我的",


      "group1":"特征",
      "group2":"系列",
      "group3":"区块链网络",
      "day":"天",
      "hours":"小时",
      "minutes":"分",
      "seconds":"秒",
	  
	  
	  "blance":"余额",
	  "power":"算力",
	  "projectName":"项目名称",
	   "earned":"赚取",
	   "PledgeRedemption":"质押 / 赎回",
     'farms': '农场',
     'farms2': '质押 LP 代币以赚取。',
	   'farms3': '已赚取',
     'farms4': '年利率',
     'farms5': '流动性',
     'farms6': '权重',
     'farms7': '获取 ',
     'farms8': '查看合约',
     'farms9': '查看货币对信息',
     'farms10': '核心',
     'farms11': '开始挖矿',
     'farms12': '请输入数量',
     'farms13': '年度投资回报率',
	 
	 'legTH':'LDG探宝',
    'new1': '质押信息',
    'new2': '全网质押总量',
    'new3': '我的质押数量',
    'new4': '全网总分红',
    'new5': '您的收益',
    'new6': '您的质押',
    'new7': '已领取收益个人奖励',
    'new8': '累计收益',
    'new9': '领取',
    'new10': '可领取: ',
    'new11': '注意: ',
    'new12': '您质押LP后就会立即参与分红，解压LP会停止分红',
    'new13': '可质押LP: ',
    'new14': '可解押LP: ',
    'new15': '获取LP',
    'new16': '质押',
    'new17': '解押',
    'new18': '可领取收益不足',
    'new19': '可质押LP不足',
    'new20': '可解押LP不足',
    'new21': '请先输入',
    'stake': '社区分红',
    'unitedgaming':'UN生态',
	'okc':'OKC生态',
	'pledgeEndExplain':'我们将此质押板块，每月发放到你的钱包，并根据持币规模，将额外提供50%的空投福利给有贡献的社区。如果不清楚可以咨询社区长或客服。你的钱包地址：',

    }
  }
};
let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
import Web3 from "web3";
import NFT from '../build/NFT.json';
import PledgeBNONFT from '../build/PledgeBNONFT.json';
import Pledge from '../build/Pledge.json';
import Stake from '../build/Stake.json';
import lpAbi from '../build/listAbi/lpAbi.json';

import { NFTAddress, NFTBNOTokenAddress, NFTPledgeAddress, stakeAddress, lpAddress }  from './util';

 


 

var web3 = new Web3(Web3.givenProvider);

const startInitPledge = async () => {
    if (typeof window.pledge == 'undefined') {
        let account = await web3.eth.requestAccounts();
        // TODO
        var PledgeNFTContract = await new web3.eth.Contract(NFT, NFTAddress);
        var PledgeBNONFTContract = await new web3.eth.Contract(PledgeBNONFT, NFTBNOTokenAddress);
        var PledgeContract = await new web3.eth.Contract(Pledge, NFTPledgeAddress); 
        var StakeContract = await new web3.eth.Contract(Stake, stakeAddress); 
        var lpContract = await new web3.eth.Contract(lpAbi, lpAddress);
        var initPledge = {
            web3,
            // TODO
            PledgeNFTContract,
            PledgeBNONFTContract,
            PledgeContract,
            StakeContract,
            lpContract,
            stakeAddress,
            lpAddress,
            account: account[0]
        }; 
        window.pledge = initPledge;
        return initPledge;
    } else {
        return window.pledge;
    } 
};
export { startInitPledge };


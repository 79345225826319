
import React, { useState } from 'react'
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import Web3 from 'web3';
export default function NFTItem(props) {
  const { is_favor, index, nft } = props;
  const [addTime,setAddTime] = useState(0);
  
  const favorNFT = () => {  
    props.callback(nft,function(){
      setAddTime(1);
    }); 
    
  }

  const boxStyle = {
    //background:"url('/assets/bg-round.png')",
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    boxShadow: 'rgb(136, 136, 136) 0px 0px 50px 10px inset',
  }
  const imgBox = {
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1
  }
  return (
    <div className='col-sm-12 col-md-12 col-lg-12'>
      <div className="top-box-shadow rounded-2">
        <div style={boxStyle} className="rounded-2">
          <div className="nft-items default-text-color overflow-hidden p-3"   >
            <a href={`/NFT?assetId=${nft.assetId}`} className='nft-image overflow-hidden rounded-2  d-flex  '>
              <img src={nft.nft && nft.nft.url} className='img-rounded img-fluid margin w-100' style={imgBox}></img>
            </a>
          </div>
          <div className='card-body content position-relative  p-3 pt-0'>
            <div className='d-flex justify-content-between mb-2 d-flex flex-row align-items-center'>
              <div className="img-group">
                <a className='user-avatar '>
                  <img src={(nft.selluser && nft.selluser.avatar) || "/assets/avatar.png"} className='avatar avatar-md-sm shadow-md rounded-pill'></img>
                </a>
              </div>
              <div className="btn-group px-3  d-flex flex-row justify-content-between align-items-center cursor-pointer" onClick={favorNFT}>
                {true || props.nft.is_favor == 1 ? (  <HeartFilled  style={{ fontSize: '16px', color:'red' }}/>): (<HeartOutlined style={{ fontSize: '16px', color:'#E5E5E5' }} />)}
                <div className='default-text-color'>{nft.nft && (nft.nft.favorite + addTime)}</div>
               
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between  fw-semi-bold h6  title default-text-color">
              <div>{nft.nft && nft.nft.name}</div>
              <div>#{nft.assetId}</div>
               </div>
            <div className="d-flex justify-content-between mt-2">
              <small className="rate fw-semi-bold  default-text-color">{nft.price && Web3.utils.fromWei(nft.price)} {nft.token && nft.token.name}BNB</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

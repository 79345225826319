import React, { useState, useEffect } from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { Button, Container, Form } from 'react-bootstrap'
import LinearBorderBox from '../common/LinearBorderBox'
import CategoryTitle from '../common/CategoryTitle'
import ContentTitle from '../common/ContentTitle'
import Input from 'antd/es/input/Input'
import { Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { domainURL, fetchData } from '../../util/util'
import { useDispatch, useSelector } from 'react-redux'
import { setNftGroup, setNftNetwork } from '../../redux/slices/configSlice'
import { useNavigate } from 'react-router-dom';
import { message, Spin } from 'antd';


import { useTranslation } from 'react-i18next';
import Loading from '../common/Loading'


const className = 'fs-4 default-text-color  px-3 py-1 mt-4 mb-1';

export default function CreateNFT() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const network = useSelector(state => state.config.nftNetwork);
    const group = useSelector(state => state.config.nftGroup);
    const [collection, setCollection] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [messageApi, contextHolder] = message.useMessage();

    const { data, error, isLoading } = useSWR([domainURL + `/api/nft/group`], fetchData);
    if (data) {
        dispatch(setNftGroup(data.data.nft_group)); 
        dispatch(setNftNetwork(data.data.nft_network));
        setTimeout(function(){  
            if(collection.length == 0){
                setCollection(data.data.nft_group[0]['tags'].split(','));
            } 
        },1000);
    }
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
     
    const [selectGroup, setSelectGroup] = useState();
    const [selectCollection, setSelectCollection] = useState('');
    const [selectNetwork, setSelectNetwork] = useState('');
    
    const changeTitle = (event) => {
        setTitle(event.target.value);
    }
    const changeDescription = (event) => {
        setDescription(event.target.value);
    }

    
    
    const handleChange = (event) => {
        if (event.file.response) {
            
            //console.log(event.file.response); 
            setImageUrl(event.file.response.data.url);
        }
    }

    const startMint = async (url) => {
        var mintFee = await window.web.NFTContract.methods.mintFee().call(); 
        window.web.NFTContract.methods.mint(imageUrl).estimateGas({ from: window.web.account, value: mintFee }).then((res) => {
            sendTransfer(url, mintFee);
        }).catch((err) => { 
            messageApi.info(t('insufficientGas'));
            setLoading(false);
            //console.log('铸造结果22：', err);
        })
    }
    const sendTransfer = async (url, mintFee) => {
        window.web.NFTContract.methods.mint(url).send({ from: window.web.account, value: mintFee }).then((res) => {
            setLoading(false);
            messageApi.info(t('createTip4'));
            navigate('/userList');
        }).catch((error) =>{
            if (error.code == 4001) { 
                messageApi.error(t('userDenied'));
            } 
            setLoading(false);
        });
        
    }
 
    const uploadButton = (
        <div>
            {loadings ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );


    useEffect(() => { 
        //console.log(group);
        // // setCollections(tags[0]['tags'].split(','));
       // setSelectCollection(data.data.nft_group[0]['tags'].split(',')[0]);
       
    }, [])



    const changeGroup = (event) => {
        let newValue = event.target.value; 
        setSelectGroup(newValue);
        let tags = group.filter(function (item) {
            if (item.name == newValue) {
                return item.tags;
            }
        }); 
        setCollection(tags[0]['tags'].split(','));
    }
    const changeNetwork = (event) => {
        let newValue = event.target.value;
        setSelectNetwork(newValue);
    }
    const changeCollection = (event) => {
        let newValue = event.target.value;
        setSelectCollection(newValue);
    }

    const createURI = async (formData) => {
        return fetch(domainURL + '/api/nft/create', {
            method: 'post',
            body: formData,
        }).then((res) => res.json())
    }


    const createNFT = () => {
        if(!title){
            messageApi.info(t('createTip1'));
            return;
        }
        if(!description){
            messageApi.info(t('createTip2'));
            return;
        }

        if(!imageUrl){
            messageApi.info(t('createTip3'));
            return;
        }
        var curGroup = selectGroup  ? selectGroup : group[0]['name'];
        var curCollectData = selectCollection;
        if(!curCollectData){
            // console.log('切换标签');
            let tags = group.filter(function (item) {
                if (item.name == curGroup) {
                    return item.tags;
                }
            });
            curCollectData = tags[0]['tags'].split(',')[0];
        }
        let formData = new FormData();
        var curNetWork= selectNetwork ? selectNetwork : network[0]['name']
        formData.append('title', title);
        formData.append('description', description);
        formData.append('characteristics', curGroup);
        formData.append('collection', curCollectData);
        formData.append('network', curNetWork);
        formData.append('url', imageUrl);
        setLoading(true);
          
        createURI(formData).then((res) => {
            if(res.code == 1){
               // console.log(res.data.uri);
                startMint(res.data.uri);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const createCreateForm = () => {
        return (<div className='text-white m-2 py-4'>
            <CategoryTitle title={t('create1')} ></CategoryTitle>
            <div className="m-4 p-1"></div>
            <ContentTitle title={t('create2')} className={className}></ContentTitle>
            <Input placeholder={t('create3')} value={title} onChange={changeTitle} className='input-style border-0 rounded-2  mx-2  px-4 py-2 '></Input>
            <ContentTitle title={t('create4')} className={className}></ContentTitle>
            <Input placeholder={t('create5')} value={description} onChange={changeDescription} className='input-style border-0 rounded-2  mx-2  px-3 py-1'></Input>
            <ContentTitle title={t('create6')} className={className}></ContentTitle>
            <Form.Group className=" mb-3">
                <Form.Select className='select-style border-0 text-white  mx-2' onChange={changeGroup} >
                    {group && group.map((item, key) => {
                        return (<option key={key} idx={key}>{item.name}</option>);
                    })}
                </Form.Select>
            </Form.Group>
            <ContentTitle title={t('create7')} className={className}></ContentTitle>
            <Form.Group className=" mb-3">
                <Form.Select className='select-style border-0 text-white  mx-2' onChange={changeCollection}>
                    {collection && collection.map((item, key) => {
                        return (<option key={key} idx={key}>{item}</option>);
                    })}
                </Form.Select>
            </Form.Group>
            <ContentTitle title={t('create8')} className={className}></ContentTitle>
            <Form.Group className=" mb-3">
                <Form.Select className='select-style border-0 text-white  mx-2' onChange={changeNetwork}  >
                    {network && network.map((item, key) => {
                        return (<option key={key}>{item.name}</option>);
                    })}
                </Form.Select>
            </Form.Group>
            <ContentTitle title={t('create9')} className={className}></ContentTitle>

            <div className='text-area-style border-0 text-white  mx-2 p-4 w-50 round-2 d-flex justify-content-center item-align-center mb-4 text-center' >

                <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={domainURL + '/api/nft/upload'}
                    beforeUpload={false}
                    onChange={handleChange}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
            </div>

            <div>
                <div className='fs-6 px-3 default-text-color'><small>{t('create10')}</small></div>
                <div className='fs-6  px-3 default-text-color'><small>{t('create11')}</small></div>
            </div>
            {/* <Button className='button-offer w-100 py-2 my-4 fs-6 fw-bold' onClick={createNFT}  >{t('create12')}</Button> */}

            <Button className='button-offer w-100 py-2 my-4 fs-6 fw-bold  ' disabled={loading} onClick={createNFT}  >
                {loading ? <Loading></Loading> : t('create12')}
            </Button>

            
        </div>);
    }

    return (
        <div className='bgLinearColor'>
            {contextHolder}
            <Header></Header>
            <Container>
                <LinearBorderBox item={createCreateForm()}></LinearBorderBox>
            </Container>
            <Footer></Footer>
        </div>
    )
}

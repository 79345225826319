import Web3 from "web3";
import NFTJSON from '../build/NFT.json';
import NFTTokenJSON from '../build/NFTToken.json';
import NFTExchangeJSON from '../build/NFTExchange.json';
import { NFTAddress, NFTTokenAddress, NFTExchangeAddress }  from './util';
 
 

var web3 = new Web3(Web3.givenProvider);

const startInitWeb = async () => {
    if (typeof window.web == 'undefined') {
        
        
        let account = await web3.eth.requestAccounts();
        var NFTContract = await new web3.eth.Contract(NFTJSON, NFTAddress);
        var NFTTokenContract = await new web3.eth.Contract(NFTTokenJSON, NFTTokenAddress);
        var NFTExchangeContract = await new web3.eth.Contract(NFTExchangeJSON, NFTExchangeAddress); 
        var initweb = {
            web3,
            NFTContract,
            NFTTokenContract,
            NFTExchangeContract,
            account: account[0]
        };
        window.web = initweb;
        

        let curChainId = await web3.eth.getChainId()
        if(curChainId != 56){
            // console.log('需要切换网络');
            // await window.ethereum.request({
            //     method: 'wallet_switchEthereumChain',
            //     params: [{chainId: '0x38' }],
            //   });
        }
        
        window.ethereum.on('networkChanged', function(){
            console.log('网络发生了变化');	

        });
        window.ethereum.on('chainChanged', function(){
            console.log('区块链节点发生了变化');
        });
        window.ethereum.on('accountsChanged', function(){
            console.log('账号发生了变化');
        });
        return initweb;
    } else {
        return window.web;
    } 
};
export { startInitWeb };


import Web3 from "web3";

var web3 = new Web3(Web3.givenProvider);

const startCustomInitPledge = async (param) => {
    console.log(param);
    let account = await web3.eth.requestAccounts();
    var LpContract = await new web3.eth.Contract(param.lpAbi, param.lp);
    var PledgeContract = await new web3.eth.Contract(param.pledgeAbi, param.pledge); 
    var initPledge = {
        web3,
        LpContract,
        PledgeContract,
        account: account[0]
    };  
    return initPledge;
      
};
export { startCustomInitPledge };


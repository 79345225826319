import React, { useEffect, useState } from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { Button, Container, Table } from 'react-bootstrap'
import LinearBorderBox from '../common/LinearBorderBox' 
import ContentTitle from '../common/ContentTitle' 
import { Tabs, Upload, Space, Input } from 'antd'
import { domainURL } from '../../util/util'
import { startInitWeb } from '../../util/startInitWeb'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo } from '../../redux/slices/userSlice'
import { message } from 'antd'; 
import { useTranslation } from 'react-i18next';
import Loading from '../common/Loading'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const className = 'fs-4 default-text-color  px-3 py-1 mt-4 mb-1';

export default function User() {

    const dispatch = useDispatch();
    const [account, setAccount] = useState('');
    const [imageUrl, setImageUrl] = useState('/assets/no-picture.png');
    const userInfo = useSelector(state => state.user.info);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    

    const { t  } = useTranslation();

   


    const onChange = (res) => {
        console.log(res);
    }

    const changeNickname = (event) => {
        let newInfo = JSON.parse(JSON.stringify(userInfo));
        newInfo.nickname = event.target.value;
        dispatch(setInfo(newInfo));
    }
    // const changeUsername = (event) => {
    //     let newInfo = JSON.parse(JSON.stringify(userInfo));
    //     newInfo.username = event.target.value;
    //     dispatch(setInfo(newInfo));
    // }

    const changeBio = (event) => {
        let newInfo = JSON.parse(JSON.stringify(userInfo));
        newInfo.bio = event.target.value;
        dispatch(setInfo(newInfo));
    }
    const changeWeb = (event) => {
        let newInfo = JSON.parse(JSON.stringify(userInfo));
        newInfo.websitelink = event.target.value;
        dispatch(setInfo(newInfo));
    }
    const changeEmail = (event) => {
        let newInfo = JSON.parse(JSON.stringify(userInfo));
        newInfo.email = event.target.value;
        dispatch(setInfo(newInfo));
    }
    const changegGroupId = (event) => {
        let newInfo = JSON.parse(JSON.stringify(userInfo));
        newInfo.group_id = event.target.value;
        dispatch(setInfo(newInfo));
    }

    const changegTwitter = (event) => {
        let newInfo = JSON.parse(JSON.stringify(userInfo));
        newInfo.twitter = event.target.value;
        dispatch(setInfo(newInfo));
    }
    const changegInstagram = (event) => {
        let newInfo = JSON.parse(JSON.stringify(userInfo));
        newInfo.ins = event.target.value;
        dispatch(setInfo(newInfo));
    }

    const contactInstagram = () => { 
       window.open(userInfo.instagram,'_blank');
    }
    const contactTwitter= () => { 
       window.open(userInfo.twitter,'_blank');
    }



    const signMessage = async (newInfo) => {
        const message = newInfo.address.substr(2,newInfo.address.length);
        console.log('message: ',message);
        console.log(window.web.account);
        const signature = window.web.web3.eth.personal.sign('oceannft.io', window.web.account);
        return signature;
    }
    const fetchData = async () => {
        
        let newInfo = JSON.parse(JSON.stringify(userInfo));
        newInfo['address'] = window.web.account;
        console.log(newInfo);
        newInfo['sign'] = await signMessage(newInfo);


        let formData = new FormData();
        formData.append('nickname', newInfo.nickname);
        formData.append('avatar', newInfo.avatar);
        formData.append('bio', newInfo.bio);
        formData.append('websitelink', newInfo.websitelink ? newInfo.websitelink : '');
        formData.append('email', newInfo.email);
        formData.append('group_id', newInfo.group_id ? newInfo.group_id : '');
        formData.append('sign', newInfo.sign);
        formData.append('address', newInfo.address);
        formData.append('twitter', newInfo.twitter ? newInfo.twitter : '' );
        formData.append('ins', newInfo.ins ? newInfo.ins : '');
        formData.append('profileimage', newInfo.profileimage);
        formData.append('banner', newInfo.banner);

        return fetch(domainURL + '/api/nft/profile', {
            method: 'post',
            body: formData,
        }).then((res) => res.json())
    }
    const updateUserInfo = async (event) => {
        setLoading(true);
        //TODO 增加4002弹框提示
        fetchData().then((res) => { 
            if (res.code === 1) {
                messageApi.info(res.msg);
            } else {
                messageApi.info(res.msg);
            }
            setLoading(false);
        }).catch((err) => {
            if (err.code == 4001) {
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
        });
    }
     
    const handleChange = (event) => {
        if (event.file.response) {
            let newInfo = JSON.parse(JSON.stringify(userInfo));
          
            setImageUrl(event.file.response.data.url);
            newInfo.avatar = event.file.response.data.url;
            dispatch(setInfo(newInfo));
        }
    }

    const uploadButton = (
        <div>
            {loadings ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleInfoChange  =(event) => {
        if (event.file.response) {   
            let newInfo = JSON.parse(JSON.stringify(userInfo)); 
            newInfo.avatar = event.file.response.data.url;
            dispatch(setInfo(newInfo)); 
        }
    }
    const handleProfileimageChange  =(event) => {
        if (event.file.response) {   
            let newInfo = JSON.parse(JSON.stringify(userInfo)); 
            newInfo.profileimage = event.file.response.data.url;
            dispatch(setInfo(newInfo)); 
        }
    }

    

    const handleBannerChange  =(event) => {
        if (event.file.response) {   
            let newInfo = JSON.parse(JSON.stringify(userInfo)); 
            newInfo.banner = event.file.response.data.url;
            dispatch(setInfo(newInfo));
        }
    }
 
    const createEditProfile = () => {
        return (<div>
            <div className="m-4 p-1 avatar text-center">
                <Upload
                    name="file"
                    listType="picture-circle"
                    className="avatar-uploader rounded-circle"
                    showUploadList={false}
                    action={domainURL + '/api/nft/upload'}
                    beforeUpload={false}
                    onChange={handleChange}
                >
                    {<img src={userInfo.avatar || imageUrl} alt="avatar" className="rounded-circle" style={{ width: '100%' }} />}
                </Upload>
            </div>
            <p className='fs-6  default-text-color text-center'><small>{t('desc1')}</small></p>
            <ContentTitle title={t('nickname')} className={className}></ContentTitle>
            <Input placeholder={t('nickname')} value={userInfo.nickname} onChange={changeNickname} className='input-style border-0 rounded-2  mx-2  px-4 py-2 '></Input>
            {/* <ContentTitle title="Last Name" className={className}></ContentTitle>
            <Input placeholder='Last Name...' value={userInfo.username} onChange={changeUsername} className='input-style border-0 rounded-2  mx-2  px-3 py-1'></Input> */}
            <ContentTitle title={t('biography')} className={className}></ContentTitle>
            <Input placeholder={t('biography')} value={userInfo.bio} onChange={changeBio} className='input-style border-0 rounded-2  mx-2  px-3 py-1'></Input>
            <ContentTitle title={t('usecollection')} className={className}></ContentTitle>
            <Input placeholder={t('usecollection')} value={userInfo.group_id} onChange={changegGroupId} className='input-style border-0 rounded-2  mx-2  px-3 py-1'></Input>
            
            
            <ContentTitle title={t('socialLinks')} ftitle={t('socialLinksUseFul')} className={className}></ContentTitle>
            
            <Space.Compact style={{width: '100%'}}>
                <Input placeholder='Twitter' value={userInfo.twitter} onChange={changegTwitter} className='input-style border-0 rounded-2  ms-2  px-3 '></Input>
                <Button type="primary" style={{minWidth: '120px',background:'#339DCF',borderRadius:0,border:'none'}} onClick={contactTwitter}>{t('socialLinkscontact')}</Button>
            </Space.Compact>
            <Space.Compact style={{width: '100%',marginTop:10}}>
                <Input placeholder='Instagram' value={userInfo.ins} onChange={changegInstagram} className='input-style border-0 rounded-2  ms-2  px-3 '></Input>
                <Button type="primary" style={{minWidth: '120px',background:'#339DCF',borderRadius:0,border:'none'}} onClick={contactInstagram}>{t('socialLinkscontact')}</Button>
            </Space.Compact>
 
            
            
            <ContentTitle title={t('weblink')} className={className}></ContentTitle>
            <Input placeholder='http://' value={userInfo.websitelink} onChange={changeWeb} className='input-style border-0 rounded-2  mx-2  px-3 py-1'></Input>
            <ContentTitle title={t('emailaddress')} className={className}></ContentTitle>
            <Input placeholder='name@example.com' value={userInfo.email} onChange={changeEmail} className='input-style border-0 rounded-2  mx-2  px-3 py-1'></Input>
            <ContentTitle title={t('walletAddress')} className={className}></ContentTitle>
            <Input placeholder='0x348963ed.......e4b4104b678156e22c664' value={account} className='input-style border-0 rounded-2  mx-2  px-3 py-1' disabled></Input>

            {/* <div className='border-0 text-white  mx-2 w-50 round-2 d-flex justify-content-center mx-auto item-align-center my-4' >
                <img src="/assets/BANNER.png" ></img>
            </div> */}

            {/* <div className='fs-6 px-3 default-text-color  text-center'><small>Note:Gas fee required, it will deducted from your wallet</small></div> */}
            
            <ContentTitle title={t('userInfoImg')} className={className}></ContentTitle>
            <div className='text-area-style border-0 text-white  mx-2 p-4 w-50 round-2 d-flex justify-content-center item-align-center mb-4 text-center' >

                <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={domainURL + '/api/nft/upload'}
                    beforeUpload={false}
                    onChange={handleProfileimageChange}
                >
                    {userInfo.profileimage ? <img src={userInfo.profileimage} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
            </div>

            <ContentTitle title={t('userInfoBanner')} className={className}></ContentTitle>
            <div className='text-area-style border-0 text-white  mx-2 p-4 w-50 round-2 d-flex justify-content-center item-align-center mb-4 text-center' >

                <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={domainURL + '/api/nft/upload'}
                    beforeUpload={false}
                    onChange={handleBannerChange}
                >
                    {userInfo.banner ? <img src={userInfo.banner} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
            </div>


            <Button className='button-offer w-100 py-2 my-4 fs-6 fw-bold  ' disabled={loading} onClick={updateUserInfo}  >
                {loading ? <Loading></Loading> : t('updateProfile')}
            </Button>
        </div>);
    }

    // const createWithdraw = () => {
    //     return (<div>
    //         <ContentTitle title="Withdraw" className={className}></ContentTitle>
    //         <div className='fs-6 px-3 default-text-color  text-left'><small>Important</small></div>
    //         <div className='fs-6 px-3 default-text-color  text-left'><small>Please reload your balance them send amount less than balance</small></div>
    //         <div className='fs-3 px-3 default-text-color  default-text-blur text-center my-4 p-4'>0 BNB Reload balance</div>
    //         <ContentTitle title="Wallet Address*" className={className}></ContentTitle>
    //         <Input placeholder='Wallet Address Ex:0x' className='input-style border-0 rounded-2  mx-2  px-4 py-2 '></Input>
    //         <ContentTitle title="Amount*" className={className}></ContentTitle>
    //         <Input placeholder='Enter send amount' className='input-style border-0 rounded-2  mx-2  px-3 py-1'></Input>
    //         <Button className='button-offer  py-2 my-4 fs-6 fw-bold'  >Send</Button>
    //     </div>);
    // }

    const createTransactions = () => {
        return (<div id="table-nft">
            <ContentTitle title="Transactions" className={className}></ContentTitle>
            <Table borderless responsive hover className='default-text-color table-striped'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Offer</td>
                        <td>0.0900000 MATIC</td>
                        <td>0x3</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Offer</td>
                        <td>0.0900000 MATIC</td>
                        <td>0x3</td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        </div>);
    }

    const items = [
        {
            key: '1',
            label: t('editprofile'),
            children: createEditProfile()
        }, 
    ];


    const createCreateForm = () => {
        return (<div className='text-white  m-2  py-4'>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>);
    }
    const getNFTList = async (url) => {
        return fetch(url).then((res) => res.json())
    }

   
  

    

    


    useEffect(() => {
        async function startWeb3() {
            var web = null;
            if (typeof window.web == 'undefined') {
                web = await startInitWeb();
            } else {
                web = window.web;
            }
            setAccount(web.account); 
            const url = domainURL + `/api/nft/userinfo?address=${window.web.account}`;
            getNFTList(url).then((res) => {
                if (res.code === 1) { 
                    dispatch(setInfo(res.data));
                }
            });
        } 
        startWeb3();

    },[])


    return (
        <div className='bgLinearColor'>
            <Header></Header>
            {contextHolder}
            <Container>
                <LinearBorderBox item={createCreateForm()}></LinearBorderBox>
            </Container>
            <Footer></Footer>
        </div>
    )
}

import React from 'react'
import { HeartOutlined } from '@ant-design/icons';
import { beautySub } from '../../util/util';
export default function TopSellerItem(props) {
  const {index, seller} = props;

  return (
    <div className='px-1  col-sm-12 col-md-6 col-lg-3 d-flex align-items-center'>
      <div  className='default-text-color font-weight-bold  h4 '>0{index+1}.</div>

      <div className='d-inline mx-2'>
        <div className='overflow-hidden rounded-2'>
          <img src={seller.selluser.avatar || "/assets/avatar.png"} className='rounded-circle ' width="40px" height="40px"></img>
        </div>
      </div>

      <div className='px-2  col-sm-6 col-md-6 col-lg-6 d-flex flex-column justify-content-start align-items-start'>
        <div className='default-text-color h6'>{beautySub(seller.selluser.nickname,4)}</div>
        <div className='default-text-bababa  h6'>{seller.total}NFTS</div>
      </div> 
    </div>
  )
}

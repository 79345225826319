import React from 'react'
import { FacebookFilled, TwitterSquareFilled, GoogleSquareFilled, YoutubeFilled, InstagramFilled } from '@ant-design/icons';
import { Container, Dropdown } from 'react-bootstrap';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function Footer() {

  const { t, i18n } = useTranslation();
   

  const changeItem = (event) => {  
    var lang = event.target.dataset.value == 1 ? 'en' : 'zh';
    localStorage.setItem('lang',lang);
    i18next.changeLanguage(lang)
    .then((t) => {
      t('key'); // -> same as i18next.t
    });
}

  return (
    <div >
      <div className='bottom-style'>
      </div>
      <Container >
        <div className='py-5 row g-3 item-wrap  justify-content-between ' >
          <div className='d-flex align-items-start col-sm-12 col-md-6 col-lg-3 align-middle'>
            <img src="/assets/logo.png" width="50px"></img>
            <div className='default-text-blur h3 mx-2 align-middle'>OCEAN</div>
          </div>

          <div className='d-flex align-items-start justify-items-start   flex-column  col-sm-12 col-md-6 col-lg-3'>
            <div className='default-text-color h3'> {t('search')}</div>
            <div className='default-text-color h6'><a href="/search" className='default-text-color'>{t('allNfts')}</a></div>
            {/* <div className='default-text-color h6'>{t('art')}</div> */}
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 '>
            <div className='default-text-color h3'>{t('joinUs')}</div>
            <div className='d-flex my-3'>
              {/* <div className='mx-2  bg-dark p-2 rounded-2'><FacebookFilled style={{ fontSize: '30px', color: '#E5E5E5' }} /> </div> */}
              <div className='mx-2  bg-dark p-2 rounded-2'>
                <a href="https://twitter.com/OceanBNO" target="_blank">
                  <TwitterSquareFilled style={{ fontSize: '30px', color: '#E5E5E5' }} /
                  ></a>
              </div>
              {/* <div className='mx-2  bg-dark p-2 rounded-2'><GoogleSquareFilled style={{ fontSize: '30px', color: '#E5E5E5' }} /> </div> */}
              <div className='mx-2  bg-dark p-2 rounded-2'>
              <a href="https://t.me/OceanBNO"  target="_blank">
                {/* <YoutubeFilled style={{ fontSize: '30px', color: '#E5E5E5' }} />  */}
                <img src="/assets/telegram.png" style={{ width: '30px', height: '30px' }}></img>
                </a>
              </div>
             </div>
            <div className='default-text-color h6'> 
               <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  English
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item data-value='1' onClick={changeItem} >{t('english')}</Dropdown.Item>
                  <Dropdown.Item data-value='2' onClick={changeItem}>{t('chinese')}</Dropdown.Item> 
                </Dropdown.Menu>
              </Dropdown></div>
            <div className='default-text-color h6'>
              
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Button, Container } from 'react-bootstrap'
import { Pagination } from 'antd'
import { useDispatch } from 'react-redux';
import { setPledgeId, setSelectUnpledgeId } from '../../redux/slices/bnoSlice';
import { startInitPledge } from '../../util/startInitPledge';
import { startInitWeb } from '../../util/startInitWeb';
import { domainURL } from '../../util/util';
import PledgeNFTItem from './PledgeNFTItem'
const limit = 8;

export default forwardRef((props,refparams) => {
  useImperativeHandle(refparams, () => ({
    updatePledgeData:updatePledgeData
  }))

   
  const dispatch = useDispatch();
  const [NFTLists, setNFTLists] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [staticResult, setStaticResult] = useState({ curPage:0,activePage:1,total:0 });

  
  const updatePledgeData = async () => {
    console.log('来更新我吧',props.type);
    if (props.type == 'pledge') {
      // console.log('开始了',props.type);
      if (typeof window.pledge == 'undefined') {
        var web = await startInitPledge();
      } else {
        var web = window.pledge;
      }
      startUpdatePledge(web);
    }
  }

  const startUpdatePledge =async(web = '') => {
     
    var res = await web.PledgeContract.methods.getUserNft(web.account).call(); 
        if (res.length) {
          var datas = [];
          for (let index = 0; index < res.length; index++) {
            if (res[index] > 0) { 
              var url = await web.PledgeNFTContract.methods.tokenURI(res[index]).call(); 
              console.log(url);
              if(url.indexOf('oss.oceannft.io') != -1){
                var JSONDataPromise = await getNFTList(url);   
                datas.push({ url: JSONDataPromise.image, assetId: res[index],id:res[index] });
              }   
            } 
          }
          setNFTLists(datas);
        }
  }


  const getNFTList = async (url) => {
    // console.log(url);
    return fetch(url).then((res) => res.json())
  }

  useEffect(() => {
    async function startWeb3() {
      if (typeof window.pledge == 'undefined') {
        var web = await startInitPledge();
      } else {
        var web = window.pledge;
      } 
      if (props.type == 'pledge') { 
        startUpdatePledge(web); 
      } else if (props.type == 'owner') { 
        updateList(1); 
      }

    }
    startWeb3();
  }, [])

  const pledgeNFT = (res) => { 
    //console.log('选择回调',res);
    //第一步检测除了自己已经被选中的？
    var newData = selectList.filter(item => item != res);
    //console.log('第一步检测除了自己已经被选中的',newData);
    //第二步检测是不是存在的
    var existData = selectList.filter(item => item == res);
    //console.log('第二步检测自己有没有被选中',existData);
    //更新选择的数据 
    
    if (existData.length) {
      //如果自己被选中，就把除了自己的的已选中的保存
      setSelectList(newData);
    } else {
      //如果自己没有被选中，就把自己加入进去
      newData.push(res)
      setSelectList(newData);
    }
    //结果就是 select是已经被选中的
    //console.log(selectList);
   
    if (props.type == 'pledge') { //pledge 代表已经被质押的需要赎回的
      //赎回NFT,选择的NFT
      dispatch(setSelectUnpledgeId(newData));//赎回那几个
      //console.log('质押NFT,选择的NFT:',newData);
    } else if (props.type == 'owner') {  //pledge 代表没有被质押，可以进行质押的
      //质押NFT,选择的NFT
      //console.log('质押NFT,选择的NFT:',newData);//质押那几个
      dispatch(setPledgeId(newData));
    }
    //console.log(NFTLists);

  }
  const checkExists = (res) => { 
    var existData = selectList.filter(item => item == res);
    return existData.length
  }

  const clickItem = (number) => {
    updateList(number);
  }
  const updateList = (page) => {
    const { account } = window.web;
    const url = domainURL + `/api/nft/mylist?address=${account}&type=owner&pageNumber=${page}&pageSize=8`;
        getNFTList(url).then((res) => {
          if (res.code == 1) { 
            var newData = staticResult; 
            newData.activePage = page; 
            newData.total = res.data.total;  
            newData.curPage = Math.ceil(res.data.total / limit); 
            setStaticResult(newData);
            setNFTLists(res.data.list);
          }
        });
  }
  
  const createPageNode = () => {
    let items = []; 
    for (let number = 1; number <= staticResult.curPage; number++) {
      items.push(
        <Pagination.Item key={number} onClick={clickItem.bind('', number)} active={number === staticResult.activePage}>
          {number}
        </Pagination.Item> 
      );
    }
    return items;
  }



  return (
    <Container>
      <div className='row g-3 item-wrap d-flex'>
        {NFTLists.map(function (item, key) {
          return (<div className='px-2 col-sm-6 col-md-6 col-lg-3 ' key={key}>
            <PledgeNFTItem nft={item} index={key} key={key} callback={pledgeNFT} isSelect={checkExists(item.assetId)} type="pledge" from='unPledge' ></PledgeNFTItem>
            
          </div>);
        })}
        <div className='my-4'>
                {/* <Pagination>{createPageNode()}</Pagination> */}
                <Pagination defaultCurrent={staticResult.activePage} 
                            total={staticResult.total}  
                            showSizeChanger={false} 
                            pageSize={8}
                            onChange={clickItem} />
            </div>
      </div>
    </Container>
  )


});

 
 


import React from 'react'
import { useTranslation } from 'react-i18next';

export default function NFTItem(props) {
  const { nft, type } = props;
  const { t } = useTranslation();

  const boxStyle = {
    //background:"url('/assets/bg-round.png')",
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    boxShadow: 'rgb(136, 136, 136) 0px 0px 50px 10px inset',
  }
  const imgBox = {
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1
  }
  const createOrder = () => {
    props.callback(nft);
  }
  const cancleOrder = () => {
    props.callbackCancleOrder(nft);
  }


  const createButton = () => {
     
    if (type == 'owner') {
      var node = (<div className="btn-group px-3  d-flex flex-row justify-content-center align-items-center cursor-pointer" onClick={createOrder}>
        <div className='default-text-color text-center'>{t('usert1')}</div>
      </div>);
    } else if (type == 'sell') {
      if (nft.status == 1) {
        var node = (<div className="btn-group-disable px-3  d-flex flex-row justify-content-center align-items-center cursor-pointer" >
          <div className=' text-center'>{t('usert2')}</div>
        </div>);
      } else if (nft.status == 2) {
        var node = (<div className="btn-group-disable px-3  d-flex flex-row justify-content-center align-items-center cursor-pointer"  >
          <div className=' text-center'>{t('usert3')}</div>
        </div>);
      } else if (nft.status == 0 && nft.bidcount == 0) {
        var node = (<div className="btn-group px-3  d-flex flex-row justify-content-center align-items-center cursor-pointer" onClick={cancleOrder}>
          <div className='default-text-color text-center'>{t('usert4')}</div>
        </div>);
      } else if (nft.status == 0 && nft.bidcount != 0) {
        var node = (<div className="btn-group-disable px-3  d-flex flex-row justify-content-center align-items-center cursor-pointer" >
          <div className='text-center'>{t('usert5')}</div>
        </div>);
      }
    }
    return node;
  }


  return (
    <div className='col-sm-12 col-md-12 col-lg-12'>
      <div className="top-box-shadow rounded-2">
        <div style={boxStyle} className="rounded-2">
          <div className="nft-items default-text-color overflow-hidden p-3"   >
            <a   className='nft-image overflow-hidden rounded-2  d-flex  '>
              <img src={nft.url || (nft.nft &&  nft.nft.url)  || '/assets/no-nft.png'} className='img-rounded img-fluid margin w-100' style={imgBox}></img>
            </a>
          </div>
          <div className='card-body content position-relative  p-3 pt-0'>
            <div className='d-flex justify-content-between mb-2 d-flex flex-row align-items-center'>
              <div className="img-group">
                <a className='user-avatar '>
                  <img src={(nft.createduser && nft.createduser.avatar) || (nft.selluser && nft.selluser.avatar) || (nft.nft && nft.nft.owneruser && nft.nft.owneruser.avatar) || "/assets/avatar.png"} className='avatar avatar-md-sm shadow-md rounded-pill'></img>
                </a>
              </div>
              <div className=" px-3  d-flex flex-row justify-content-center align-items-center cursor-pointer">
              
                <div className='default-text-color text-center'>#{nft.assetId || nft.nft_id}</div>

              </div>
            </div>

            {createButton()}
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Container } from 'react-bootstrap';

export default function CategoryTitle(props) {
    const { title } = props;
    
    return (
        <Container >
            <h1 className="default-text-color  fs-2 ">
                {title}
            </h1>
        </Container>
    )
}

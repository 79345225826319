import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { domainURL, fetchData } from '../../util/util';
import DropdownButton from '../common/DropdownButton'
import useSWR from 'swr'; 
import { setNftGroup, setNftNetwork } from '../../redux/slices/configSlice';
import { useTranslation } from 'react-i18next';
import '../css/group.css';

export default function DropdowBox(props) {

    const { t  } = useTranslation();

    const dispatch = useDispatch();

    const network = useSelector(state => state.config.nftNetwork);
    const group = useSelector(state => state.config.nftGroup);

    const { data  } = useSWR([domainURL + `/api/nft/group`], fetchData);
    if (data) {
        dispatch(setNftGroup(data.data.nft_group));
        dispatch(setNftNetwork(data.data.nft_network));
    }

    
    const [selectGroup, setSelectGroup] = useState();
    const [selectCollection, setSelectCollection] = useState('');
    const [selectNetwork, setSelectNetwork] = useState('');

    

    const getCollection = (value = '') => { 
        var curGroup = value ? value : (selectGroup  ? selectGroup : group[0]['name']); 
        let tags = group.filter(function (item) {
            if (item.name == curGroup) {
                return item.tags;
            }
        }); 
        
        let result =  tags[0]['tags'].split(',').map((res) => {
            return {name:res};
        }); 
        return result;
    }
    const getFirstName = (list) => {  
        return list[0]['name'];
    }
    
     
    const changeGroup = (value) => { 
        setSelectGroup(value);
        var curNetWork= selectNetwork ? selectNetwork : ''; 
        var curCollection= selectCollection ? selectCollection : '';//getFirstName(getCollection(value)); 
         
        props.callback(value,curCollection,curNetWork);
    }
    const changeCollection = (value) => { 
        setSelectCollection(value);
        var curGroup = selectGroup  ? selectGroup : ''; 
        var curNetWork= selectNetwork ? selectNetwork : ''; 
        console.log(curGroup,value,curNetWork);
        props.callback(curGroup,value,curNetWork);
    }
    const changeNetwork = (value) => { 
        setSelectNetwork(value);
        var curGroup = selectGroup  ? selectGroup : '';
        var curCollection= selectCollection ? selectCollection : '';// getFirstName(getCollection(value));  
        props.callback(curGroup,curCollection,value);
    }
    
      
    return (
        <div className='d-flex flex-row flex-wrap'>
           <div className='mb-2'> <DropdownButton icon="/assets/search_icon_1.png" list={group} title={t('group1')} changeValue={changeGroup}></DropdownButton></div>
           <div className='mb-2'>{group.length ? (<DropdownButton icon="/assets/search_icon_2.png" title={t('group2')} list={getCollection()} changeValue={changeCollection}></DropdownButton>) : null}</div>
           <div className='mb-2' > <DropdownButton list={network} changeValue={changeNetwork} title={t('group3')}></DropdownButton></div>
        </div>
    )
}

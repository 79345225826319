import React from 'react' 


const defaultClassName = 'default-text-blur fs-4';
 
export default function ContentTitle(props) {
    const { title, className = defaultClassName, ftitle } = props;
    return (
        <div >
            <h1 className={className} >
                {title}
                {ftitle ? (<span className="default-text-color  fs-6 ">({ftitle}) </span>) : '' } 
            </h1>
        </div>
    )
}

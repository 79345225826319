import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
const userSlice = createSlice({
    name: "user",
    initialState: {
        info: {}, 
    },
    reducers: {
        setInfo(state, action) {
            state.info = action.payload;
        }

    }
});

export const { setInfo } = userSlice.actions;
export default userSlice.reducer;
 

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
const configSlice = createSlice({
    name: "config",
    initialState: {
        nftGroup: [],
        nftNetwork: []
    },
    reducers: {
        setNftGroup(state, action) {
            state.nftGroup = action.payload;
        },
        setNftNetwork(state, action) {
            state.nftNetwork = action.payload;
        }, 

    }
});

export const { setNftGroup, setNftNetwork } = configSlice.actions;
export default configSlice.reducer;

 
import React from 'react'
import { Button, CloseButton, Modal } from 'react-bootstrap'
import NFTNode from './NFTNode'
import { useTranslation } from 'react-i18next';
import Loading from './Loading';


export default function VerticallyModalNFTNode(props) {

    const { loadingtype, loading } = props;
    const { t } = useTranslation();

    const hideModal = () => {
        props.onHide();
    }

    const addNFT = () => {
        props.addnft();
    }
 
    
    return (
        <Modal
            {...props}
            size="lg"
            closeable="true"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            variant="white"
        > <div className='pup-outer pup-outer2' >
                <Modal.Header >
                    <Modal.Title className='w-100'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <span>{t('addnft')}</span>
                            <CloseButton onClick={hideModal} variant="white" />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NFTNode type="owner"></NFTNode>
                </Modal.Body>
                <Modal.Footer >

                    <Button className='button-offer  button-common   py-2 my-4  me-3 fs-6 fw-bold  hvr-shutter-in-horizontal overflow-hidden  rounded-4' disabled={props.loading} onClick={addNFT}  >

                        {loading && loadingtype == 'stakeNft' ? <Loading></Loading> : t('addnft')}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>

    )
}

import React, { useEffect, useState, useRef } from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { Button, Container, Table } from 'react-bootstrap'
import LinearBox from '../common/LinearBox'
import { Tabs, Upload, Space, Input } from 'antd'
import { domainURL,BNO, USDT, Router } from '../../util/util'
// import { startInitWeb } from '../../util/startInitWeb'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo } from '../../redux/slices/userSlice'
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../common/Loading'
import { startCustomInitPledge } from '../../util/startCustomInitPledge'
import { startNftPledge } from '../../util/startNftPledge'
import Web3 from "web3";
//引入ABI文件
import BN from 'bn.js'

import lpAbi from '../../build/listAbi/lpAbi.json';
import pledgeAbi from '../../build/listAbi/pledgeAbi.json';
import bnoAbi from '../../build/listAbi/token.json';
import routerAbi from '../../build/listAbi/router.json';

// import ModalToken from '../common/ModalToken.js';
import ModalStake from '../common/ModalStake.js';
import ModalUnStake from '../common/ModalUnStake.js';
import ModalStakeNFT from '../common/ModalStakeNFT.js';
import ModalUnStakeNFT from '../common/ModalUnStakeNFT.js';
import VerticallyModalNFTNode from '../common/VerticallyModalNFTNode.js';
import BigNumber from "bignumber.js";



export default function User() {

    const dispatch = useDispatch();
    const [account, setAccount] = useState('');
    const [imageUrl, setImageUrl] = useState('/assets/no-picture.png');
    const userInfo = useSelector(state => state.user.info);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalUrl, setModalUrl] = useState('');
    const [modalAmount, setModalAmount] = useState('');
    const [Annual_ROI, setModalROI] = useState(0);
    const [pledgeKey, setPledgekey] = useState(0);
    const countRef = useRef(pledgeKey);

    const [pledgeList, setPledgeList] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [stakeObj, setStakeObj] = useState(false);
    const [modalNFTShow, setModalNFTShow] = useState(false);
    const [modalNFTShow2, setModalNFTShow2] = useState(false);


    const [spinStatus, setSpinStatus] = useState(false);
    const [loadingType, setLoadingType] = useState('');
    const [tips, setTips] = useState('');



    const { t } = useTranslation();

    const outerStyle = {
        // background:'#252244',
        width: '100%',
        minHeight: '500px'
    }
    const headerStyle = {
        background: '#252244',
    }

    const detailStyle = {

    }

    const toggleDom2 = async (index) => {
        let pledgeRes = [...pledgeList];
        pledgeRes[index].isVisible = !pledgeRes[index].isVisible
        setPledgeList(pledgeRes)
    }


    const getIncome = (pendingFit) => {
        if (pendingFit) {
            var res = Web3.utils.fromWei(pendingFit);
            return parseFloat(res).toFixed(2);
        } else {
            return 0;
        }
    }

    //开始进行操作了
    //质押代币
    const pledgeSomeToken = async (data, key) => {
        console.log(data)
        // let name = data.target.dataset.name;
        // let key = data.target.dataset.key;

        setModalTitle(data.name);
        setModalROI(0)
        setModalUrl(data.lpurl);
        setPledgekey(key);
        
        setModalAmount(parseInt(data.info.lpbal * 1000) / 1000)
        setModalShow(true);

    }

    const pledgeSomeToken2 = async (data, key) => {
        console.log(data)
        // let name = data.target.dataset.name;
        // let key = data.target.dataset.key;

        setModalTitle(data.name);
        setModalROI(0)
        setModalUrl(data.lpurl);
        setPledgekey(key);
        setModalAmount(parseInt(data.info?.allstake* 1000) / 1000)
        setModalShow2(true);

    }

    //提现
    const startDraw = async (data) => {
        let info = data['info'];
        console.log(info);

        if (info.pendingFit == 0) {
            messageApi.error(t('tips7'));
            return;
        }
        if (spinStatus) {
            return;
        }
        if (spinStatus) {
            messageApi.error(t('tips16'));
            return;
        }
        const { PledgeContract, account } = info;

        setSpinStatus(true);
        setLoading(true);
        setLoadingType('draw');
        setTips(t('tips14'));

 

        let withdrawalFee = data.bnbfee
        withdrawalFee = withdrawalFee != 0 ? Web3.utils.toWei(String(withdrawalFee)):0

        PledgeContract.methods.withdraw().estimateGas({ from: account, value: withdrawalFee }).then((res) => {
            estimateGasDrawSuccess(info, withdrawalFee);
        }).catch((error) => {
            if (error.code == 4001) {
                messageApi.error(t('userDenied'));
            } else {
                messageApi.error(t('insufficientGas'));
            }
            setLoading(false);
            setSpinStatus(false);

        });

    }

    const estimateGasDrawSuccess = (info, withdrawalFee) => {
        const { PledgeContract, account } = info;

        PledgeContract.methods.withdraw().send({ from: account, value: withdrawalFee}).then((res) => {
            console.log(res);
            setSpinStatus(false);
            messageApi.info(t('tips8'));
            setLoading(false);
            initialization();

        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
            setSpinStatus(false);
        });
    }

    //赎回
    const startRedeem = async (repedgeNumber) => {
        console.log("startRedeem");
        let info = pledgeList[pledgeKey]['info'];
        if (spinStatus) {
            return;
        }

        const { PledgeContract, account } = info;
        setSpinStatus(true);
        setTips(t('tips5'));
        setLoading(true);
        setLoadingType('redeembon');

        repedgeNumber = Web3.utils.toWei(repedgeNumber);
        console.log(repedgeNumber);
let withdrawalFee = pledgeList[pledgeKey].bnbfee
        withdrawalFee = withdrawalFee != 0 ? Web3.utils.toWei(String(withdrawalFee)):0

        PledgeContract.methods.rePledge(repedgeNumber).estimateGas({ from: account, value: withdrawalFee }).then((res) => {
            estimateGasRedeemSuccess(info, repedgeNumber);
        }).catch((error) => {
            if (error.code == 4001) {
                messageApi.error(t('userDenied'));
            } else {
                messageApi.error(t('insufficientGas'));
            }
            setSpinStatus(false);
            setLoading(false);
        });
    }

    const estimateGasRedeemSuccess = (info, repedgeNumber) => {

        const { LpContract, PledgeContract, account } = info;
        let withdrawalFee = pledgeList[pledgeKey].bnbfee
        withdrawalFee = withdrawalFee != 0 ? Web3.utils.toWei(String(withdrawalFee)):0

        PledgeContract.methods.rePledge(repedgeNumber).send({ from: account, value: withdrawalFee }).then((res) => {
            console.log(res);
            setSpinStatus(false);
            setLoading(false);
            messageApi.info(t('tips1111'));
            setModalShow2(false);
            initialization();
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setSpinStatus(false);
            setLoading(false);
        });
    }

    // 质押
    const startPledge = async (pledgeNumber) => {
        let info = pledgeList[pledgeKey]['info'];
        console.log(pledgeKey, info, pledgeList);
        if (spinStatus) {
            return;
        }
        if (pledgeNumber == 0) {
            messageApi.error(t('tips9'));
            return;
        }

        const { PledgeContract, account, LpContract } = info;

        setSpinStatus(true);
        setLoadingType('pledgebno');
        setLoading(true);
        setTips(t('tips10'));

        let allowanceAccount = await LpContract.methods.allowance(account, info.pledge).call();
        console.log('授权数量：', allowanceAccount);
        let pledgeAmount = Web3.utils.toWei(pledgeNumber);
        console.log('授权数量：', allowanceAccount, '质押数量：', pledgeAmount);
        let withdrawalFee = pledgeList[pledgeKey].bnbfee
        withdrawalFee = withdrawalFee != 0 ? Web3.utils.toWei(String(withdrawalFee)):0

        if (parseInt(allowanceAccount) >= parseInt(pledgeAmount)) {
            console.log(allowanceAccount >= pledgeAmount);
            //console.log('授权数量：',allowanceAccount , '质押数量：',pledgeAmount);
            PledgeContract.methods.pledge(pledgeAmount).estimateGas({ from: account, value: withdrawalFee }).then((res) => {
                estimateGasSuccess(info, pledgeAmount);
            }).catch((error) => {
                if (error.code == 4001) {
                    messageApi.error(t('userDenied'));
                } else {
                    messageApi.error(t('insufficientGas'));
                }
                setLoading(false);
                setSpinStatus(false);
            });
        } else {
            console.log('pledge: ', info.pledge, 'pledgeAmount', pledgeAmount, 'account:', account);
            LpContract.methods.approve(info.pledge, pledgeAmount).send({ from: account }).then((res) => {
                console.log('这里是一个授权的方法结果成功');
                PledgeContract.methods.pledge(pledgeAmount).estimateGas({ from: account, value: withdrawalFee }).then((res) => {
                    estimateGasSuccess(info, pledgeAmount);
                }).catch((error) => {
                    if (error.code == 4001) {
                        messageApi.error(t('userDenied'));
                    } else {
                        messageApi.error(t('insufficientGas'));
                    }
                    setLoading(false);
                    setSpinStatus(false);
                });
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                console.log('这里是一个授权的方法结果失败', error);
                setSpinStatus(false);
                setLoading(false);
            });
        }
    }

    const estimateGasSuccess = async (info, pledgeAmount) => {

        const { LpContract, PledgeContract, account } = info;
        var res = await LpContract.methods.allowance(account, info.pledge).call();
        let withdrawalFee = pledgeList[pledgeKey].bnbfee
        withdrawalFee = withdrawalFee != 0 ? Web3.utils.toWei(String(withdrawalFee)):0

        PledgeContract.methods.pledge(pledgeAmount).send({ from: account, value: withdrawalFee }).then((res) => {
            console.log(res);
            setSpinStatus(false);
            setLoading(false);
            messageApi.info(t('tips11'));
            setModalShow(false);
            initialization();
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
                setModalShow(false);
            }
            setSpinStatus(false);
            setLoading(false);
        });

    }



    const pledgeCallback = (amounts, state) => {
        //setModalShow(false);
        setLoading(true);
        setSpinStatus(true);
        setLoadingType('pledge');
        setLoadingType('redeem');
        setLoadingType('PledgeRedemption');
        if (state == 1) {
            startPledge(amounts);


        } else if (state == 0) {
            startRedeem(amounts)

        }

    }

    const pledgeNFTCallback = (tokenIds, state) => {

        // state 为1 质押
        if(state == 1) {
            addNFT(tokenIds)
        } else {
            startRedeemNFT(tokenIds)
        }
    }
    const addNFT = async (pledgeId) => {
        if (pledgeId.length == 0) {
            messageApi.info(t('tips12'));
            return;
        }
        let nft = pledgeList[pledgeKey]['nft'];
        const { nftContract, Pledge2Contract, account } = nft;
        let pledgeAddress = pledgeList[pledgeKey].address
        setLoading(true);
        var isApprovedForAll = await nftContract.methods.isApprovedForAll(account, pledgeAddress).call();

        if (isApprovedForAll) {
            estimateGasStakeNft(pledgeId);

        } else {
            await nftContract.methods.setApprovalForAll(pledgeAddress, true).send({ from: account }).then((res) => {
                estimateGasStakeNft(pledgeId);
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                setLoading(false);
            });
        }
    }
    const estimateGasStakeNft = async (pledgeId) => {
        let nft = pledgeList[pledgeKey]['nft'];
        const { nftContract, Pledge2Contract, account } = nft;
        let withdrawalFee = pledgeList[pledgeKey].bnbfee
        withdrawalFee = withdrawalFee != 0 ? Web3.utils.toWei(String(withdrawalFee)):0
        Pledge2Contract.methods.stakeNft(pledgeId)
            .estimateGas({ from: account, value: withdrawalFee })
            .then((res) => {
                estimateGasStakeNftSuccess(pledgeId);
            }).catch((error) => {
                if (error.code == 4001) {
                    messageApi.error(t('userDenied'));
                } else {
                    messageApi.error(t('insufficientGas'));
                }
                setSpinStatus(false);
                setLoading(false);
            });

    }
    const estimateGasStakeNftSuccess = async (pledgeId) => {
        let nft = pledgeList[pledgeKey]['nft'];
        const { nftContract, Pledge2Contract, account } = nft;
        let withdrawalFee = pledgeList[pledgeKey].bnbfee
        withdrawalFee = withdrawalFee != 0 ? Web3.utils.toWei(String(withdrawalFee)):0

        Pledge2Contract.methods.stakeNft(pledgeId)
            .send({ from: account, value: withdrawalFee })
            .then((res) => {
                //console.log('质押成功了,你在提示什么',t('tips12'));
                messageApi.info(t('tips13'));
                setModalNFTShow(false);
                setLoading(false);
                //回调主要成功后，需要刷新质押列表
               
                initialization();
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                    setSpinStatus(false);
                setModalNFTShow(false);
                setLoading(false);
            });

    }
    const startRedeemNFT = async (selectUnpledgeId) => {
        if (spinStatus) {
            return;
        }
        if (selectUnpledgeId.length == 0) {
            messageApi.info(t('tips1'));
            return;
        }

        setSpinStatus(true);
        setTips(t('tips2'));
        setLoading(true);
        let nft = pledgeList[pledgeKey]['nft'];
        const { nftContract, Pledge2Contract, account } = nft;
    let withdrawalFee = pledgeList[pledgeKey].bnbfee
        withdrawalFee = withdrawalFee != 0 ? Web3.utils.toWei(String(withdrawalFee)):0

        Pledge2Contract.methods.unstakeNft(selectUnpledgeId)
        .estimateGas({ from: account, value: withdrawalFee })
        .then((res) => {
            estimateGasUnstakeNftSuccess(selectUnpledgeId);
        }).catch((error) => {
            setSpinStatus(false);
            if (error.code == 4001) {
                messageApi.error(t('userDenied'));
            } else {
                messageApi.error(t('insufficientGas'));
            }
            setLoading(false);
        });

    }
    const estimateGasUnstakeNftSuccess = async (selectUnpledgeId) => {
        let nft = pledgeList[pledgeKey]['nft'];
        const { nftContract, Pledge2Contract, account } = nft;
let withdrawalFee = pledgeList[pledgeKey].bnbfee
        withdrawalFee = withdrawalFee != 0 ? Web3.utils.toWei(String(withdrawalFee)):0

        Pledge2Contract.methods.unstakeNft(selectUnpledgeId).send({ from: account, value: withdrawalFee }).then((res) => {
            messageApi.info(t('tips3'));
            setModalNFTShow2(false);
            setLoading(false);
            //回调刷新一下我的已经质押列表
            initialization()

        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
                setSpinStatus(false);
            setLoading(false);
        });
    }
    const pledgeNFT = (item, key) => {
        console.log('pledgeNFT', item)

        setPledgekey(key);
        setStakeObj(item);
        setModalNFTShow(true)
    }
   
   const unpledgeNFT = (item, key) => {
        console.log('unpledgeNFT', item)

        setPledgekey(key);
        setStakeObj(item);
        setModalNFTShow2(true)
    }
    //开始进行操作了
    const handleclose = (pledgeNumber) => {
        setModalShow(false);
        setModalShow2(false);
        setModalNFTShow(false);
        setModalNFTShow2(false);
    }
    var timer = null
    const handlechange = (pledgeNum) => {
        console.log(pledgeNum)
        if(timer) {
            clearTimeout(timer)
            timer = null
        }
        timer = setTimeout(async () => {
            let info = pledgeList[pledgeKey]['info'];
            let lp_address = pledgeList[pledgeKey].lp_address
            const { PledgeContract, account, LpContract, incomeRatio } = info;


            let totalSupply = await LpContract.methods.totalSupply().call()
            console.log("totalSupply",totalSupply)
            // 2. 用户质押数量
            let pledgeNumber = Web3.utils.toWei(pledgeNum)
            console.log("pledgeNumber",pledgeNumber)
            var web3 = new Web3(Web3.givenProvider); 
            var tokenContract = await new web3.eth.Contract(bnoAbi, BNO);
                    var routerContract = await new web3.eth.Contract(routerAbi, Router);

            // 获取lp地址BNO的余额 
            let bno_amount = await tokenContract.methods.balanceOf(lp_address).call();
            console.log("bno_amount",bno_amount)
            
            
            if(pledgeNumber != 0 && bno_amount != 0) {
                // 3 用户质押的数量/lp的总量 * bno的余额
                let out3 = BigNumber(pledgeNumber).dividedBy(totalSupply).multipliedBy(bno_amount).toFixed(0)
            console.log("out3",out3)
                
                let outs4 = await routerContract.methods.getAmountsOut(out3,[BNO, USDT]).call()
                console.log("outs4",outs4)
                let allstakeU = (outs4[1] * 2.2 / Math.pow(10, 20) * incomeRatio).toFixed(2)
                console.log("allstakeU",allstakeU)

                setModalROI(allstakeU)
            }
        }, 500)
    }

    const getNFTList = async (url) => {
        return fetch(url).then((res) => res.json())
    }

    const getListData = async (data) => {
        console.log('getListData ', data);
        let newList = [];
        var web3 = new Web3(Web3.givenProvider); 
        var tokenContract = await new web3.eth.Contract(bnoAbi, BNO);
        var routerContract = await new web3.eth.Contract(routerAbi, Router);
        const updateList = await Promise.all(
            data.map(async (item, key) => {
                let info = null, nft = null
                if(item.startTime > parseInt(new Date().getTime() / 1000)) {
                    console.log('未发布',item)
                    info = {
                        allstake: 0,
                        allstakeU: 0,
                        pendingFit: 0,
                        pendingFitU: 0,
                        lpbal: 0,
                        incomeRatio: 0,
                        liquidity: 0
                    }
                } else {
                    console.log('获取数据',item)
                    info = await startWebPledge(item.address, item.lp_address, item.annual_output, tokenContract,routerContract);
                    console.log("获取到的数据", info)
                }
                // 查询nft
                if(item.nft_address == '0') {
                    nft = {
                        nftAddition: 0,
                        nftUrl: [],
                        nftIds: []
                    }
                } else {
                    nft = await startWebNft(item.nft_address, item.address)
                }
                return { ...item, info, nft }
            })
        )

        setPledgeList(updateList);

    }
    const startWebNft = async (nft_address, address) => {
        try {

            let web = await startNftPledge({
                nft_address,
                address
            })
            const { account, web3, nftContract, Pledge2Contract } = web;
            let result = {
                nftAddition: 0,
                nftAmount: 0,
                nftUrl: [],
                nftIds: [],
                balance: 0,
                ...web
            }
            const userInfo = await Pledge2Contract.methods.userInfo(account).call()
            console.log("userInfo", userInfo)
            result.nftAddition =  web3.utils.fromWei(userInfo.nftAddition);
            result.nftAmount = userInfo.nftAmount
            // 获取已质押的nft
            const nftInfo = await Pledge2Contract.methods.getUserNftAndUri(account).call()
            console.log("nftInfo", nftInfo)
            result.nftIds = nftInfo[0]
            result.nftUrl = nftInfo[1]

            // 获取持有nft
            const balanceOf = await nftContract.methods.balanceOf(account).call()
            console.log(balanceOf)
            result.balance = balanceOf

            
            // for(let i = 0; i < (balanceOf > 4 ? 4 : balanceOf); i++) {
            //     let tokenOfOwnerByIndex = await nftContract.methods.tokenOfOwnerByIndex(account, i).call()
            //     console.log(tokenOfOwnerByIndex)
            //     let tokenURI = await nftContract.methods.tokenURI(tokenOfOwnerByIndex).call()
            //     console.log(tokenURI)
            //     let res = await getNFTList(tokenURI)
            //     console.log(res)
            //     res.tokenId = tokenOfOwnerByIndex
            //     result.balanceUrl.push(res)
            // }

            return result
            
        } catch (error) {
            
        }
    }
    const startWebPledge = async (address, lp_address, annual_output, tokenContract, routerContract) => {
	  try {
		let params = null;

		params = {
		  lp: lp_address,
		  lpAbi: lpAbi,
		  pledge: address,
		  pledgeAbi: pledgeAbi,
		};

		let web = await startCustomInitPledge(params);
		console.log(web);
		const { account, web3, LpContract, PledgeContract } = web;
		setAccount(web.account);
		let result = {
		  allstake: 0,
		  allstakeU: 0,
		  pendingFit: 0,
		  pendingFitU: 0,
		  lpbal: 0,
		  incomeRatio: 0,
		  liquidity: 0,
		  ...web,
		  ...params,
		};

		
		annual_output = Web3.utils.toWei(annual_output);
		// 获取交易对中的价格信息
		const one = web3.utils.toWei("1");
		const [outs, incomeRatio, pendingFit, totalSupply,res3,lpbal,bno_amount,pledgeRes] = await Promise.all([
		  routerContract.methods.getAmountsOut(one, [BNO, USDT]).call(),
		  PledgeContract.methods.getIncomeRatio(annual_output).call(),
		  PledgeContract.methods.pendingFit(account).call(),
		  LpContract.methods.totalSupply().call(),
		  PledgeContract.methods.poolInfo().call(),
		  LpContract.methods.balanceOf(account).call(),
		  tokenContract.methods.balanceOf(lp_address).call(),
		  PledgeContract.methods.userInfo(account).call(),
		]);

		// 获取用户质押信息
		result.allstake = web3.utils.fromWei(pledgeRes.allstake);
		result.stakeSupply = res3.stakeSupply;
		result.lpbal = web3.utils.fromWei(lpbal);
		result.liquidity = (web3.utils.fromWei(outs[1]) * web3.utils.fromWei(bno_amount) * 2).toFixed(2);
		result.incomeRatio = parseFloat(incomeRatio / 100).toFixed(2);
		result.pendingFit = pendingFit;
		//console.log(pendingFit);

		if (pendingFit != 0) {
		  const outs2 = await routerContract.methods.getAmountsOut(pendingFit, [BNO, USDT]).call();
		  console.log("outs2", outs2);
		  result.pendingFitU = BigNumber(outs2[1]).multipliedBy(1.2).toFixed(0);
		}

		if (pledgeRes.allstake != 0 && bno_amount != 0) {
		  let out3 = BigNumber(pledgeRes.allstake).dividedBy(totalSupply).multipliedBy(bno_amount).toFixed(0);
		  const outs4 = await routerContract.methods.getAmountsOut(out3, [BNO, USDT]).call();
		  //console.log("outs4", outs4);
		  result.allstakeU = (outs4[1] * 2.2 / Math.pow(10, 18)).toFixed(2);
		  console.log("allstakeU", result.allstakeU);
		}


		console.log("读取合约result", result);
		return result;
	  } catch (err) {
		console.error("Error:", err);
		throw err;
	  }
	}


    async function initialization() {
	
        var web3 = new Web3(Web3.givenProvider);
        var chainId = 56;			
        try {
                const networkId = await web3.eth.net.getId();
                if (networkId) {
                    chainId = networkId;
                }
            } catch (error) {
                console.error('Error getting network ID:', error);
            }
        	
        const url = domainURL + `/api/index/farms?chainid=`+chainId;
		
        getNFTList(url).then((res) => {
            if (res.code === 1) {
                let list = res.data || []
                list.forEach(item => {
                    item.info = {
                        incomeRatio: 0,
                        liquidity: 0,
                        pendingFit: 0,
                        pendingFitU: 0,
                        allstake: 0,
                        allstakeU: 0,
                        lpbal: 0,
                    }
                    item.nft = {
                        nftAddition: 0,
                        nftAmount: 0,
                        nftUrl: [],
                        nftIds: [],
                        balance: 0,
                    }
                    item.isVisible = true
                })
                setPledgeList(list);
                getListData(list);
            }
        });


    }

    useEffect(() => {
        async function startWeb3() {
			var web3 = new Web3(Web3.givenProvider); 
			var chainId = 56;			
			try {
			        const networkId = await web3.eth.net.getId();

					if (networkId) {
						chainId = networkId;
					    switch (networkId) {
					        case 56: // Binance Smart Chain Mainnet
					            BNO = '0xc10c96b80de6c1c4f13f7a3084222b83a33384df';
					            USDT = '0x55d398326f99059ff775485246999027b3197955';
					            Router = '0x10ed43c718714eb63d5aa57b78b54704e256024e';
					            break;
					        case 97: // Binance Smart Chain Testnet
					            BNO = '0xf644E8556f9ed4D2cc3B7D6668cD4e5E623b3bC4';
					            USDT = '0x05aa278a9cB6e14131Ed77d958216FEBc44120D6';
					            Router = '0xd99d1c33f9fc3444f8101754abc46c52416550d1';
					            break;
					        // Add more cases for other networks as needed
					        default:
					            // Use defaults or set custom values
					            break;
					    }
					}
			    } catch (error) {
			        console.error('Error getting network ID:', error);
			    }
				
            const url = domainURL + `/api/index/farms?chainid=`+chainId;
            getNFTList(url).then((res) => {
                console.log(res)
                if (res.code === 1) {
                    let list = res.data || []
                list.forEach(item => {
                    item.info = {
                        incomeRatio: 0,
                        liquidity: 0,
                        pendingFit: 0,
                        pendingFitU: 0,
                        allstakeU: 0,
                        allstake: 0,
                        lpbal: 0,
                    }
                    item.nft = {
                       nftAddition: 0,
                        nftAmount: 0,
                        nftUrl: [],
                        nftIds: [],
                        balance: 0,
                    }
                    item.isVisible = true
                })
                setPledgeList(list);
                getListData(list);
                }
            });


        }
        startWeb3();

        // const interval = setInterval(() => {
        //       startWeb3(); // 每3秒调用一次 startWeb3 函数
        //     }, 3000);
        //     return () => {
        //         clearInterval(interval); // 在组件卸载或依赖项变化时清除轮询
        //       };
    }, [])


    const CountdownItem = ({ item }) => {
        const [remainingTime, setRemainingTime] = useState(0);
         useEffect(() => {
          const endTime = item.startTime * 1000;
          const currentTime = new Date().getTime();
          const remaining = endTime - currentTime;
          setRemainingTime(remaining);
          const interval = setInterval(() => {
            setRemainingTime(prevTime => prevTime - 1000);
          }, 1000);
          return () => clearInterval(interval);
        }, [item]);
        const formatTime = () => {
          const seconds = Math.floor((remainingTime / 1000) % 60);
          const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
          const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
          const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
           return `${days}D ${hours > 9 ? hours: '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + seconds}`;
        };
         return (
          <div>
            {formatTime()}
          </div>
        );
      };

    const createBanner = () => {
        return (<div className='banner'>
            <h1>{t('farms')}</h1>
            <h2>{t('farms2')}</h2>
        </div>);
    }

  
    const createTrList = () => {
        return pledgeList.length > 0 && pledgeList.map((item, key) => {
            return (<tbody key={key}><tr className={item.isVisible ? 'tr_ctn tr_open' : 'tr_ctn'}  onClick={() => toggleDom2(key)}>
                    <td>
                    <div className="td_ctn">
                        <img src={item.logo} className="token_logo" />
                        {item.name}
                    </div>
                </td>
                <td>
                    <div className="td_ctn">
                        <div>
                            <div>{t('farms3')}</div>
                            <div>{getIncome(item.info?.pendingFit)}</div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="td_ctn">
                        <div>
                            <div>{t('farms4')}</div>
                            <div>{item.info?.incomeRatio}%</div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="td_ctn">
                        <div>
                            <div>{t('farms5')}</div>
                            <div>${item.info?.liquidity}</div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="td_ctn">
                        <div>
                            <div>{t('power')}</div>
                            <div>{item.nft_address == '0' ? item.info?.allstake : (parseFloat(item.info?.allstake) + parseFloat(item.nft?.nftAddition))}</div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="td_ctn">
                        <svg  viewBox="0 0 24 24" color="primary" className="down_icon" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path></svg>
                    </div>
                </td>
            </tr>
            
            {item.isVisible && <tr>
                <td colSpan="6">
                    <div className="td_collapse">
                        <div className='td_collapse_inner'>
                        <div className='collapse_left'>
                                <p className='link'>
                                    <a target="_blank" style={{ textDecoration: 'none' }} href={item.lp_url}>{t('farms7')} {item.name} LP</a>
                                    <svg viewBox="0 0 24 24" color="primary" width="16px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB bYgUAi"><path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path></svg>
                                </p>
                                <p className='link'>
                                    <a target="_blank" style={{ textDecoration: 'none' }} href={item.contract_url}>{t('farms8')}</a>
                                    <svg viewBox="0 0 24 24" color="primary" width="16px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB bYgUAi"><path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path></svg>
                                </p>
                                <p className='link'>
                                    <a target="_blank" style={{ textDecoration: 'none' }} href={item.token_url}>{t('farms9')}</a>
                                    <svg viewBox="0 0 24 24" color="primary" width="16px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB bYgUAi"><path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path></svg>
                                </p>
                                <div className='badge2'>
                                    <svg viewBox="0 0 24 24" width="18px" color="secondary" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB eitcbN"><path d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM9.38 16.01L7 13.61C6.61 13.22 6.61 12.59 7 12.2L7.07 12.13C7.46 11.74 8.1 11.74 8.49 12.13L10.1 13.75L15.25 8.59C15.64 8.2 16.28 8.2 16.67 8.59L16.74 8.66C17.13 9.05 17.13 9.68 16.74 10.07L10.82 16.01C10.41 16.4 9.78 16.4 9.38 16.01Z"></path></svg>
                                    {t('farms10')}
                                </div>
                            </div>
                            <div className='collapse_right'>
                                <div className="border_box mx-4">
                                    <div className='border_title'>
                                        BNO {t('farms3')}
                                    </div>
                                    <div className='receive_box'>
                                        <div>
                                            <div className='big_font'>{getIncome(item.info?.pendingFit)}</div>
                                            <div>~{getIncome(item.info?.pendingFitU)}USD</div>
                                        </div>
                                        <div className='draw'>
                                          
                                                                                        <button disabled={item.info?.pendingFit == 0 || loading}
                                                onClick={() => startDraw(item)} className='base_btn2' style={{height:'40px'}}>{t('draw')}</button>

                                        </div>

                                    </div>
                                </div>

                                <div className='border_box  mx-4'>
                                   
                                   <div className='border_title'>
                                         {item.name} LP
                                    </div>
                                    <div className='receive_box'>
                                    <div>
                                        <div className='big_font'>{item.info?.allstake}</div>
                                        <div>~{item.info?.allstakeU}USD</div>
                                    </div>
                                        <div className='draw'>
                                        {item.startTime > parseInt(new Date().getTime() / 1000) ?
                                            <button
                                                className='base_btn2' style={{height:'40px'}}>
                                                    <CountdownItem key={item.id} item={item} />
                                                </button>
                                             :
                                            item.info?.allstake == 0 ?
                                            <button
                                            disabled={loading}
                                                data-key={key}
                                                data-name={item.name}
                                                onClick={() => pledgeSomeToken(item,key)}
                                                className='base_btn2' style={{height:'40px'}}>
                                                    {t('stakelp1')}
                                                </button>
                                            :
                                            <div style={{textAlign:'right'}}>
                                            <button className='base_btn' data-key={key}
                                                data-name={item.name}
                                                onClick={() => pledgeSomeToken2(item,key)} style={{width: '48px',padding: 0,marginRight: '5px'}}>
                                                <svg viewBox="0 0 24 24" style={{fill:'rgb(103, 108, 155)'}} color="primary" width="14px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB iARelP"><path d="M18 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"></path></svg>
                                            </button>
                                            <button className='base_btn' data-key={key}
                                                data-name={item.name}
                                                onClick={() => pledgeSomeToken(item,key)} style={{width: '48px',padding: 0}}>
                                                <svg viewBox="0 0 24 24" style={{fill:'rgb(103, 108, 155)'}}  color="primary" width="14px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB iARelP"><path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"></path></svg>
                                            </button>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                </div>
                                {item.nft_address != '0' && <div className="border_box">
                                    <div className='border_title'>
                                        NFT
                                    </div>
                                    <div className='receive_box'>
                                        <div>
                                            <div className='big_font'>{(item.nft?.nftAmount)}</div>
                                            <div>&nbsp;</div>
                                        </div>
                                        <div className='draw'>
                                        {
                                            item.nft?.nftAmount == 0 ?
                                            <button
                                            disabled={loading}
                                                onClick={() => pledgeNFT(item,key)}
                                                className='base_btn2' style={{height:'40px'}}>
                                                    {t('stakelp1')}
                                                </button>
                                            :
                                            <div style={{textAlign:'right'}}>
                                            <button className='base_btn'
                                                onClick={() => unpledgeNFT(item,key)} style={{width: '48px',padding: 0,marginRight: '5px'}}>
                                                <svg viewBox="0 0 24 24" style={{fill:'rgb(103, 108, 155)'}} color="primary" width="14px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB iARelP"><path d="M18 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"></path></svg>
                                            </button>
                                            <button className='base_btn'
                                                onClick={() => pledgeNFT(item,key)} style={{width: '48px',padding: 0}}>
                                                <svg viewBox="0 0 24 24" style={{fill:'rgb(103, 108, 155)'}}  color="primary" width="14px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB iARelP"><path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"></path></svg>
                                            </button>
                                            </div>
                                        }
                                        </div>

                                    </div>
                                </div>
                                }

                            </div>
                        </div>
                    </div>
                </td>
            </tr>}
            </tbody>
            )
        })
    }
     const createMobileTrList = () => {
        return pledgeList.length > 0 && pledgeList.map((item, key) => {
            return (<tbody key={key}><tr className={item.isVisible ? 'tr_ctn tr_open' : 'tr_ctn'}  onClick={() => toggleDom2(key)}>
                    <td style={{width: '50%'}}>
                    <div className="td_ctn">
                        <img src={item.logo} className="token_logo" />
                        {item.name}
                    </div>
                </td>
                <td style={{width: '20%'}}>
                    <div className="td_ctn">
                        <div>
                            <div>{t('farms3')}</div>
                            <div>{getIncome(item.info?.pendingFit)}</div>
                        </div>
                    </div>
                </td>
                <td style={{width: '20%'}}>
                    <div className="td_ctn">
                        <div>
                            <div>{t('farms4')}</div>
                            <div>{item.info?.incomeRatio}%</div>
                        </div>
                    </div>
                </td>
                <td style={{width: '10%'}}>
                    <div className="td_ctn">
                        <svg  viewBox="0 0 24 24" color="primary" className="down_icon" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path></svg>
                    </div>
                </td>
            </tr>
            
            {item.isVisible && <tr>
                <td colSpan="4">
                    <div className="td_collapse">
                         <div className='collapse_right'>
                                <div className="border_box mt20">
                                    <div className='border_title'>
                                        BNO {t('farms3')}
                                    </div>
                                    <div className='receive_box'>
                                        <div>
                                            <div className='big_font'>{getIncome(item.info?.pendingFit)}</div>
                                            <div>~{getIncome(item.info?.pendingFitU)}USD</div>
                                        </div>
                                        <div className='draw'>
                                            <button disabled={item.info?.pendingFit == 0 || loading}
                                                onClick={() => startDraw(item)}  className='base_btn2' style={{height:'40px'}}>{t('draw')}</button>

                                        </div>

                                    </div>
                                </div>

                                <div className='border_box mt20'>
                                   
                                   <div className='border_title'>
                                        {item.name} LP
                                    </div>
                                    <div className='receive_box'>
                                    <div>
                                        <div className='big_font'>{item.info?.allstake}</div>
                                        <div>~{item.info?.allstakeU}USD</div>
                                    </div>
                                        <div className='draw'>
                                        {item.startTime > parseInt(new Date().getTime() / 1000) ?
                                            <button
                                                className='base_btn2' style={{height:'40px'}}>
                                                    <CountdownItem key={item.id} item={item} />
                                                </button>
                                              :
                                            item.info?.allstake == 0 ?
                                            <button
                                            disabled={loading}
                                                data-key={key}
                                                data-name={item.name}
                                                onClick={() => pledgeSomeToken(item,key)}
                                                className='base_btn2' style={{height:'40px'}}>
                                                    {t('stakelp1')}
                                                </button>
                                            :
                                            <div style={{textAlign:'right'}}>
                                            <button className='base_btn' data-key={key}
                                                data-name={item.name}
                                                onClick={() => pledgeSomeToken2(item,key)} style={{width: '48px',padding: 0,marginRight: '5px'}}>
                                                <svg viewBox="0 0 24 24" style={{fill:'rgb(103, 108, 155)'}} color="primary" width="14px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB iARelP"><path d="M18 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"></path></svg>
                                            </button>
                                            <button className='base_btn' data-key={key}
                                                data-name={item.name}
                                                onClick={() => pledgeSomeToken(item,key)} style={{width: '48px',padding: 0}}>
                                                <svg viewBox="0 0 24 24" style={{fill:'rgb(103, 108, 155)'}}  color="primary" width="14px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB iARelP"><path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"></path></svg>
                                            </button>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                </div>

                                {item.nft_address != '0' && <div className="border_box">
                                    <div className='border_title'>
                                        NFT
                                    </div>
                                    <div className='receive_box'>
                                        <div>
                                            <div className='big_font'>{(item.nft?.nftAmount)}</div>
                                            <div>&nbsp;</div>
                                        </div>
                                        <div className='draw'>
                                        {
                                            item.nft?.nftAmount == 0 ?
                                            <button
                                            disabled={loading}
                                                onClick={() => pledgeNFT(item,key)}
                                                className='base_btn2' style={{height:'40px'}}>
                                                    {t('stakelp1')}
                                                </button>
                                            :
                                            <div style={{textAlign:'right'}}>
                                            <button className='base_btn'
                                                onClick={() => unpledgeNFT(item,key)} style={{width: '48px',padding: 0,marginRight: '5px'}}>
                                                <svg viewBox="0 0 24 24" style={{fill:'rgb(103, 108, 155)'}} color="primary" width="14px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB iARelP"><path d="M18 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"></path></svg>
                                            </button>
                                            <button className='base_btn'
                                                onClick={() => pledgeNFT(item,key)} style={{width: '48px',padding: 0}}>
                                                <svg viewBox="0 0 24 24" style={{fill:'rgb(103, 108, 155)'}}  color="primary" width="14px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB iARelP"><path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"></path></svg>
                                            </button>
                                            </div>
                                        }
                                        </div>

                                    </div>
                                </div>
                                }
                            </div>
                            <div className="line_ctn">
                                    <div>{t('power')}</div>
                                    <div>{item.nft_address == '0' ? item.info?.allstake : (parseFloat(item.info?.allstake) + parseFloat(item.nft?.nftAddition))}</div>
                            </div>
                            <div className="line_ctn">
                                    <div>{t('farms5')}</div>
                                    <div>${item.info?.liquidity}</div>
                            </div>
                            <div className="line_ctn">
                                    <div>{t('farms6')}</div>
                                    <div>{item.weight}</div>
                            </div>
                        <div className='collapse_left'>
                                <p className='link'>
                                    <a target="_blank" style={{ textDecoration: 'none' }} href={item.lp_url}>{t('farms7')} {item.name} LP</a>
                                    <svg viewBox="0 0 24 24" color="primary" width="16px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB bYgUAi"><path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path></svg>
                                </p>
                                <p className='link'>
                                    <a target="_blank" style={{ textDecoration: 'none' }} href={item.contract_url}>{t('farms8')}</a>
                                    <svg viewBox="0 0 24 24" color="primary" width="16px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB bYgUAi"><path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path></svg>
                                </p>
                                <p className='link'>
                                    <a target="_blank" style={{ textDecoration: 'none' }} href={item.token_url}>{t('farms9')}</a>
                                    <svg viewBox="0 0 24 24" color="primary" width="16px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB bYgUAi"><path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path></svg>
                                </p>
                                <div className='badge2'>
                                    <svg viewBox="0 0 24 24" width="18px" color="secondary" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBQB eitcbN"><path d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM9.38 16.01L7 13.61C6.61 13.22 6.61 12.59 7 12.2L7.07 12.13C7.46 11.74 8.1 11.74 8.49 12.13L10.1 13.75L15.25 8.59C15.64 8.2 16.28 8.2 16.67 8.59L16.74 8.66C17.13 9.05 17.13 9.68 16.74 10.07L10.82 16.01C10.41 16.4 9.78 16.4 9.38 16.01Z"></path></svg>
                                    {t('farms10')}
                                </div>
                            </div>
                           
                    </div>
                </td>
            </tr>}
            </tbody>
            )
        })
    }
    const createList = () => {
        return (<div className='table_box'>
            <table className='table_ctn pc_ctn'>{
                    createTrList()
                }
            </table>
            <table className='table_ctn mobile_ctn'>{
                    createMobileTrList()
                }
            </table>
            
        </div>)
    }
    return (
        <div className='bgLinearColor'>
            <Header></Header>
            {contextHolder}
            <Container className='my-4'>
                <LinearBox item={createBanner()}></LinearBox>
                <LinearBox item={createList()}></LinearBox>
            </Container>
            {/* <ModalToken
                show={modalShow}
                loadingtype={loadingType}
                oncallback={pledgeCallback}
                handleclose={handleclose}
                handlechange={handlechange}
                title={modalTitle}
                lpurl={modalUrl}
                amount={modalAmount}
                ROI={Annual_ROI}
                loading={loading}
                onHide={() => setModalShow(false)}>

            </ModalToken> */}
            <ModalStake
             show={modalShow}
                loadingtype={loadingType}
                oncallback={pledgeCallback}
                handleclose={handleclose}
                handlechange={handlechange}
                title={modalTitle}
                lpurl={modalUrl}
                amount={modalAmount}
                ROI={Annual_ROI}
                loading={loading}
                onHide={() => setModalShow(false)}>

            </ModalStake>

            <ModalUnStake
             show={modalShow2}
                loadingtype={loadingType}
                oncallback={pledgeCallback}
                handleclose={handleclose}
                handlechange={handlechange}
                title={modalTitle}
                amount={modalAmount}
                loading={loading}
                onHide={() => setModalShow2(false)}>

            </ModalUnStake>
            <ModalStakeNFT
                show={modalNFTShow}
                loading={loading}
                obj={stakeObj}
                oncallback={pledgeNFTCallback}
                handleclose={handleclose}
                onHide={() => setModalNFTShow(false)}
            >
            </ModalStakeNFT>
            <ModalUnStakeNFT
            show={modalNFTShow2}
            loading={loading}
            obj={stakeObj}
            oncallback={pledgeNFTCallback}
            handleclose={handleclose}
            onHide={() => setModalNFTShow2(false)}>
            </ModalUnStakeNFT>
            <Footer></Footer>
        </div>
    )
}

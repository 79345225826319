import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'

export default function ChainIcon() {
    return (
        <Container className='flex my-4 py-4'>
            {/* <Row>
                <Col xs={6} lg={3} md={3} sm={6} className="p-1">
                    <img src="/assets/support1.png" className="img-fluid1" ></img>
                </Col>
                <Col xs={6} lg={3} md={3} sm={6}  className="p-1">
                    <img src="/assets/support1.png" className="img-fluid1" ></img>
                </Col>
                <Col xs={6} lg={3} md={3} sm={6}  className="p-1">
                    <img src="/assets/support1.png" className="img-fluid1" ></img>
                </Col>
                <Col xs={6} lg={3} md={3} sm={6}  className="p-1">
                    <img src="/assets/support1.png" className="img-fluid1" ></img>
                </Col>
            </Row> */}
        </Container>
    )
}

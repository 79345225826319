import React, { useEffect, useState } from 'react'
import Banner from './Banner';
import ChainIcon from '../common/ChainIcon';
import CategoryTitle from '../common/CategoryTitle';
import NFTList from './NFTList';
import TopCollections from './TopCollections';
import TopSeller from './TopSeller';
import Footer from '../common/Footer';
import Header from '../common/Header';
import Order from '../Order';
import Balance from '../Balance';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { loadBalanceData } from '../../redux/slices/balanceSlice';
import { loadCancleOrderData, loadAllOrderData, loadFillOrderData } from '../../redux/slices/orderSlice';
import { useSelector } from 'react-redux';
// import YBTokenJson from '../../build/YBToken.json'
// import ExchangeJson from '../../build/Exchange.json'
import useSWR from 'swr';
import DropdownButton from '../common/DropdownButton';
import { startInitWeb } from '../../util/startInitWeb';
import { setMintFee } from '../../redux/slices/mintSlice';
import { setBannerData, setCollections, setLatest, setNFTSymbol, setSellers } from '../../redux/slices/homeSlice';
import { domainURL } from '../../util/util'; 
import { useTranslation } from 'react-i18next';
import Web3 from 'web3';
import GroupList from '../common/GroupList';
import NFTGroupList from './NFTGroupList';


const fetchData = (...args) => fetch(...args).then((res) => res.json());
export default function Home(props) {


    const dispatch = useDispatch();
    const { mintFee } = useSelector(state => state.mint);
    const navigate = useNavigate();

    const [banner, setBanner] = useState([]);
    const [list ,setList] = useState([]);
    const [curTag,setCurTag] = useState('');
    const { t, i18n } = useTranslation();
    const { data, error, isLoading } = useSWR(domainURL+'/api/index/index', fetchData); 
    
    if (data) {
        
        if(data.code == 1){  
            dispatch(setBannerData(data.data.banner[0]));
            dispatch(setLatest(data.data.latest));
            dispatch(setCollections(data.data.collections));
            dispatch(setSellers(data.data.sellers));
        } 
    } 


    useEffect(() => { 
        async function startWeb3() {
            if (typeof window.web == 'undefined') {
                await startInitWeb();
            }
        }
        // setTimeout(function(){
         startWeb3();
         // },2000);
        var url = domainURL + '/api/search/index'+ `?offset=0&limit=8&attributes=${curTag}`;// + JSON.stringify(json)
        getNFTList(url).then((res) => {  
                setList(res.rows);
        });
        
       
    }, [])
    const updateCategory = (value)=> {  
        var url = domainURL + '/api/search/index'+ `?offset=0&limit=8&attributes=${value}`;// + JSON.stringify(json)
        getNFTList(url).then((res) => {  
            setList(res.rows);
        });
    }
    const jumpToSearch = (value)=> {  
        navigate(`/search?attributes=${value}`);
    }
    

    const getNFTList = async (url) => {
        return fetch(url).then((res) => res.json())
    }
 

    return (
        <div className='bgLinearColor'>
            {/* {mintFee} */}
            
            <Header></Header>
          
            <Banner ></Banner>
            <ChainIcon></ChainIcon>
            <GroupList updateCategory={updateCategory} jumpToSearch={jumpToSearch}></GroupList>
            <NFTGroupList  type="home" list={list} > </NFTGroupList>
            <CategoryTitle title={t('latestnNft')}></CategoryTitle>
            <NFTList  type="home"> </NFTList>
            <CategoryTitle title={t('topCollection')}></CategoryTitle>
            <TopCollections></TopCollections>
            <CategoryTitle title={t('topSellers')}></CategoryTitle>
            <TopSeller></TopSeller>
            <Footer></Footer>
            
            {/* <Balance></Balance>
             <Order></Order> */}
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { Button, Container, Table } from 'react-bootstrap'
import LinearBorderBox from '../common/LinearBorderBox' 
import ContentTitle from '../common/ContentTitle' 
import { Tabs, Upload, Space, Input } from 'antd'
import { domainURL } from '../../util/util'
// import { startInitWeb } from '../../util/startInitWeb'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo } from '../../redux/slices/userSlice'
import { message } from 'antd'; 
import { useTranslation } from 'react-i18next';
import Loading from '../common/Loading'
import { startCustomInitPledge } from '../../util/startCustomInitPledge'
import Web3 from "web3";
//引入ABI文件

import lpAbi from '../../build/listAbi/lpAbi.json';
import pledgeAbi from '../../build/listAbi/pledgeAbi.json';

import ModalToken from '../common/ModalToken.js';
import VerticallyModalNFTNode from '../common/VerticallyModalNFTNode.js';


console.log();
export default function User() {

    const dispatch = useDispatch();
    const [account, setAccount] = useState('');
    const [imageUrl, setImageUrl] = useState('/assets/no-picture.png');
    const userInfo = useSelector(state => state.user.info);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [pledgeKey, setPledgekey] = useState(0);
    
    const [pledgeList, setPledgeList] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalNFTShow, setModalNFTShow] = useState(false);
    

    const [spinStatus, setSpinStatus] = useState(false);
    const [loadingType, setLoadingType] = useState('');
    const [tips, setTips] = useState('');



    const { t  } = useTranslation();
 
    const  outerStyle = {
        // background:'#252244',
        width:'100%',
        minHeight:'500px'
    }
    const headerStyle = {
        background:'#252244',
    }
 
    const detailStyle = { 
        
    }

    const createCreateTrList = () => {
         
        return pledgeList.length > 0 && pledgeList.map((item,key) => {
            return (<tbody key={key}><tr> 
                    <td className='xxTdbiOuter'>
                        <div className='xxbiOuter'>
                            <img src={item.logo} className='xxbi' ></img>
                            {item.name}
                        </div>
                    </td> 
					<td>{getIncome(item.info?.pendingFit)}</td> 
                    <td>{item.info?.incomeRatio}%</td> 
					<td>{getIncome(item.info?.allstake)}</td>
					
                    {/*<td>{item.totalpool}</td> 
                    
                    <td>{item.weight}</td> */}
                </tr>

                <tr>
                    <td colSpan="8">
                        <div className='tdOuter'>
                            <div className='tdouter-left'> 
                                    <p><a style={{textDecoration: 'none'}} href={item.lp_url} className='text-white'>LP合约信息</a></p>
                                    <p><a style={{textDecoration: 'none'}} href={item.token_url} className='text-white'>代币合约信息</a></p>
                                    <p><a style={{textDecoration: 'none'}} href={item.contract_url} className='text-white'>质押合约信息</a></p> 
                            </div> 
                            <div  className='tdouter-right'>
								<div  style={detailStyle} class="pendingFit">
									<div>
										<p class="pendingFitTxt">{t('incomde')}: {getIncome(item.info?.pendingFit)}</p>							  
									</div>
								<div>
								
							    <Button className='button-offer w-100 py-2 my-4 fs-6 fw-bold   pledgd-button1 ' 
							    disabled={loading}
							    data-key = {key}
							    onClick={startDraw}   >
							        
							        {loading && loadingType == 'draw' ? <Loading></Loading> : t('draw')}
							    </Button>
							
							   
							</div>
							</div>
							
							
							
                            <div  style={detailStyle}>
                                <div>
                                    <p class="userAllstake" >{t('power')} :  {getIncome(item.info?.allstake)}</p>
									<p class="userBal" >{t('blance')} : {getIncome(item.info?.lpbal)}</p>
                                </div>
                                <div>
                                    <Button className='button-offer w-100 py-2 my-4 fs-6 fw-bold   pledgd-button1'
                                            disabled={loading} 
                                            data-key = {key}
                                            data-name = {item.name}
                                            onClick={pledgeSomeToken}    >
											
											{loading && loadingType == 'PledgeRedemption' ? <Loading></Loading> : t('PledgeRedemption')}
										
                                    
                                    </Button>
									
                                </div>
                                </div> 

                                
                                
                            </div>
                        </div>
                        </td>
                </tr></tbody>
                );
        });
       
    }


    const getIncome = (pendingFit) => { 
        if(pendingFit){
            var res = Web3.utils.fromWei(pendingFit);
            return parseFloat(res).toFixed(2);
        }else{
            return 0;
        } 
    }

    //开始进行操作了
    //质押代币
    

    const pledgeSomeToken = async (data) => {
        let name =data.target.dataset.name;
        let key =data.target.dataset.key;
		
        setModalTitle(name);
        setPledgekey(key);
        setModalShow(true);
        
    }
   

    //提现的操作
    
    const startDraw = async (data) => {
        console.log(data.target.dataset.key);
		let info =pledgeList[data.target.dataset.key]['info'];
		
        if (info.pendingFit == 0) {
            messageApi.error(t('tips7'));
            return;
        }
        if (spinStatus) {
            return;
        }
        if (spinStatus) {
            messageApi.error(t('tips16'));
            return;
        }
        const { PledgeContract, account } = info;
        
        setSpinStatus(true);
        setLoading(true);
        setLoadingType('draw');
        setTips(t('tips14'));
		
        console.log({ from: account,value:0 },info.account);
		
		
        PledgeContract.methods.withdraw().estimateGas({ from: account,value:0 }).then((res) => {
            estimateGasDrawSuccess(info);
        }).catch((error) => {
            messageApi.error(t('insufficientGas'));
            setLoading(false);
            setSpinStatus(false);
			
        });
		
    }

    const estimateGasDrawSuccess = (info) => {
        const { PledgeContract, account } = info;
        PledgeContract.methods.withdraw().send({ from: account,value:0}).then((res) => {
            console.log(res);
            setSpinStatus(false);
            messageApi.info(t('tips8'));
            setLoading(false);
            initialization();
			
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
            setSpinStatus(false);
        });
    }

    //赎回的操作
    const startRedeem = async (repedgeNumber) => {
		console.log("startRedeem");
        let info =pledgeList[pledgeKey]['info'];
        if (spinStatus) {
            return;
        }
       
        const { PledgeContract, account } = info;
        setSpinStatus(true);
        setTips(t('tips5'));
        setLoading(true);
        setLoadingType('redeembon');

        repedgeNumber =  Web3.utils.toWei(repedgeNumber);
		console.log(repedgeNumber);
		
        PledgeContract.methods.rePledge(repedgeNumber).estimateGas({ from: account,value:0 }).then((res) => {
            estimateGasRedeemSuccess(info,repedgeNumber);
        }).catch((error) => {
            messageApi.error(t('insufficientGas'));
            setSpinStatus(false);
            setLoading(false);
        });
    }
	
    const estimateGasRedeemSuccess = (info,repedgeNumber) => {

        const { LpContract, PledgeContract, account } = info;        
        PledgeContract.methods.rePledge(repedgeNumber).send({ from: account,value:0 }).then((res) => {
            console.log(res);
            setSpinStatus(false);
            setLoading(false);
            messageApi.info(t('tips11')); 
			setModalShow(false);
			 initialization();
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
            }
            setLoading(false);
        });
    }


    const startPledge = async (pledgeNumber) => {
        let info =pledgeList[pledgeKey]['info'];
        console.log(pledgeKey,info,pledgeList);
        if (spinStatus) {
            return;
        }
        if (pledgeNumber == 0) {
            messageApi.error(t('tips9'));
            return;
        }
        
        const { PledgeContract, account, LpContract } = info;

        setSpinStatus(true);
        setLoadingType('pledgebno');
        setLoading(true);
        setTips(t('tips10'));

        let allowanceAccount = await LpContract.methods.allowance(account, info.pledge).call();
        console.log('授权数量：',allowanceAccount);
        let pledgeAmount = Web3.utils.toWei(pledgeNumber);
        console.log('授权数量：',allowanceAccount , '质押数量：',pledgeAmount);
        if (parseInt(allowanceAccount) >= parseInt(pledgeAmount)) {
			console.log(allowanceAccount >= pledgeAmount);
            //console.log('授权数量：',allowanceAccount , '质押数量：',pledgeAmount);
            PledgeContract.methods.pledge(pledgeAmount).estimateGas({ from: account,value:0 }).then((res) => {
                estimateGasSuccess(info,pledgeAmount);
            }).catch((error) => {
                messageApi.error(t('insufficientGas'));
                setLoading(false);
                setSpinStatus(false);
            });
        }else{ 
            console.log('pledge: ',info.pledge,'pledgeAmount',pledgeAmount,'account:',account);
            LpContract.methods.approve(info.pledge, pledgeAmount).send({ from: account }).then((res) => {
                console.log('这里是一个授权的方法结果成功');
                PledgeContract.methods.pledge(pledgeAmount).estimateGas({ from: account,value:0 }).then((res) => {
                    estimateGasSuccess(info,pledgeAmount);
                }).catch((error) => {
                    messageApi.error(t('insufficientGas'));
                    setLoading(false);
                    setSpinStatus(false);
                });  
            }).catch((error) => {
                if (error.code == 4001) {
                    setSpinStatus(false);
                    messageApi.error(t('userDenied'));
                }
                console.log('这里是一个授权的方法结果失败',error);
                setLoading(false);
            }); 
        }  
    }

    const estimateGasSuccess = async (info,pledgeAmount) => {

        const { LpContract, PledgeContract, account } = info;
        var res = await LpContract.methods.allowance(account, info.pledge).call();

        PledgeContract.methods.pledge(pledgeAmount).send({ from: account,value:0 }).then((res) => {
            console.log(res);
            setSpinStatus(false);
            setLoading(false);
            messageApi.info(t('tips11')); 
			setModalShow(false);
			initialization();
        }).catch((error) => {
            if (error.code == 4001) {
                setSpinStatus(false);
                messageApi.error(t('userDenied'));
				setModalShow(false);
            }
            setLoading(false);
        });

    }



    const pledgeCallback = (amounts,state) => { 
        //setModalShow(false);
		setLoading(true);
		setSpinStatus(true);
		setLoadingType('pledge');
		setLoadingType('redeem');
		setLoadingType('PledgeRedemption');
		if(state == 1){
			startPledge(amounts);

			
		}else if(state == 0){
			startRedeem(amounts)
			
		}
        
    }


    //开始进行操作了
    const handleclose = (pledgeNumber) => { 
        setModalShow(false); 
    } 
    
    

    
    const createCreateForm = () => {
        return (<div className='m-2  py-4 text-white' style={outerStyle}>
                    <table width="100%" class="pledgelist">
                        <tr width="100%" style={headerStyle}>
                            <th>{t('projectName')}</th> 
							<th>{t('earned')}</th> 
                            <th>{t('pledgebnoRatio')}</th> 							
							<th>{t('power')}</th> 
                         {/* <th>总矿池</th>
                          
                            // <th>权重</th>  */}
                        </tr> 
                         {createCreateTrList()}
                         
                    </table> 

                </div>);
    }
    const getNFTList = async (url) => {
        return fetch(url).then((res) => res.json())
    }
    
    const getListData = async (data) => {
        console.log('getListData ',data); 
        let newList = [];

         await Promise.all(
            data.map(async (item,key)=>{ 
				console.log(item)
                let info =  await startWebPledge(item.address,item.lp_address,item.totalpool);  
				console.log('startWebPledge ',info); 
                newList.push({...item,info});
            })
        ); 

        setPledgeList(newList);
		
    }

    const startWebPledge = async (address,lp_address,totalpool) =>{
        let params = null;

		params = {lp:lp_address,
				  lpAbi:lpAbi,
				  pledge:address,
				  pledgeAbi:pledgeAbi};
		
        let web = await startCustomInitPledge(params); 
		console.log(web);
        const {account, web3, LpContract, PledgeContract} = web; 
        setAccount(web.account); 
        
        let pledgeRes = await PledgeContract.methods.userInfo(account).call(); 
        console.log("userInfo："+pledgeRes);
        let result = {
            allstake: 0,
            rewardDebt: 0,
            pendingFit: 0,
            stakeSupply:0,
            lpbal:0,
			incomeRatio:0,
            ...web,
            ...params
        }
        result.allstake = pledgeRes.allstake;

		totalpool = Web3.utils.toWei(totalpool);
		//console.log(totalpool);
        let incomeRatio = await PledgeContract.methods.getIncomeRatio(totalpool).call();		
        result.incomeRatio = parseFloat(incomeRatio).toFixed(2);
		result.incomeRatio = 155.12
		console.log("incomeRatio"+incomeRatio);
		
        let pendingFit = await PledgeContract.methods.pendingFit(account).call();  
        result.pendingFit = pendingFit;
         
        let res3 = await PledgeContract.methods.poolInfo().call();  
        result.stakeSupply = res3.stakeSupply;

        let lpbal = await LpContract.methods.balanceOf(account).call();
        result.lpbal = lpbal;
		console.log(result)
        return result;
    } 
 
	
	async function initialization() {
	
	    const url = domainURL + `/api/index/pledgelist`;
	    getNFTList(url).then((res) => {
	        if (res.code === 1) { 
	            setPledgeList(res.data);
	            getListData(res.data);					
	        }
	    });
		
		
	} 

    useEffect(() => {
        async function startWeb3() { 

            const url = domainURL + `/api/index/pledgelist`;
            getNFTList(url).then((res) => {
                if (res.code === 1) { 
                    setPledgeList(res.data);
                    getListData(res.data);					
                }
            });
			
			
        } 
        startWeb3();
		
		// const timerId = setInterval(() => {
		//       startWeb3(); // 每3秒调用一次 startWeb3 函数
		//     }, 3000);
    },[])



    return (
        <div className='bgLinearColor'>
            <Header></Header>
            {contextHolder}
            <Container>
                <LinearBorderBox item={createCreateForm()}></LinearBorderBox>
            </Container>
            <ModalToken
                show={modalShow}
                loadingtype={loadingType}
                oncallback={pledgeCallback}
                handleclose={handleclose}
                title={modalTitle}
                loading={loading} 
                onHide={() => setModalShow(false)}>

            </ModalToken>



            <Footer></Footer>
        </div>
    )
}
